import { useEffect, useState } from "react";
import AppLayout from "../../../layout/AppLayout";
import Timeline from "./components/timeline";
import Submenu from "./components/submenu";
import { Divider } from "@rio-cloud/rio-uikit";
import MaintenanceRequest from "./components/maintenanceRequest";
import Inspection from "./components/inspection";
import { fetchScheduling } from "../../../api/fleetManager/fetchScheduling";
import { useSearchParams } from "react-router-dom";
import { Scheduling } from "../../../models/dealership/schedule";
import { Loading } from "../../../utils/loading";
import ScreeningDiagnosis from "./components/screeningDiagnosis";
import {
  MaintenanceMonitoringTimelineStep,
  Ticket,
} from "../../../models/ticket";
import { fetchTicket, fetchTickets } from "../../../api/ticket";
import { initialTimeline } from "../../../dtos/ticketDto";
import Repair from "./components/repair";
import { MaintenanceMonitoringStatusEnum } from "../../../enums/maintenanceMonitoring";
import Rate, { mockTimeline } from "./components/rate";

const Assessment = () => {
  const [searchParams] = useSearchParams();

  const [step, setStep] = useState<number>(0);
  const [currentStatusStep, setCurrentStatusStep] = useState<
    number | undefined
  >(undefined);

  const [scheduling, setScheduling] = useState<Scheduling | undefined>(
    undefined
  );
  const [ticket, setTicket] = useState<Ticket | undefined>(undefined);
  const [timeline, setTimeline] = useState<MaintenanceMonitoringTimelineStep[]>(
    []
  );
  const [doneTime, setDoneTime] = useState<number>(0);
  const [progressTime, setProgressTime] = useState<number>(0);
  const [totalTime, setTotalTime] = useState<number>(0);
  const [overTime, setOverTime] = useState<number>(0);

  return (
    <AppLayout>
      <>
        <Submenu />
        <div className="container bg-white padding-20 width-100pct margin-top-20">
          <h3>Progresso da manutenção</h3>
          <Rate
            step={step}
            setStep={setStep}
            doneTime={mockTimeline.reduce(
              (acc, item) => acc + (item.time || 0),
              0
            )} // Soma do tempo concluído
            progressTime={30} // Exemplo de tempo em andamento
            totalTime={480} // Exemplo de tempo total estimado
            overTime={20} // Exemplo de tempo excedente
            timeline={mockTimeline}
          />

          <Divider className="margin-top-20" />

          {/* {step === 0 && (
            <MaintenanceRequest
              scheduling={scheduling}
              fanceData={undefined}
              ticket={ticket}
            />
          )}
          {step === 1 && (
            <ScreeningDiagnosis
              screening={ticket?.screening}
              ticketId={ticket?.id}
              scheduling={scheduling}
            />
          )}
          {step === 2 && (
            <Repair repair={ticket?.repair} ticketId={ticket?.id} />
          )}
          {step === 3 && (
            <Inspection
              scheduling={scheduling}
              inspection={ticket?.inspection}
              ticketId={ticket?.id}
              request={ticket?.request}
            />
          )} */}
        </div>
      </>
    </AppLayout>
  );
};

export default Assessment;
