import { Navigate, Route, Routes } from "react-router-dom";

import MaintenanceControl from "./maintenanceControl";
import NewAppointment from "./newAppointment";
import MyAppointmens from "./myAppointments";
import DealershipMaintenanceMonitoringRoutes from "./maitenanceMonitoring/routes";

const DealershipRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/dealership/maintenance-control" />} />
    <Route path="/maintenance-control" element={<MaintenanceControl />} />
    <Route path="/maintenance-monitoring/*" index element={<DealershipMaintenanceMonitoringRoutes />} />
    <Route path="/my-appointments" element={<MyAppointmens />} />
    <Route path="/new-appointment" element={<NewAppointment />} />
  </Routes>
);

export default DealershipRoutes;
