import React from "react";
import "./styles.scss";
import { IInspection } from "../../services/fleetManager/inspectionsService";

interface IScheduleModalProps {
  isOpen: boolean;
  onClose: () => void;
  vehicle: IInspection | null;
}

const ScheduleModal = ({ isOpen, onClose, vehicle }: IScheduleModalProps) => {
  if (!isOpen || !vehicle) return null;

  return (
    <div className="schedule-modal-overlay">
      <div className="schedule-modal">
        <button onClick={onClose} className="schedule-modal-close">
          <span className="rioglyph rioglyph-remove"></span>
        </button>
        <div className="text-size-20">Agendamento</div>

        <hr className="margin-top-5" />

        <div className="margin-top-25 border height-200 width-100pct">
          <div className="centered-content">
            <div
              className="child-centered-content text-color-status-resting"
              style={{ fontSize: 30 }}
            >
              <span className="text-color-status-driving text-bold">Volks</span>
              |Total
            </div>
            <div className="child-centered-content">
              Quero agendar com VolksTotal
            </div>
            <button
              type="button"
              className="btn btn-primary child-centered-content"
            >
              Continuar
            </button>
          </div>
        </div>

        <div className="margin-top-15 border height-200 width-100pct">
          <div className="centered-content">
            <div className="child-centered-content" style={{ fontSize: 30 }}>
              <span className="rioglyph rioglyph-workshop"></span>
            </div>
            <div className="child-centered-content">
              Quero simplesmente agendar
            </div>
            <button
              type="button"
              className="btn btn-primary child-centered-content"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleModal;
