import { useEffect, useState } from "react";
import { Divider } from "@rio-cloud/rio-uikit";
import Page from "../Page";
import Submenu from "./components/submenu";
import Timeline from "./components/timeline";
import MaintenanceRequest from "./components/maintenanceRequest";
import Inspection from "./components/inspection";
import { Scheduling } from "../../../models/dealership/schedule";
import { fetchScheduling } from "../../../api/fleetManager/fetchScheduling";
import { useSearchParams } from "react-router-dom";
import { Loading } from "../../../utils/loading";
import { MaintenanceMonitoringTimelineStep, Ticket } from "../../../models/ticket";
import { fetchTicket, fetchTickets, openTicket, postCheckoutTicket, putInspection, putRepair, putScreening } from "../../../api/ticket";
import { initialTimeline } from "../../../dtos/ticketDto";
import ScreeningDiagnosis from "./components/screeningDiagnosis";
import { MaintenanceMonitoringStatusEnum } from "../../../enums/maintenanceMonitoring";
import { FinishStepDialog } from "./dialogs/finishStepDialog";
import Repair from "./components/repair";

const MaintenanceMonitoring = () => {
    const [searchParams] = useSearchParams();

    const [finishStepDialog, setFinishStepDialog] = useState<boolean>(false);
    const [finishStepDialogLoading, setFinishStepDialogLoading] = useState<boolean>(false);

    const [step, setStep] = useState<number>(0);
    const [currentStatusStep, setCurrentStatusStep] = useState<number | undefined>(undefined);

    const [scheduling, setScheduling] = useState<Scheduling | undefined>(undefined);
    const [ticket, setTicket] = useState<Ticket | undefined>(undefined);
    const [timeline, setTimeline] = useState<MaintenanceMonitoringTimelineStep[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            const schedulingId = searchParams.get("scheduling-id");
            if (!schedulingId) return setStep(0), setCurrentStatusStep(0);

            const scheduling = await fetchScheduling(schedulingId);
            if (!scheduling) return setStep(0), setCurrentStatusStep(0);
            setScheduling(scheduling);

            const ticketId = (await fetchTickets({ consultantUser: true })).find(ticket => ticket.request?.schedulingId === schedulingId)?.id;
            const ticket = ticketId ? await fetchTicket(ticketId) : undefined;
            setTicket({
                ...ticket,
                request: { ...ticket?.request, schedulingId },
                timeline: ticket?.timeline ?? initialTimeline,
            });

            setTimeline(ticket?.timeline ?? initialTimeline);
            const currentStep = (ticket?.timeline ?? initialTimeline)?.findIndex(step => !step.time && step.time !== 0);
            setStep(currentStep < 0 ? 0 : currentStep), setCurrentStatusStep(currentStep < 0 ? 0 : currentStep);
        }

        fetchData();
    }, [searchParams]);

    const [doneTime, setDoneTime] = useState<number>(0);
    const [progressTime, setProgressTime] = useState<number>(0);
    const [totalTime, setTotalTime] = useState<number>(0);
    const [overTime, setOverTime] = useState<number>(0);
    useEffect(() => {
        const interval = setInterval(() => {
            const done = timeline
                .filter(step => step.status === MaintenanceMonitoringStatusEnum.DONE)
                .reduce((acc, step) => acc + (step.time ?? 0), 0);
            setDoneTime(done);

            const progress = timeline
                .filter(step => step.status === MaintenanceMonitoringStatusEnum.IN_PROGRESS)
                .reduce((acc, step) => acc + (Math.floor((new Date().getTime() - step?.date!.getTime()) / 60000)), 0);
            setProgressTime(progress);
            
            const estimedTime = timeline.reduce((acc, step) => acc + (step.estimatedTime ?? 0), 0);
            setTotalTime((done + progress) > estimedTime ? (done + progress) : estimedTime);
            setOverTime((done + progress) - estimedTime < 0 ? 0 : (done + progress) - estimedTime);
        }, 1000);
        return () => clearInterval(interval);
    }, [step, timeline]);

    const startStep = async (index: number, updatedTimeline?: MaintenanceMonitoringTimelineStep[] | undefined) => {
        const startDateTime = new Date();
        setTimeline((updatedTimeline ?? timeline).map((step, i) => {
            if (i === index) {
                return {
                    ...step,
                    status: MaintenanceMonitoringStatusEnum.IN_PROGRESS,
                    date: startDateTime,
                    time: 0
                }
            }
            return step;
        }));

        if (index === 0) {
            const id = await openTicket({ date: startDateTime, schedulingId: ticket?.request?.schedulingId ?? '' });
            const createdTicket = await fetchTicket(id);
            setTicket(createdTicket);
        }
        if (index === 1) putScreening({ screening: { checkIn: startDateTime }, ticketId: ticket?.id ?? '' }), ticket!.screening = {...(ticket?.screening ?? {}), checkIn: startDateTime};
        if (index === 2) putRepair({ repair: { checkIn: startDateTime }, ticketId: ticket?.id ?? '' }), ticket!.repair = { ...(ticket?.repair ?? {}), checkIn: startDateTime};
        if (index === 3) putInspection({ inspection: { checkIn: startDateTime }, ticketId: ticket?.id ?? '', checklist: ticket?.request?.checkupChecklist }), ticket!.inspection = { ...(ticket?.inspection ?? {}), checkIn: startDateTime};;
    }

    const finishStep = async (index: number): Promise<boolean> => {
        const finishDateTime = new Date();

        if (index === 0) {
            if(!await postCheckoutTicket({ date: finishDateTime, ticketId: ticket?.id ?? '' })) return false;
            ticket!.request!.checkOut = finishDateTime;
        }
        
        if (index === 1) {
            if(!await putScreening({ screening: { ...ticket?.screening, checkOut: finishDateTime }, ticketId: ticket?.id ?? '' })) return false;
            ticket!.screening!.checkOut = finishDateTime;
        }
        
        if (index === 2) {
            if(!await putRepair({ repair: { ...ticket?.repair, checkOut: finishDateTime }, ticketId: ticket?.id ?? '' })) return false;
            ticket!.repair!.checkOut = finishDateTime;
        }

        if (index === 3) {
            if(!await putInspection({ inspection: { ...ticket?.inspection, checkOut: finishDateTime }, ticketId: ticket?.id ?? '', checklist: ticket?.request?.checkupChecklist })) return false;
            ticket!.inspection!.checkOut = finishDateTime;
        }

        
        const updatedTimeline = timeline.map((step, i) => {
            if (i === index) {
                return {
                    ...step,
                    status: MaintenanceMonitoringStatusEnum.DONE,
                    time: Math.floor((finishDateTime.getTime() - step.date!.getTime()) / 60000)
                }
            }
            return step;
        });
        setTimeline(updatedTimeline);
        startStep(index + 1, updatedTimeline);

        setCurrentStatusStep(index + 1);
        setStep(index + 1);

        return true;
    }

    return (
        <Page>
            <>
                {currentStatusStep === undefined && <Loading label="Carregando dados..." />}

                {
                    ticket && (
                        <>
                            <Submenu />
                            <div className="container bg-white padding-20 width-100pct margin-top-20">
                                <h3>Progresso da manutenção</h3>
                                <Timeline
                                    step={step}
                                    setStep={(step: number) => setStep(step)}
                                    currentStatusStep={currentStatusStep}
                                    timeline={timeline}
                                    doneTime={doneTime}
                                    progressTime={progressTime}
                                    overTime={overTime}
                                    totalTime={totalTime}
                                    startStep={startStep}
                                    finishStep={() => setFinishStepDialog(true)}
                                />

                                <Divider className="margin-top-20" />

                                {step === 0 && <MaintenanceRequest scheduling={scheduling} fanceData={undefined} />}
                                {step === 1 && <ScreeningDiagnosis screening={ticket?.screening} ticketId={ticket?.id} setCheckOut={() => setFinishStepDialog(true)} scheduling={scheduling} />}
                                {step === 2 && <Repair repair={ticket?.repair} ticketId={ticket?.id} />}
                                {step === 3 && <Inspection inspection={ticket?.inspection} ticketId={ticket?.id} request={ticket?.request} scheduling={scheduling} />}
                            </div>
                        </>
                    )
                }
            </>

            <FinishStepDialog 
                open={finishStepDialog}
                onClose={() => setFinishStepDialog(false)}
                loading={finishStepDialogLoading}
                onFinish={async () => {
                    setFinishStepDialogLoading(true);
                    await finishStep(currentStatusStep!);
                    setFinishStepDialogLoading(false);
                    setFinishStepDialog(false);
                }}
            />
        </Page>
    );
}

export default MaintenanceMonitoring;