export const Loading = ({label}: {label?: string}) => (
    <div className="height-100">
        <div className="spinnerInfoBox display-flex justify-content-center align-items-center height-100pct">
            <div className="spinner">
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
            </div>
            <div className="spinnerText">{label ?? 'Loading'}</div>
        </div>
    </div>
);