import { ScheduleCheckup } from "../../models/fleetManager/checkup";
import { CheckupDto } from "./checkupDto";

export const ScheduleCheckupDto = (data: any): ScheduleCheckup => {
    return {
        id: data.scheduleMaintenanceInfo.id,
        checkup: CheckupDto(data.checkup),
        odometer: data.scheduleMaintenanceInfo.odometer,
        hourmeter: data.scheduleMaintenanceInfo.hourmeter,
        os: '',
        date: data.scheduleMaintenanceInfo.scheduledDate && new Date(data.scheduleMaintenanceInfo.scheduledDate),
        status: data.scheduleMaintenanceInfo.state,
        done: data.scheduleMaintenanceInfo.state === 'Finalizado',
        isSigned: true,
    };
}