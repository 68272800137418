import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger";
import Tooltip from "@rio-cloud/rio-uikit/Tooltip";
import CheckupDetailDialog from "../dialogs/checkupDetailDialog";
import { useEffect, useState } from "react";
import { Asset } from "../../../models/fleetManager/asset";
import { Checkup } from "../../../models/fleetManager/checkup";
import { fetchCheckup } from "../../../api/fleetManager/fetchCheckup";

interface ICheckupInfoProps {
    asset?: Asset;
}

const CheckupInfo = ({ asset }: ICheckupInfoProps) => {
    const [checkupDetailDialog, setCheckupDetailDialog] = useState<boolean>(false);
    const [checkup, setCheckup] = useState<Checkup | undefined>(undefined);

    useEffect(() => {
        const fetchData = async () => {
            if(asset?.nextCheckups && asset.nextCheckups.length > 0) {
                const checkup = await fetchCheckup(asset?.nextCheckup?.checkup.id ?? "");
                setCheckup(checkup);
            }
        }

        fetchData();
    }, [asset]);

    return (
        checkup ?
            <div>
                <OverlayTrigger
                    key={crypto.randomUUID()}
                    trigger="click"
                    placement="left"
                    overlay={
                        <Tooltip id="tooltip" allowOnTouch>
                            <div className="tooltip-card flex flex-column padding-10 gap-6">
                                <h3 className="flex flex-column text-size-18 font-bold items-start text-left">
                                    Revisão {checkup.description || checkup.name}
                                    <span className="text-size-12 font-normal">
                                        {[asset?.nextCheckup?.date?.toLocaleDateString('pt-BR'), `${checkup.odometer} km`].filter(Boolean).join(' ou ')}
                                    </span>
                                </h3>
                                <ul className="text-left">
                                    {
                                        checkup.services
                                            .slice(0, 4)
                                            .map((service, index) => (
                                                <li key={index}>{service.name}</li>
                                            ))
                                    }
                                </ul>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => setCheckupDetailDialog(true)}
                                >
                                    <span>Ver lista completa</span>
                                </button>
                            </div>
                        </Tooltip>
                    }
                >
                    <span className="cursor-pointer rioglyph rioglyph-exclamation text-size-12 text-color-white icon-revision"></span>
                </OverlayTrigger>

                <CheckupDetailDialog
                    asset={asset}
                    checkup={checkup}
                    showCheckupDetailDialog={checkupDetailDialog} 
                    setShowCheckupDetailDialog={(e:boolean) => setCheckupDetailDialog(e)} 
                />
            </div>
            : null
    );
}

export default CheckupInfo;