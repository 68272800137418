import { MaintenanceMonitoringStatusEnum, MaintenanceMonitoringStepsEnum } from "../enums/maintenanceMonitoring";
import { MaintenanceMonitoringTimelineStep, Ticket } from "../models/ticket";

const getStatus = (hasCheckIn: boolean, hasCheckOut: boolean): MaintenanceMonitoringStatusEnum => {
    if (hasCheckIn && hasCheckOut) {
        return MaintenanceMonitoringStatusEnum.DONE;
    } else if (hasCheckIn) {
        return MaintenanceMonitoringStatusEnum.IN_PROGRESS;
    } else {
        return MaintenanceMonitoringStatusEnum.PENDING;
    }
}

const getTime = (checkIn: Date, checkOut: Date): number => {
    return (checkOut.getTime() - checkIn.getTime()) / 60000;
}

export const initialTimeline: MaintenanceMonitoringTimelineStep[] = [
    {
        step: MaintenanceMonitoringStepsEnum.TICKET,
        estimatedTime: 90,
        status: MaintenanceMonitoringStatusEnum.PENDING,
    },
    {
        step: MaintenanceMonitoringStepsEnum.SCREENING,
        estimatedTime: 90,
        status: MaintenanceMonitoringStatusEnum.PENDING,
    },
    {
        step: MaintenanceMonitoringStepsEnum.REPAIR,
        estimatedTime: 90,
        status: MaintenanceMonitoringStatusEnum.PENDING,
    },
    {
        step: MaintenanceMonitoringStepsEnum.INSPECTION,
        estimatedTime: 90,
        status: MaintenanceMonitoringStatusEnum.PENDING,
    },
    {
        step: MaintenanceMonitoringStepsEnum.RELEASE,
        estimatedTime: 90,
        status: MaintenanceMonitoringStatusEnum.PENDING,
    },
];

export const ticketDto = (data: any): Ticket => {
    return {
        id: data.id,
        step: data.status || 'TICKET',
        request: {
            schedulingId: data.maintenanceScheduleId,
            checkIn: data.ticket?.checkInDate ? new Date(data.ticket.checkInDate + 'T' + data.ticket.checkInHour) : undefined,
            checkOut: data.ticket?.checkOutDate ? new Date(data.ticket.checkOutDate + 'T' + data.ticket.checkOutHour) : undefined,
            checkupChecklist: (data.ticket?.checkupChecklist ?? [])
                .map((checkupChecklist: any) => {
                    return {
                        name: checkupChecklist.name,
                        items: Object.keys(checkupChecklist.items ?? {}).map((key: any) => {
                            const item = checkupChecklist.items[key];
                            return {
                                id: key,
                                name: item.name,
                                ok: item.ok
                            };
                        })
                    }
                }),
        },
        screening: {
            dealership: data.screening?.dealership,
            corporateTaxId: data.screening?.corporateTaxId,
            location: data.screening?.location,
            agentName: data.screening?.agentName,
            checkIn: data.screening?.checkInDate ? new Date(data.screening?.checkInDate + 'T' + data.screening?.checkInHour) : undefined,
            checkOut: data.screening?.checkOutDate ? new Date(data.screening?.checkOutDate + 'T' + data.screening?.checkOutHour) : undefined,
            checkInDriverLocation: data.screening?.checkInDriverLocation,
            serviceOrder: data.screening?.serviceOrder,
            serviceOrderDate: data.screening?.serviceOrderDate ? new Date(data.screening?.serviceOrderDate + 'T' + data.screening?.serviceOrderHour) : undefined,
            failIndication: data.screening?.failIndication,
            failCode: data.screening?.failCode,
            diagnosisTool: data.screening?.diagnosisTool,
            diagnosisReport: data.screening?.diagnosisReport,
            troubleshootingDoc: data.screening?.troubleshootingDoc,
            estimatedTimeTroubleshooting: data.screening?.estimatedTimeTroubleshooting,
            estimatedTimeBoxEntry: data.screening?.estimatedTimeBoxEntry,
            estimatedTimeRepair: data.screening?.estimatedTimeRepair,
            report: data.screening?.report
        },
        repair: {
            checkIn: data.repair?.checkInDate ? new Date(data.repair.checkInDate + 'T' + data.repair.checkInHour) : undefined,
            checkOut: data.repair?.checkOutDate ? new Date(data.repair.checkOutDate + 'T' + data.repair.checkOutHour) : undefined,
            repairDate: data.repair?.repairDate ? new Date(data.repair.repairDate) : undefined,
            awaitingParts: data.repair?.awaitingParts,
            estimatedTime: data.repair?.estimatedTime,
            estimatedTroubleshooting: data.repair?.estimatedTroubleshooting,
            report: data.repair?.report
        },
        inspection: {
            checkIn: data.inspection?.checkInDate ? new Date(data.inspection.checkInDate + 'T' + data.inspection.checkInHour) : undefined,
            checkOut: data.inspection?.checkOutDate ? new Date(data.inspection.checkOutDate + 'T' + data.inspection.checkOutHour) : undefined,
            serviceValidation: data.inspection?.serviceValidation,
            estimatedTime: data.inspection?.estimatedTime,
            report: data.inspection?.report,
        },
        timeline: [
            {
                step: MaintenanceMonitoringStepsEnum.TICKET,
                estimatedTime: 90,
                status: getStatus(!!data.ticket?.checkInDate, !!data.ticket?.checkOutDate),
                date: data.ticket?.checkInDate ? new Date(data.ticket.checkInDate + 'T' + data.ticket?.checkInHour) : undefined,
                time: data.ticket?.checkOutDate ? getTime(new Date(data.ticket.checkInDate + 'T' + data.ticket?.checkInHour), new Date(data.ticket.checkOutDate + 'T' + data.ticket?.checkOutHour)) : undefined
            },
            {
                step: MaintenanceMonitoringStepsEnum.SCREENING,
                estimatedTime: 90,
                status: getStatus(!!data.screening?.checkInDate, !!data.screening?.checkOutDate),
                date: data.screening?.checkInDate ? new Date(data.screening.checkInDate + 'T' + data.screening?.checkInHour) : undefined,
                time: data.screening?.checkOutDate ? getTime(new Date(data.screening.checkInDate + 'T' + data.screening?.checkInHour), new Date(data.screening.checkOutDate + 'T' + data.screening?.checkOutHour)) : undefined
            },
            {
                step: MaintenanceMonitoringStepsEnum.REPAIR,
                estimatedTime: 90,
                status: getStatus(!!data.repair?.checkInDate, !!data.repair?.checkOutDate),
                date: data.repair?.checkInDate ? new Date(data.repair.checkInDate + 'T' + data.repair?.checkInHour) : undefined,
                time: data.repair?.checkOutDate ? getTime(new Date(data.repair.checkInDate + 'T' + data.repair?.checkInHour), new Date(data.repair.checkOutDate + 'T' + data.repair?.checkOutHour)) : undefined
            },
            {
                step: MaintenanceMonitoringStepsEnum.INSPECTION,
                estimatedTime: 90,
                status: getStatus(!!data.inspection?.checkInDate, !!data.inspection?.checkOutDate),
                date: data.inspection?.checkInDate ? new Date(data.inspection.checkInDate + 'T' + data.inspection?.checkInHour) : undefined,
                time: data.inspection?.checkOutDate ? getTime(new Date(data.inspection.checkInDate + 'T' + data.inspection?.checkInHour), new Date(data.inspection.checkOutDate + 'T' + data.inspection?.checkOutHour)) : undefined
            },
            {
                step: MaintenanceMonitoringStepsEnum.RELEASE,
                estimatedTime: 90,
                status: MaintenanceMonitoringStatusEnum.PENDING,
            },
        ]
    };
}