import { Asset, AssetSummary } from "../../models/fleetManager/asset";
import { AssetPlanDto } from "./assetPlanDto";
import { ScheduleCheckupDto } from "./scheduleCheckupDto";

export const AssetDto = (data: any): Asset => {
  return {
    id: data.vehicle.assetId,
    name: data.vehicle.name,
    model: data.vehicle.vehicleModel,
    chassis: data.vehicle.chassis,
    odometer: data.vehicle.odometer,
    hourmeter: data.vehicle.hourMeter,
    year: data.vehicle.vehicleYear,
    licensePlate: data.vehicle.plate,
    plans: data.vehiclePlan.map(AssetPlanDto),
    volksTotalPlan: data.volksTotal,
    madeCheckups: data.madeCheckups.map(ScheduleCheckupDto),
    madeOilChangeCheckups: data.madeOilCheckups.map(ScheduleCheckupDto),
    lastCheckup: data.lastVehicleCheckup
      ? ScheduleCheckupDto(data.lastVehicleCheckup)
      : undefined,
    nextCheckups: data.nextCheckups.map(ScheduleCheckupDto),
    nextOilChangeCheckups: data.nextOilCheckups.map(ScheduleCheckupDto),
    nextCheckup:data.nextVehicleCheckup
        ? ScheduleCheckupDto({
          ...data.nextVehicleCheckup,
          scheduleMaintenanceInfo: data.nextVehicleCheckup.schedule,
        })
        : undefined,
    checkupActions: data.autoWorkshopActivitiesAmount ?? 0,
    score: data.vehicleScore ?? "-",
    groupName: data.vehicle.vehicleGroup,
    groups: data.groups,
    warrantyStatus: data.warrantyStatus,
    status: data.status,
    geoLocation: data.geoLocation,
    campaigns: data.campaigns,
  };
};

export const AssetSummaryDto = (data: any): AssetSummary => {
  return {
    id: data.id,
    name: data.name,
    groupIds: data.groupIds,
  };
};
