import { useState, useEffect } from "react";
import isEmpty from "lodash/fp/isEmpty";
import TreeCategory from "@rio-cloud/rio-uikit/TreeCategory";
import Tree, { TreeItem } from "@rio-cloud/rio-uikit/Tree";
import { AssetGroup, AssetSummary } from "../../models/fleetManager/asset";
import { fetchAssets } from "../../api/fleetManager/fetchAssets";
import { fetchAssetGroups } from "../../api/fleetManager/fetchAssetGroups";

import "./styles.scss";

const CATEGORY_FILTER = "filter";
const CATEGORY_ASSETS = "assets";

interface IAssetsTreeProps {
  assetIds: string[];
  groupIds: string[];
  setAssetIds: (assetIds: string[]) => void;
  setGroupIds: (groupIds: string[]) => void;
}

const AssetTreeSidebar = (props: IAssetsTreeProps) => {
  const [assets, setAssets] = useState<AssetSummary[]>([]);
  const [assetGroups, setAssetGroups] = useState<AssetGroup[]>([]);
  const [expandedAssetGroups, setExpandedAssetGroups] = useState<string[]>([]);
  const [, setSearchValue] = useState<string>("");
  const [showEmptyGroups, ] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, ] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      
      const [assets, groups] = await Promise.all([
        fetchAssets(),
        fetchAssetGroups(),
      ]);
      
      setAssets(
        assets.map((asset) => ({
          ...asset,
          groupIds: 
            asset.groupIds.length === 0 || !(groups.some(group => asset.groupIds.includes(group.id))) ? 
              ['ungruped'] : asset.groupIds,
        }))
      );
      setAssetGroups([...groups, { id: "ungruped", name: "Desagrupadas" }]);
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleSelectAsset = ({ items, groups }: any) => {
    props.setAssetIds(items);
    props.setGroupIds(groups);

    if(groups.length > 0) {
      props.setAssetIds(
        assets.filter(asset => groups.some((group: any) => asset.groupIds.includes(group))).map(asset => asset.id)
      );
    }
  };

  const handleExpandAssetGroups = (newExpandedAssetGroups: string[]) =>
    setExpandedAssetGroups(newExpandedAssetGroups);

  const handleUpdateSearch = (value: string) => setSearchValue(value);

  if (loading) {
    return <div className="loading-indicator">Carregando...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="display-flex flex-wrap gap-25 overflow-auto">
      <div className="w-full">
        <TreeCategory
          key={CATEGORY_FILTER}
          id={CATEGORY_FILTER}
          label="Filter"
          icon="rioglyph-filter"
        />

        <TreeCategory
          key={CATEGORY_ASSETS}
          id={CATEGORY_ASSETS}
          label="Assets"
          icon="rioglyph-truck"
          hasSelection={!isEmpty([]) || !isEmpty([])}
        >
          <Tree
            groups={assetGroups}
            items={assets as TreeItem[]}
            expandedGroups={expandedAssetGroups}
            onExpandGroupsChange={handleExpandAssetGroups}
            selectedGroups={props.groupIds}
            selectedItems={props.assetIds}
            onSelectionChange={handleSelectAsset}
            searchPlaceholder="Buscar veículo"
            onSearchChange={handleUpdateSearch}
            showEmptyGroups={showEmptyGroups}
          />
        </TreeCategory>
      </div>
    </div>
  );
};

export default AssetTreeSidebar;
