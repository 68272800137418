import { useEffect, useState } from "react";
import DatePicker from "@rio-cloud/rio-uikit/DatePicker";
import type { Moment } from "moment";
import { TicketRepair } from "../../../../models/ticket";
import { fetchTicketImages, ITicketFile, uploadTicketImage } from "../../../../api/ticket";
import { FilePicker } from "@rio-cloud/rio-uikit";

const accept = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/svg": [],
};

interface IRepairProps {
  ticketId: string | undefined;
  repair: TicketRepair | undefined;
}

const Repair = ({ ticketId, repair }: IRepairProps) => {
  const [file, setFile] = useState<ITicketFile | undefined>();
  
    useEffect(() => {
      const fetchData = async () => {
        if (repair && ticketId) {
          const images = await fetchTicketImages({ ticketId: ticketId, step: 'REPAIR' })
          setFile(images[0]);
        };
      }
  
      fetchData();
    }, [repair]);

  return (
    <div>
      <div className="resume-section">
        <div className="resume-container padding-0">
          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="data">Data</label>
              <DatePicker
                id="data"
                inputProps={{ placeholder: "--/--/----" }}
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                onChange={(result: Moment | string) => {
                  if (typeof result === "string") {
                    repair!.repairDate = new Date(result);
                  } else {
                    repair!.repairDate = result.toDate();
                  }
                }}
                className="margin-bottom-0"
              />
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="checkin">Check-in</label>
              <input
                id="checkin"
                className="form-control"
                type="text"
                placeholder="--/--/----"
                disabled
                value={repair?.checkIn?.toLocaleDateString('pt-BR')}
              />
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="hora-checkin" className="opacity-0">Hora</label>
              <div className="input-group">
                <span className="input-group-addon"><span className="rioglyph rioglyph-time"></span></span>
                <input
                  id="hora-checkin"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  disabled
                  value={repair?.checkIn?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="checkout">Check-out</label>
              <input
                id="checkout"
                className="form-control"
                type="text"
                placeholder="--/--/----"
                disabled
                value={repair?.checkOut?.toLocaleDateString('pt-BR')}
              />
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="hora-checkout" className="opacity-0">Hora</label>
              <div className="input-group">
                <span className="input-group-addon"><span className="rioglyph rioglyph-time"></span></span>
                <input
                  id="hora-checkout"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  disabled
                  value={repair?.checkOut?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="pecas">Aguardando Peças</label>
              <div className="input-group">
                <input
                  id="pecas"
                  className="form-control"
                  type="text"
                  value={repair?.awaitingParts}
                  onChange={(e) => repair!.awaitingParts = e.target.value}
                  disabled={!repair?.checkIn || !!repair?.checkOut}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="literatura">
                Tempo Estimado Conforme Literatura
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="literatura"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  value={repair?.estimatedTime}
                  onChange={(e) => repair!.estimatedTime = e.target.value}
                  disabled={!repair?.checkIn || !!repair?.checkOut}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="troubleshooting">
                Troubleshoot Estimado em Solução Conforme Literatura
              </label>
              <input
                id="troubleshooting"
                className="form-control"
                type="text"
                value={repair?.estimatedTroubleshooting}
                onChange={(e) => repair!.estimatedTroubleshooting = e.target.value}
                disabled={!repair?.checkIn || !!repair?.checkOut}
              />
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="relato">Relato</label>
              <textarea
                className="form-control height-100"
                id="relato"
                placeholder="Descritivo"
                value={repair?.report}
                onChange={(e) => repair!.report = e.target.value}
                disabled={!repair?.checkIn || !!repair?.checkOut}
              ></textarea>
            </div>
          </div>
          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="relato">Inspeção visual</label>
              {
                  !!!repair?.checkOut && 
                  !file &&
                  !(!repair?.checkIn || !!repair?.checkOut) &&
                  <FilePicker
                    displayMode='dropzone'
                    className='margin-bottom-20'
                    onPick={(acceptedFiles: FileList | null) => {
                      const filePicked = acceptedFiles?.[0];
                      setFile(
                        filePicked ?
                        { 
                          name: filePicked.name, 
                          updatedAt: new Date(filePicked.lastModified).toLocaleDateString('pt-BR'), 
                          contentType: filePicked.type, 
                          contentSize: filePicked.size 
                        }
                        : undefined
                      );
                      if(acceptedFiles?.[0] && ticketId) uploadTicketImage({ ticketId: ticketId, imageFile: acceptedFiles[0], step: 'REPAIR' });
                    }}
                    multiple={false}
                    accept={accept}
                  >
                    {dropArea}
                  </FilePicker>
                }
                <FilePreview file={file} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const dropArea = ({ isDragActive }: { isDragActive: boolean }) => (
  <div
    className={`
          rounded border border-style-dashed border-color-gray bg-lightest text-size-14
          text-center text-color-darker opacity-50 padding-25 cursor-pointer
      `}
  >
    <span className='rioglyph rioglyph-files text-size-200pct opacity-50 margin-top-20' />
    <div className='margin-top-10'>
      {isDragActive ? 'Jogue os arquivos aqui ...' : 'Arraste arquivos de imagem para cá ou clique para selecionar no computador'}
    </div>
  </div>
);

const FilePreview = ({ file }: { file: ITicketFile | undefined }) => (
  <div className='panel panel-default margin-top-20'>
    <div className='panel-body'>
      {file && (
        <>
          <div>
            <b>{`${file?.name}`}</b>
          </div>
          <div>tipo: {`${file?.contentType}`}</div>
          <div>tamanho: {`${file?.contentSize}`}</div>
          <div>data da última modificação: {`${file?.updatedAt}`}</div>
          <div>caminho: {`${file?.name}`}</div>
        </>
      )}
      {!file && <div className='text-color-dark text-italic'>Nenhum arquivo</div>}
    </div>
  </div>
);

export default Repair;
