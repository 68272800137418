import { dealershipDto } from "../../dtos/fleetManager/dealershipDto";
import { Dealership } from "../../models/fleetManager/dealership";
import ApiService from "../../services/api";

export const fetchFavoritesDealerships = async (): Promise<Dealership[]> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.get<any>(`/dealerships/favorites`);
        if (response.status === 200) {
            return response.data.map(dealershipDto);
        } else return [];
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const fetchDealershipsByGeolocation = async (lat: number, lng: number): Promise<Dealership[]> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.get<any>(`/dealerships?latitude=${lat}&longitude=${lng}&maxDistance=50`);
        if (response.status === 200) {
            return response.data.map(dealershipDto);
        } else return [];
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const fetchDealershipsByStateAndCity = async (state: string, city: string): Promise<Dealership[]> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.get<any>(`/dealerships?state=${state}&city=${city}`);
        if (response.status === 200) {
            return response.data.map(dealershipDto);
        } else return [];
    } catch (error) {
        console.error(error);
        return [];
    }
}