import { useState } from "react";
import { IChannel } from "../../../services/amityService";
import { timeDifference } from "../../../utils/dateUtils";
import { Loading } from "../../../utils/loading";

interface IMessagesProps {
    loadingChats: boolean;
    handleNewChat: () => void;
    handleOpenScheduling: () => void;
    handleOpenMessages: (channel: IChannel) => void;
    channels: IChannel[];
}

const Messages = ({ loadingChats, handleNewChat, handleOpenScheduling, handleOpenMessages, channels }: IMessagesProps) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    
    return (
        <>
            <h3 className="flex justify-between items-center gap-6 chat-sidebar__title">
                Mensagens
                {/* <button
                    type="button"
                    className="btn btn-primary btn-link p-0"
                    onClick={handleNewChat}
                >
                    <span className="rioglyph rioglyph-plus"></span>
                </button> */}
            </h3>

            <div className="search-field">
                <div className="form-group">
                    <label className="width-100pct">
                        <input
                            className="form-control margin-y-5"
                            type="text"
                            placeholder="Pesquisar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <button className="btn btn-action padding-0">
                            <span className="rioglyph rioglyph-search text-color-primary"></span>
                        </button>
                    </label>
                </div>
            </div>

            <div className="chats-list">
                {/*<div
                    className="flex items-center justify-between gap-6 travels-scheduling"
                    onClick={handleOpenScheduling}
                >
                    <span className="rioglyph rioglyph-calendar text-color-primary"></span>
                    <div className="flex flex-column travels-scheduling__info">
                        <span className="text-color-black text-size-18 text-medium">
                            Agendamentos
                        </span>
                        <span className="text-color-black text-size-14">
                            1 pendente
                        </span>
                    </div>
                    <span className="rioglyph rioglyph-chevron-right text-color-primary"></span>
                </div>*/}

                {
                    loadingChats && <Loading label="Carregando..." />
                }

                {channels
                    .filter((channel) => channel.displayName.toLowerCase().includes(searchTerm.toLowerCase()))
                    .map((channel) => (
                        <div
                            onClick={() => handleOpenMessages(channel)}
                            className="flex items-center justify-between card-info"
                        >
                            <div className="w-1/6 padding-10">
                                <img src={channel.profileImage ?? `/assets/images/profile.png`} alt="" className="h-[35px] rounded-full" />
                                {channel.hasUnreadMessage && <span className="card-info__alert" />}
                            </div>
                            <div className="flex flex-column w-4/6 card-info__name">
                                <div className="text-color-black text-size-18 text-medium">
                                    {channel.displayName.split('__')[0]}
                                </div>
                                <div className="text-color-gray font-size-12 truncate">
                                    {channel.displayName.split('__')[1]}
                                    {/* Consultor(a) */}
                                </div>
                            </div>
                            <div className="card-info__time w-1/6">{timeDifference(channel.lastActivity)}</div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default Messages;