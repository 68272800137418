import Checkbox from "@rio-cloud/rio-uikit/Checkbox";
import Multiselect from "@rio-cloud/rio-uikit/Multiselect";
import Switch from "@rio-cloud/rio-uikit/Switch";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Asset } from "../../../../models/fleetManager/asset";
import Select from "@rio-cloud/rio-uikit/Select";
import { Driver } from "../../../../models/fleetManager/driver";
import {
  Checkup,
  CheckupAdicionalService,
  CheckupService,
} from "../../../../models/fleetManager/checkup";
import { fetchCheckup } from "../../../../api/fleetManager/fetchCheckup";
import { getUserProfile } from "../../../../configuration/login/loginSlice";

export interface IAssetAndDriverForm {
  asset?: Asset;
  scheduleByDriver?: boolean;
  checkup?: Checkup;
  driver?: Driver;
  schedulingTypes?: string[];
  aditionalServices?: CheckupAdicionalService[];
  comments?: string | null;
  obs?: string;
}

interface IAssetsAndDriversProps {
  setStep: (step: number) => void;
  assets: Asset[];
  drivers: Driver[];
  form?: IAssetAndDriverForm;
  setAppointmentForm: (form: IAssetAndDriverForm) => void;
}

const AssetsAndDriversForm = ({
  setStep,
  assets,
  drivers,
  form,
  setAppointmentForm,
}: IAssetsAndDriversProps) => {
  const userProfile = useSelector(getUserProfile);

  const [showComments, setShowComments] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const assetId = queryParams.get("asset");
      if (assetId) chooseAsset(assetId, assets);
    };

    if (!form?.asset) fetchData();
  }, []);

  const chooseAsset = (assetId: string, assets: Asset[]) => {
    const asset = assets.find((asset) => asset.id === assetId);
    setAppointmentForm({ ...form, asset });
    chooseCheckup(asset);
  };

  const chooseCheckup = async (asset: Asset | undefined) => {
    if (!asset?.nextCheckup?.checkup) return;
    const checkup = await fetchCheckup(asset?.nextCheckup?.checkup.id ?? "");
    setAppointmentForm({ ...form, checkup, asset });
  };

  const chooseDriver = (driverId: string, drivers: Driver[]) => {
    const driver = drivers.find((driver) => driver.id === driverId);
    setAppointmentForm({ ...form, driver });
  };

  const formIsValid = (): boolean => {
    return !!(
      form?.asset &&
      form?.asset.hourmeter &&
      form?.asset.odometer &&
      form?.driver &&
      form?.driver.email &&
      form?.driver.license &&
      form?.driver.name &&
      form?.driver.phone &&
      form?.checkup &&
      form?.schedulingTypes &&
      form?.schedulingTypes.length > 0
    );
  };

  const checkIfUserIsDriver = (): boolean => {
    const driverId = userProfile?.sub?.split(":")[1];
    return drivers.some((driver) => driver.id === driverId);
  };

  const aditionalServices = [
    {
      id: "fluid-analysis",
      name: "Análise de fluído (Predic)",
      price: 50,
    },
    {
      id: "filter-cleaning",
      name: "Limpeza de filtro (Limpar Auto)",
      price: 10,
    },
    {
      id: "break-cleaning",
      name: "Limpeza de freios (Limpar Auto)",
      price: 60,
    },
    {
      id: "oxi-sanitization",
      name: "Oxisanitização (Motran)",
      price: 10,
    },
  ];

  return (
    <>
      <div className="scheduling-page p-10 h-[70vh] overflow-auto">
        <div className="scheduling-page__header">
          <h2 className="text-size-h2 text-color-black font-semibold text-uppercase">
            1. Veículos e motoristas
          </h2>
          <p className="text-color-status-resting text-size-16 padding-top-10">
            Preencha abaixo os dados dos veículos e dos motoristas
          </p>
        </div>

        <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
          <h6 className="text-bold text-size-16">Informações do veículo</h6>
          {form?.asset && (
            <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
              <div className="flex items-center gap-4">
                <img
                  src="/assets/images/vehicle-thumb.png"
                  alt="Vehicle"
                  className="w-20 h-20 rounded"
                />

                <div className="flex-1 padding-left-10">
                  <p className="text-color-black text-size-16 font-medium m-0">
                    {form?.asset.name}
                  </p>
                  <p className="text-color-status-resting text-size-14 m-0">
                    Chassi - {form?.asset.chassis}
                  </p>
                </div>

                {(form?.asset?.plans?.length ?? 0) > 0 && (
                  <div className="flex gap-6 margin-bottom-20">
                    <span className="px-2 py-1 green-tag">RIO</span>
                    <span className="px-2 py-1 green-tag">Em Garantia</span>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="flex justify-between w-full gap-6 mt-5">
            <div className="form-group form-group-lg w-1/3">
              <label htmlFor="placa">Placa do veículo</label>
              <input
                id="placa"
                className="form-control"
                type="text"
                value={form?.asset?.licensePlate}
                disabled
              />
            </div>

            <div className="form-group form-group-lg w-1/3">
              <label htmlFor="model">Modelo do veículo</label>
              <div className="input-group">
                <input
                  id="model"
                  className="form-control"
                  type="text"
                  value={form?.asset?.model}
                  disabled
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/3">
              <label htmlFor="year">Ano do veículo</label>
              <div className="input-group">
                <input
                  id="year"
                  className="form-control"
                  type="text"
                  value={form?.asset?.year}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="hourmeter">Horímetro*</label>
              <div className="input-group">
                <input
                  id="hourmeter"
                  className="form-control no-controls"
                  type="number"
                  value={form?.asset?.hourmeter}
                  onChange={(e) =>
                    setAppointmentForm({
                      ...form,
                      asset: form?.asset
                        ? {
                            ...form?.asset,
                            hourmeter: Number(e.target.value),
                          }
                        : undefined,
                    })
                  }
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="odometer">Odômetro*</label>
              <div className="input-group">
                <input
                  id="odometer"
                  className="form-control"
                  type="text"
                  value={form?.asset?.odometer}
                  onChange={(e) =>
                    setAppointmentForm({
                      ...form,
                      asset: form?.asset
                        ? {
                            ...form?.asset,
                            odometer: Number(e.target.value),
                          }
                        : undefined,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex flex-column">
            {checkIfUserIsDriver() && (
              <Checkbox
                id="checkbox-motorista"
                checked={form?.scheduleByDriver}
                onChange={() => {
                  const driverId = userProfile?.sub?.split(":")[1];
                  const driver = drivers.find(
                    (driver) => driver.id === driverId
                  );
                  setAppointmentForm({
                    ...form,
                    scheduleByDriver: !form?.scheduleByDriver,
                    driver: !form?.scheduleByDriver ? driver : form?.driver,
                  });
                }}
              >
                Eu sou o motorista
              </Checkbox>
            )}
            <div className="flex justify-between w-full gap-6 mt-5">
              <div className="form-group form-group-lg w-1/2">
                <label htmlFor="motorista">Nome do motorista*</label>
                <Select
                  placeholder="Informe o nome do motorista"
                  options={drivers.map((driver: Driver) => ({
                    id: driver.id,
                    label: driver.name,
                  }))}
                  value={form?.driver ? [form?.driver.id] : []}
                  useFilter
                  noItemMessage="Nenhum motorista encontrado"
                  onChange={(e) => chooseDriver(e?.id ?? "", drivers)}
                  inputAddon="rioglyph rioglyph-search"
                  disabled={form?.scheduleByDriver}
                />
              </div>

              <div className="form-group form-group-lg w-1/2">
                <label htmlFor="cnh">CNH do motorista*</label>
                <div className="input-group">
                  <input
                    id="cnh"
                    className="form-control"
                    type="text"
                    placeholder="CNH do motorista"
                    maxLength={9}
                    value={form?.driver?.license}
                    onChange={(e) =>
                      setAppointmentForm({
                        ...form,
                        driver: form?.driver
                          ? {
                              ...form?.driver,
                              license: e.target.value,
                            }
                          : undefined,
                      })
                    }
                    disabled={form?.scheduleByDriver}
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-between w-full gap-6">
              <div className="form-group form-group-lg w-1/2">
                <label htmlFor="emailMotorista">E-mail do motorista*</label>
                <div className="input-group">
                  <span className="input-group-addon">
                    <span className="rioglyph rioglyph-envelope text-color-black"></span>
                  </span>
                  <input
                    id="emailMotorista"
                    className="form-control"
                    type="text"
                    placeholder="E-mail do motorista"
                    value={form?.driver?.email}
                    onChange={(e) =>
                      setAppointmentForm({
                        ...form,
                        driver: form?.driver
                          ? {
                              ...form?.driver,
                              email: e.target.value,
                            }
                          : undefined,
                      })
                    }
                    disabled={form?.scheduleByDriver}
                  />
                </div>
              </div>

              <div className="form-group form-group-lg w-1/2">
                <label htmlFor="telefoneMotorista">
                  Telefone do motorista*
                </label>
                <div className="input-group">
                  <span className="input-group-addon">
                    <span className="rioglyph rioglyph-earphone text-color-black"></span>
                  </span>
                  <input
                    id="telefoneMotorista"
                    className="form-control"
                    type="text"
                    placeholder="Telefone do motorista"
                    value={form?.driver?.phone}
                    onChange={(e) =>
                      setAppointmentForm({
                        ...form,
                        driver: form?.driver
                          ? {
                              ...form?.driver,
                              phone: e.target.value,
                            }
                          : undefined,
                      })
                    }
                    disabled={form?.scheduleByDriver}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
          <h6 className="text-bold text-size-16">
            Revisões com base no intervalo de km e/ou tempo do veículo e ações
            de oficina (se houver)
          </h6>
          <div className="info-group">
            <p className="text-color-status-resting text-size-16 padding-top-10 padding-bottom-5">
              Revisões disponíveis*
            </p>

            <div className="display-flex gap-10">
              {[
                { id: "preventive", label: "Preventiva" },
                { id: "workshopActions", label: "Campanhas de Campo" },
              ].map((option) => (
                <div className="avalilable-revisions border rounded">
                  <Checkbox
                    onChange={() =>
                      setAppointmentForm({
                        ...form,
                        schedulingTypes: form?.schedulingTypes?.includes(
                          option.id
                        )
                          ? form?.schedulingTypes.filter(
                              (type) => type !== option.id
                            )
                          : [...(form?.schedulingTypes ?? []), option.id],
                      })
                    }
                    checked={form?.schedulingTypes?.includes(option.id)}
                    custom
                    className="padding-y-5 padding-x-10"
                  >
                    <div className="checkbox-text-wrapper display-flex gap-10">
                      <div className="checkbox-text" />
                      <div className="flex-1-1">{option.label}</div>
                    </div>
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>

          {form?.asset && form?.schedulingTypes?.includes("preventive") && (
            <div
              style={{
                backgroundColor: "#ECFDF3",
                color: "#067647",
                borderColor: "#ABEFC6",
              }}
              className="border rounded padding-15 margin-top-20"
            >
              <div className="text-bold">
                Revisão {form?.checkup?.description} ({form?.checkup?.odometer}{" "}
                km)
              </div>
              {(form?.checkup?.services ?? []).map(
                (service: CheckupService) => (
                  <div className="padding-left-10 padding-top-10">
                    {service.name}
                  </div>
                )
              )}
            </div>
          )}
        </div>

        <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
          <div className="flex justify-between">
            <h6 className="text-bold text-size-16">Contratação de serviços</h6>
            <div className="flex items-center padding-left-10 padding-right-10 total-value">
              <span className="font--bold text-size-14 font-bold total-value__label">
                Total
              </span>
              <span className="font-bold text-size-14 padding-left-5 padding-right-5 margin-left-10 total-value__value">
                {(form?.aditionalServices ?? [])
                  .reduce((acc, service) => acc + service.price, 0)
                  .toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
              </span>
            </div>
          </div>

          <div className="info-group">
            <p className="text-color-status-resting text-size-16 padding-top-10 padding-bottom-5">
              Adicionar outros serviços
            </p>

            <div className="add-services">
              <Multiselect
                placeholder="Selecionar serviços"
                options={aditionalServices.map((service) => ({
                  id: service.id,
                  label: service.name,
                  price: service.price,
                }))}
                className="select-icon"
                onChange={(response: any) => {
                  setAppointmentForm({
                    ...form,
                    aditionalServices: aditionalServices.filter(
                      (aditionalService) =>
                        response.includes(aditionalService.id)
                    ),
                  });
                }}
                value={form?.aditionalServices?.map((service) => service.id)}
                noItemMessage="Nenhum serviço adicional encontrado para essa revisão"
              />
              <div className="services-cost">
                <span className="font-bold text-size-14 padding-left-5 padding-right-5 margin-left-10 total-value__value">
                  {(form?.aditionalServices ?? [])
                    .reduce((acc, service) => acc + service.price, 0)
                    .toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                </span>
              </div>
              <div className="show-comments margin-top-15 flex justify-end show-comments">
                <Switch
                  onChange={() => {
                    setShowComments(!showComments);
                    setAppointmentForm({
                      ...form,
                      comments: null,
                    });
                  }}
                  checked={showComments || !!form?.comments}
                  labelPosition="right"
                  color="secondary"
                >
                  <span className="text-size-14 text-color-status-resting">
                    comentário
                  </span>
                </Switch>
              </div>
              {(showComments || !!form?.comments) && (
                <div className="text-comments margin-top-20">
                  <textarea
                    className="form-control"
                    placeholder="Comentário..."
                    value={form?.comments ?? undefined}
                    onChange={(e) =>
                      setAppointmentForm({
                        ...form,
                        comments: e.target.value,
                      })
                    }
                  />
                </div>
              )}
            </div>
          </div>

          {/* <div className="info-group">
                            <p className="text-color-status-resting text-size-16 padding-top-10 padding-bottom-5">
                            Cesta de peças
                            </p>

                            <div className="add-services cart-row">
                            <Multiselect
                                placeholder="Compre peças para este agendamento"
                                options={services.map((service) => ({
                                id: service.id,
                                label: service.label,
                                value: service.value,
                                }))}
                                className="select-icon"
                                onChange={(response: any) =>
                                Notification.info(JSON.stringify(response))
                                }
                            />
                            <div className="services-cost">
                                <span className="font-bold text-size-14 padding-left-5 padding-right-5 margin-left-10 total-value__value">
                                R$ 0,00
                                </span>
                            </div>
                            </div>
                        </div> */}
        </div>

        <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
          <div className="flex justify-between">
            <h6 className="text-bold text-size-16">Observações gerais</h6>
          </div>

          <div className="info-group">
            <div className="add-services">
              <div className="text-comments margin-top-10">
                <textarea
                  className="form-control"
                  placeholder="Detalhe o que está acontecendo com o veículo..."
                  value={form?.obs}
                  onChange={(e) =>
                    setAppointmentForm({
                      ...form,
                      obs: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-info flex justify-between padding-15">
        <span className="text-color-status-resting font-size-14">
          Passo <strong className="text-color-danger">1</strong> de 4
        </span>
        <span className="text-color-status-resting font-size-14">
          Informações do veículo e serviços
        </span>
      </div>
      <div className="flex justify-between navigation-buttons step-1">
        <button
          disabled
          className="px-4 py-2 rounded-lg font-semibold button-color cursor-not-allowed"
        >
          <span className="rioglyph rioglyph-arrow-left text-size-16"></span>{" "}
          Voltar
        </button>
        <button
          disabled={!formIsValid()}
          onClick={() => setStep(2)}
          className="px-4 py-2 btn btn-primary"
        >
          Próxima etapa
        </button>
      </div>
    </>
  );
};

export default AssetsAndDriversForm;
