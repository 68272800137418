import Page from "../Page";
import Submenu from "./components/submenu";

const Assessment = () => {
    return (
        <Page>
            <>
                <Submenu />
                <h3>Avaliação</h3>
            </>
        </Page>
    );
}

export default Assessment;