import { IAppointmentForm } from "../../pages/fleetManager/newAppointment";
import ApiService from "../../services/api";

export const makeAnAppointment = async (appointmentData: IAppointmentForm, userProfile: any): Promise<boolean> => {
    const apiService = new ApiService();
    
    const scheduleDate = new Date(appointmentData.dateAndConsultant?.scheduleDate?.getTime() ?? 0);
    scheduleDate?.setHours(scheduleDate.getHours() - 3);

    try {
        const response = await apiService.post<any>(
            `/schedules/checkups`,
            {
                "consultantId": appointmentData.dateAndConsultant?.consultant?.id ?? 'ec6e5df5-b523-4428-bacd-48d04b90a234',
                "checkupId": appointmentData.assetsAndDrivers?.checkup?.id,
                "schedule": {
                    "booking": "string",
                    "scheduledDate": scheduleDate,
                    "sourceAccountId": userProfile?.account,
                    "sourceUserId": userProfile?.sub?.split(':')[1],
                    // TODO: Change this to the actual destination account
                    "destinationAccountId": "33", 
                    "destinationUserId": "44"
                },
                "vehicleSchedule": {
                    "vehicle": {
                        "assetId": appointmentData.assetsAndDrivers?.asset?.id,
                        "chassis": appointmentData.assetsAndDrivers?.asset?.chassis,
                        "plate": appointmentData.assetsAndDrivers?.asset?.licensePlate ?? '-',
                        "name": appointmentData.assetsAndDrivers?.asset?.name,
                        "vehicleModel": appointmentData.assetsAndDrivers?.asset?.model ?? '-',
                        "vehicleYear": appointmentData.assetsAndDrivers?.asset?.year,
                        "hourMeter": appointmentData.assetsAndDrivers?.asset?.odometer,
                        "odometer": appointmentData.assetsAndDrivers?.asset?.odometer,
                        "vehicleGroup": appointmentData.assetsAndDrivers?.asset?.groupName,
                    },
                    "driver": {
                        "name": appointmentData.assetsAndDrivers?.driver?.name,
                        "cnh": appointmentData.assetsAndDrivers?.driver?.license,
                        "email": appointmentData.assetsAndDrivers?.driver?.email,
                        "phone": appointmentData.assetsAndDrivers?.driver?.phone,
                    },
                    "dealerShip": {
                        "id": appointmentData.dealership?.id,
                        "name": appointmentData.dealership?.name,
                    },
                    "dealershipId": appointmentData.dealership?.id,
                    "autoWorkshopActivities": {
                        "id": "103",
                        "name": "Reparo dos cintos"
                    },
                    "otherServices": (appointmentData.assetsAndDrivers?.aditionalServices ?? [])
                        .map(service => ({
                            id: service.id,
                            name: service.name,
                            price: service.price
                        })),
                    "comments": appointmentData.assetsAndDrivers?.comments ?? '',
                    "generalObservations": appointmentData.assetsAndDrivers?.obs ?? '',
                }
            }
        );
        if (response.status < 400) {
            return true;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export const updateAnAppointment = async (appointmentData: IAppointmentForm, userProfile: any, schedulingId: string): Promise<boolean> => {
    const apiService = new ApiService();
    
    const scheduleDate = appointmentData.dateAndConsultant?.scheduleDate;
    scheduleDate?.setHours(scheduleDate.getHours() - 3);

    try {
        const response = await apiService.put<any>(
            `/schedules/checkups/${schedulingId}`,
            {
                "consultantId": appointmentData.dateAndConsultant?.consultant?.id ?? 'ec6e5df5-b523-4428-bacd-48d04b90a234',
                "checkupId": appointmentData.assetsAndDrivers?.checkup?.id,
                "schedule": {
                    "booking": "string",
                    "scheduledDate": scheduleDate,
                    "sourceAccountId": userProfile?.account,
                    "sourceUserId": userProfile?.sub?.split(':')[1],
                    // TODO: Change this to the actual destination account
                    "destinationAccountId": "33", 
                    "destinationUserId": "44"
                },
                "vehicleSchedule": {
                    "vehicle": {
                        "assetId": appointmentData.assetsAndDrivers?.asset?.id,
                        "chassis": appointmentData.assetsAndDrivers?.asset?.chassis,
                        "plate": appointmentData.assetsAndDrivers?.asset?.licensePlate,
                        "name": appointmentData.assetsAndDrivers?.asset?.name,
                        "vehicleModel": appointmentData.assetsAndDrivers?.asset?.model,
                        "vehicleYear": appointmentData.assetsAndDrivers?.asset?.year,
                        "hourMeter": appointmentData.assetsAndDrivers?.asset?.odometer,
                        "odometer": appointmentData.assetsAndDrivers?.asset?.odometer,
                        "vehicleGroup": appointmentData.assetsAndDrivers?.asset?.groupName,
                    },
                    "driver": {
                        "name": appointmentData.assetsAndDrivers?.driver?.name,
                        "cnh": appointmentData.assetsAndDrivers?.driver?.license,
                        "email": appointmentData.assetsAndDrivers?.driver?.email,
                        "phone": appointmentData.assetsAndDrivers?.driver?.phone,
                    },
                    "dealerShip": {
                        "id": appointmentData.dealership?.id,
                        "name": appointmentData.dealership?.name,
                    },
                    "dealershipId": appointmentData.dealership?.id,
                    "autoWorkshopActivities": {
                        "id": "103",
                        "name": "Reparo dos cintos"
                    },
                    "otherServices": (appointmentData.assetsAndDrivers?.checkup?.aditionalServices ?? [])
                        .map(service => ({
                            id: service.id,
                            name: service.name,
                            price: service.price
                        })),
                    "comments": appointmentData.assetsAndDrivers?.comments ?? '',
                    "generalObservations": appointmentData.assetsAndDrivers?.obs ?? '',
                }
            }
        );
        if (response.status < 400) {
            return true;
        } else return false;
    } catch (error) {
        console.error(error);
        return false;
    }
}