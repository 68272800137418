import { Dialog } from "@rio-cloud/rio-uikit";
import { IAppointmentForm } from "..";
import { useEffect, useState } from "react";
import { fetchUser, IUserProfile } from "../../../../api/fetchUser";
import { useSelector } from "react-redux";
import { getUserProfile } from "../../../../configuration/login/loginSlice";

enum ExternalCalendar {
    Outlook = 'Outlook',
    Google = 'Google',
}

interface ISuccessDialogProps {
    open: boolean;
    appointmentForm: IAppointmentForm | undefined,
    routeWhenClose?: string;
}

const SuccessDialog = ({ open, appointmentForm, routeWhenClose }: ISuccessDialogProps) => {
    const userProfile = useSelector(getUserProfile);
    const [scheduleDate, setScheduleDate] = useState<Date>();
    const [user, setUser] = useState<IUserProfile>();

    useEffect(() => {
        const fetchData = async () => {
            const userId = userProfile?.sub?.split(':')[1];
            if (userId) {
                const user = await fetchUser(userId);
                setUser(user);
            }
        }

        if(appointmentForm?.dateAndConsultant?.scheduleDate)
            setScheduleDate(new Date(appointmentForm?.dateAndConsultant?.scheduleDate?.getTime()));

        fetchData();
    }, [open]);

    const setAppointmentToExternalCalendar = (externalCalendar: ExternalCalendar) => {
        if (scheduleDate) {
            const scheduleStartDate = scheduleDate;
            const scheduleEndDate = new Date(scheduleDate.getTime() + 60 * 60 * 1000);

            const title = `Agendamento de Manutenção - ${appointmentForm?.assetsAndDrivers?.checkup?.name} - Chassi: ${appointmentForm?.assetsAndDrivers?.asset?.chassis}`;
            const location = `Concessionária: ${appointmentForm?.dealership?.name}`;
            const description = `<html><strong>Veículo:</strong> ${appointmentForm?.assetsAndDrivers?.asset?.name} <br /><br /> <strong>Chassi:</strong> ${appointmentForm?.assetsAndDrivers?.asset?.chassis} <br /><br /> <strong>Motorista:</strong> ${appointmentForm?.assetsAndDrivers?.driver?.name}</html>`;
            
            let startDate = '';
            let endDate = '';
            let url = '';
            
            switch (externalCalendar) {
                case ExternalCalendar.Outlook:
                    startDate = new Date(scheduleStartDate.getTime() - 3 * 60 * 60 * 1000).toISOString().split('.')[0];
                    endDate = new Date(scheduleEndDate.getTime() - 3 * 60 * 60 * 1000).toISOString().split('.')[0];
                    url = `https://outlook.office.com/calendar/0/deeplink/compose?subject=${title}&location=${location}&startdt=${startDate}&enddt=${endDate}&body=${description}`;
                    break;

                case ExternalCalendar.Google:
                    startDate = scheduleStartDate.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
                    endDate = scheduleEndDate.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
                    url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&location=${location}&dates=${startDate}/${endDate}&details=${description}`;
                    break;
            }

            window.open(url, '_blank');
        }
    }

    return (
        <Dialog
            show={open}
            title="Agendamento realizado"
            onClose={() => window.location.href = routeWhenClose ?? '/fleet-manager'}
            bsSize="sm"
            body={
                <div>
                    <div
                        style={{ backgroundColor: '#ECFDF3', borderColor: '#ABEFC6' }}
                        className="border rounded padding-15"
                    >

                        <div className="flex">
                            <div className="text-center w-14">
                                <span className="rioglyph rioglyph-ok bg-success text-color-white rounded-circle padding-2" />
                            </div>
                            <div className="flex-grow">
                                <div className="font-bold">
                                    Agendamento realizado com sucesso!
                                </div>
                                <div>
                                    O gestor da frota desse veículo também foi notificado.
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="flex items-start gap-4">
                        <img
                            src="/assets/images/vehicle-thumb.png"
                            alt="Vehicle"
                            className="w-20 h-20 rounded"
                        />

                        <div className="flex-1 padding-left-10">
                            <p className="text-color-black text-bold text-size-16 m-0">
                                {appointmentForm?.assetsAndDrivers?.asset?.name}
                            </p>
                            <p className="text-color-dark text-size-16 m-0">
                                Chassi - {appointmentForm?.assetsAndDrivers?.asset?.chassis}
                            </p>
                        </div>
                    </div>

                    <hr />

                    <div className="flex items-start gap-4">
                        <div className="padding-right-25 border border-right-only">
                            <div className="text-size-16 text-bold">Solicitante</div>
                            <div className="text-size-16 text-color-dark" style={{ lineHeight: '34px' }}>{user?.name ?? '-'}</div>
                        </div>

                        <div className="padding-left-10 flex-grow border border-right-only">
                            <div className="text-size-16 text-bold">Data</div>
                            <div className="flex" style={{ lineHeight: '34px' }}>
                                <span className="rioglyph rioglyph-calendar" /> {scheduleDate?.toLocaleDateString('pt-BR', { day: '2-digit', month: 'long' })}
                            </div>
                        </div>

                        <div className="width-100 padding-left-15">
                            <div className="text-size-16 text-bold">Hora</div>
                            <div className="text-size-16" style={{ lineHeight: '34px' }}>{scheduleDate?.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}h</div>
                        </div>
                    </div>

                    <hr />

                    <div className="text-color-dark text-decoration-underline flex">
                        <div 
                            className='display-flex align-items-center margin-right-10 cursor-pointer'
                            onClick={() => setAppointmentToExternalCalendar(ExternalCalendar.Outlook)}
                        >
                            <img src="/assets/images/outlook-icon.svg" alt="Outlook icon" />
                        </div>

                        <div 
                            className='display-flex align-items-center margin-right-10 cursor-pointer'
                            onClick={() => setAppointmentToExternalCalendar(ExternalCalendar.Google)}
                        >
                            <img src="/assets/images/google-icon.svg" alt="Google icon" />
                        </div>

                        <div style={{ lineHeight: '34px' }}>
                            Adicionar a minha agenda pessoal
                        </div>
                    </div>
                </div>
            }
            footer={
                <button type="button" className='btn btn-primary btn-link' onClick={() => window.location.href = routeWhenClose ?? '/fleet-manager'}>
                    Fechar
                </button>
            }
        />
    );
};

export default SuccessDialog;