import { Scheduling } from "../../../../models/dealership/schedule";
// import Checkbox from '@rio-cloud/rio-uikit/Checkbox';

interface IAppointmentProps {
  appointment: Scheduling;
  goBack: () => void;
}

const Appointment = ({ appointment, goBack }: IAppointmentProps) => {
  if (!appointment) return;

  return (
    <div className="scheduling-page resume-section p-10">
      <h2 className="text-size-h2 text-center text-color-black font-semibold padding-top-20 padding-bottom-20">
        Resumo do agendamento
      </h2>
      <div className="resume-container">
        <h5 className="text-size-20 text-color-black font-bold margin-bottom-20">
          AGD 001574
        </h5>

        <div className="row schedule-data">
          <div className="col-4">
            <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
              Motorista
            </label>
            <div className="mt-1 p-2 border schedule-data__info">
              {appointment.driver.name}
            </div>
          </div>
          <div className="col-2">
            <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
              CNH
            </label>
            <div className="mt-1 p-2 border schedule-data__info">
              {appointment.driver.license}
            </div>
          </div>
          <div className="col-3">
            <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
              Telefone
            </label>
            <div className="mt-1 p-2 border schedule-data__info margin-bottom-10">
              {appointment.driver.phone}
            </div>
          </div>
          <div className="col-3">
            <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
              E-mail
            </label>
            <div className="mt-1 p-2 border schedule-data__info">
              {appointment.driver.email}
            </div>
          </div>
        </div>

        <div className="p-4 bg-lighter margin-top-20 vehicle-detais">
          <div className="flex items-start gap-4">
            <img
              src="/assets/images/vehicle-thumb.png"
              alt="Vehicle"
              className="w-20 h-20 rounded"
            />

            <div className="flex-1 padding-left-10">
              <p className="text-color-black text-size-16 font-medium m-0">
                {appointment.asset.name} | Placa:{" "}
                {appointment.asset.licensePlate}
              </p>
              <p className="text-color-status-resting text-size-14 m-0">
                Chassi - {appointment.asset.chassis}
                <br />
                Odômetro: {appointment.asset.odometer} km | Horímetro:{" "}
                {appointment.asset.hourmeter}
              </p>
            </div>

            {(appointment.asset.plans?.length ?? 0) > 0 && (
              <div className="flex gap-6 margin-bottom-20">
                <span className="px-2 py-1 green-tag">RIO</span>
                <span className="px-2 py-1 green-tag">Em Garantia</span>
                {appointment.asset.volksTotalPlan && (
                  <span className="px-2 py-1 bg-white white-tag">
                    Volkstotal {appointment.asset.volksTotalPlan}
                  </span>
                )}
              </div>
            )}
          </div>

          <div className="padding-top-10">
            <div className="car-workshop">
              <ul className="flex flex-column gap-5 m-0">
                <li className="flex justify-between">
                  <div className="text-color-black">Campanhas de campo</div>
                  <div className="text-color-black font-bold">Grátis</div>
                </li>

                <li className="flex justify-between">
                  <div className="text-color-black">
                    Revisão {appointment.asset.nextCheckup?.checkup.description}{" "}
                    ({appointment.asset.nextCheckup?.checkup.odometer} km)
                  </div>
                  <div className="text-color-black font-bold">Grátis</div>
                </li>

                {/* <li className="flex justify-between">
                                    <div className="text-color-black">
                                        Total em peças
                                    </div>
                                    <div className="text-color-black">R$4.628,00</div>
                                </li> */}

                <li className="flex justify-between">
                  <div className="text-color-black">Total em serviços</div>
                  <div className="text-color-black">
                    {(
                      appointment.asset.nextCheckup?.checkup
                        .aditionalServices ?? []
                    )
                      .reduce((acc, service) => acc + service.price, 0)
                      .toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="revision-itens">
          <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
            Itens cobertos na revisão
          </h5>
          <div className="p-4 bg-white border">
            <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
              {appointment.services.map((item, index) => (
                <div key={index} className="padding-bottom-10">
                  <p className="text-bold">{item.title}</p>
                  {item.items.map((subItem, subIndex) => (
                    <p key={subIndex} className="padding-left-20">
                      {subItem}
                    </p>
                  ))}
                </div>
              ))}
            </ul>
          </div>
        </div>

        <div className="revision-itens">
          <h5 className="text-size-20 text-bold margin-bottom-0">
            Descrição dos Serviços Contratados
          </h5>
          <div className="text-size-14 margin-bottom-10">
            Espaço para descrever informações importantes sobre “Serviços
            Contratados”, adicionados no agendamento.
          </div>
          <div className="p-4 bg-white border">
            <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
              {(
                appointment.asset.nextCheckup?.checkup.aditionalServices ?? []
              ).map((item, index) => (
                <div key={index}>
                  <p>{item.name}</p>
                </div>
              ))}
            </ul>
          </div>
        </div>

        <div className="revision-itens observation">
          <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
            Observações
          </h5>
          <div className="p-4 bg-white border">
            <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
              <li className="flex justify-between">
                <span>{appointment.observation}</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="revision-itens flex justify-between gap-6">
          <div className="w-1/3">
            <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
              Concessionária
            </h5>
            <div className="p-4 bg-white border card">
              <p className="m-0 text-size-h5">{appointment.dealership.name}</p>
              <p className="m-0">{appointment.dealership.address}</p>
            </div>
          </div>

          <div className="w-1/3">
            <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
              Data e horário:
            </h5>
            <div className="p-4 bg-white border card">
              <p className="m-0 text-size-h5">
                {appointment.scheduleTime.toLocaleDateString("pt-BR", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                })}
              </p>
              <p className="m-0">
                {appointment.scheduleTime.toLocaleTimeString("pt-BR", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </p>
            </div>
          </div>

          <div className="w-1/3">
            <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
              Consultor:
            </h5>
            <div className="p-4 bg-white border card">
              <p className="m-0 text-size-h5">{appointment.consultant}</p>
            </div>
          </div>
        </div>

        <div className="warning-row">
          <h5 className="text-color-status-resting">Atenção!</h5>
          <p className="text-color-warning text-size-18 font-semibold">
            Os itens de cobertura do seu plano de contrato de manutenção estão
            sujeitas a análise técnica de garantia, podendo a garantia ser
            indeferida por violação e ou mau uso.
          </p>
          {/* <p className="margin-top-20">
                        <Checkbox 
                            indeterminate
                            checked={true}
                            disabled
                        >
                            Estou ciente sobre as regras da garantia
                        </Checkbox>
                    </p> */}
        </div>
        <div className="flex justify-between navigation-buttons step-4">
          <button className="back-btn" onClick={goBack}>
            Voltar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
