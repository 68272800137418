import { useEffect, useState } from "react";
import { ClearableInput, Select } from "@rio-cloud/rio-uikit";

import { Scheduling } from "../../../../models/dealership/schedule";
import AppointmentCard from "./appointmentCard";

import '../styles.scss';
import { fetchAllConsultantSchedule } from "../../../../api/dealership/fetchConsultantSchedule";
import { Loading } from "../../../../utils/loading";
import Appointment from "./appointment";

const Appointmens = () => {
    const [sortType, setSortType] = useState<string | undefined>('desc');
    const [appointmens, setAppointmens] = useState<Scheduling[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [scheduling, setScheduling] = useState<Scheduling | null>(null);

    useEffect(() => {
        const fetchAppointmens = async () => {
            setLoading(true);
            const appointments = await fetchAllConsultantSchedule();
            setAppointmens(appointments);
            setLoading(false);
        }

        fetchAppointmens();
    }, []);

    const fetchAppointmens = async () => {
        setLoading(true);
        const appointments = await fetchAllConsultantSchedule();
        setAppointmens(appointments);
        setLoading(false);
    }

    return (
        loading ?
            <Loading label="Carregando agendamentos..." /> :
            scheduling ?
                <Appointment appointment={scheduling} goBack={() => setScheduling(null)} /> :
                <>
                    <h1>Meus agendamentos</h1>
                    <div className="flex gap-6 margin-top-15 search-filter">
                        <div className="form-group search-group">
                            <div className="input-group">
                                <span className="input-group-addon">
                                    <span className="rioglyph rioglyph-search" aria-hidden="true" />
                                </span>
                                <ClearableInput
                                    id="search-button"
                                    placeholder="Busque"
                                    onChange={(value: string) => console.log(value)}
                                />
                            </div>
                        </div>

                        <div className="form-group select-group">
                            <Select
                                id="sort-type"
                                options={[
                                    { id: "desc", label: <span>Decrescente</span>, selected: true },
                                    { id: "asc", label: <span>Crescente</span>, selected: false },
                                ]}
                                onChange={(selected) => setSortType(selected?.id)}
                            />
                        </div>
                    </div>

                    {
                        appointmens.length === 0 && (
                            <div className="flex justify-center padding-top-10">
                                <span className="text-color-gray">
                                    Não existem agendamentos realizados.
                                </span>
                            </div>
                        )
                    }

                    {
                        appointmens
                            .sort((a, b) => (
                                sortType === 'asc'
                                    ? a.scheduleTime.getTime() - b.scheduleTime.getTime()
                                    : b.scheduleTime.getTime() - a.scheduleTime.getTime()
                            ))
                            .map((appointment) => (
                                <AppointmentCard
                                    key={appointment.asset.id}
                                    appointment={appointment}
                                    chooseScheduling={(schedulingChoosed) => setScheduling(schedulingChoosed)}
                                    fetchAppointmens={fetchAppointmens}
                                />
                            ))
                    }
                </>
    )
}

export default Appointmens;