import { useEffect, useState } from "react";
import AppointmentForm from "./components/appointmentForm";
import AppointmentSummary from "./components/appointmentSummary";
import { Asset } from "../../../models/fleetManager/asset";
import { Driver } from "../../../models/fleetManager/driver";
import { IAppointmentForm } from "../../fleetManager/newAppointment";
// import { fetchMaintenance } from "../../../../api/fleetManager/fetchMaintenance";
// import { fetchDrivers } from "../../../../api/fleetManager/fetchDrivers";
import { Loading } from "../../../utils/loading";
import { assetMockData, dealershipMockData, driverMockData } from "./mock-data";
import { AssetDto } from "../../../dtos/fleetManager/assetDto";
import { driverDto } from "../../../dtos/fleetManager/driverDto";
import { useSelector } from "react-redux";
import { getUserProfile } from "../../../configuration/login/loginSlice";

const NewAppointment = () => {
	const userProfile = useSelector(getUserProfile);
	
	const [step, setStep] = useState(0);
	const [loading, setLoading] = useState(true);

	const [appointment, setAppointment] = useState<IAppointmentForm | undefined>();

	const [assets, setAssets] = useState<Asset[]>([]);
	const [drivers, setDrivers] = useState<Driver[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);

			// const [
			// 	[assets,],
			// 	drivers
			// ] = await Promise.all([fetchMaintenance(), fetchDrivers()]);

			setAssets(assetMockData.map(AssetDto));
			setDrivers(driverMockData.map(driverDto));

			const dealership = dealershipMockData.find(d => d.consultants.includes(userProfile?.sub?.split(':')[1] || ''));
			setAppointment({
				...appointment,
				dealership: {
					id: dealership?.id || '',
					name: dealership?.name || '',
					address: dealership?.address || '',
					geoLocation: { lat: 0, lng: 0 }
				},
				dateAndConsultant: {
					consultant: {
						id: userProfile?.sub?.split(':')[1] || '',
						name: userProfile?.name || ''
					}
				}
			})

			console.log(appointment);

			setLoading(false);
		}

		fetchData()
	}, []);

	if (loading) return <Loading label="Carregando dados..." />;

	return step === 0 ?
		<AppointmentForm
			goToSummary={() => setStep(1)}
			form={appointment}
			setForm={setAppointment}
			drivers={drivers}
			assets={assets}
		/>
		:
		<AppointmentSummary
			goBackToForm={() => setStep(0)}
			appointment={appointment}
		/>
};

export default NewAppointment;
