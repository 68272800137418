import StatsWidget from "@rio-cloud/rio-uikit/StatsWidget";
import StatsWidgetNumber from "@rio-cloud/rio-uikit/StatsWidgetNumber";

interface IOccupancyRateProps {
    occupancyRate: number;
}

const OccupancyRate = ({ occupancyRate }: IOccupancyRateProps) => {
    return (
        <StatsWidget>
            <StatsWidget.Header>Taxa de ocupação</StatsWidget.Header>
            <StatsWidget.Body>
                <StatsWidgetNumber
                    className="text-color-status-driving"
                    value={`${occupancyRate}%`}
                    label="Agenda ocupada"
                />
            </StatsWidget.Body>
        </StatsWidget>
    )
}

export default OccupancyRate;