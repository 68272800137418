import { Asset } from "../../models/fleetManager/asset";

export interface IAssetFilter {
    maintenanceFilters?: IMaintenanceFilter, 
    hasRioPlanFilter?: boolean,
    planId?: string, 
    hasVolksTotalPlanFilter?: boolean,
    volksTotalPlan?: string
}

export interface IMaintenanceFilter {
    toSchedule?: boolean;
    overdue?: boolean;
    scheduled?: boolean;
    inProgress?: boolean;
}

export const maintenanceFilter = (asset: Asset, filter: IMaintenanceFilter): boolean => {
    let filterResult = true;

    const checkupScheduleInfo = asset.nextCheckups[0];

    if (filter.toSchedule) 
        filterResult = filterResult && (checkupScheduleInfo?.date == null);
    
    if (filter.overdue) 
        filterResult = filterResult && ((checkupScheduleInfo?.date != null) && (checkupScheduleInfo?.date < new Date()) && checkupScheduleInfo?.status !== "Em manutenção");
        
    if (filter.scheduled)
        filterResult = filterResult && ((checkupScheduleInfo?.date != null) && checkupScheduleInfo?.status !== "Em manutenção");
        
    if (filter.inProgress)
        filterResult = filterResult && (checkupScheduleInfo?.status === "Em manutenção");
    
    return filterResult;
}

export const hasRioPlan = (asset: Asset): boolean => asset.plans.length > 0;

export const rioPlanFilter = (asset: Asset, planId: string): boolean => asset.plans.some(plan => plan.id === planId);

export const hasVolksTotalPlan = (asset: Asset): boolean => asset.volksTotalPlan !== '';

export const volksTotalPlanFilter = (asset: Asset, volksTotalPlan: string): boolean => asset.volksTotalPlan === volksTotalPlan;

export const bigNumberAssetFilter = (filter: IAssetFilter, asset?: Asset): boolean => {
    
    if(!asset) return true;

    let filterResult = true;

    if (filter.maintenanceFilters)
        filterResult = filterResult && maintenanceFilter(asset, filter.maintenanceFilters);

    if (filter.hasRioPlanFilter === true)
        filterResult = filterResult && hasRioPlan(asset);
    
    if (filter.hasRioPlanFilter === false)
        filterResult = filterResult && !hasRioPlan(asset);

    if (filter.planId)
        filterResult = filterResult && rioPlanFilter(asset, filter.planId);

    if (filter.hasVolksTotalPlanFilter === true)
        filterResult = filterResult && hasVolksTotalPlan(asset);

    if (filter.hasVolksTotalPlanFilter === false)
        filterResult = filterResult && !hasVolksTotalPlan(asset);

    if (filter.volksTotalPlan)
        filterResult = filterResult && volksTotalPlanFilter(asset, filter.volksTotalPlan);

    return filterResult;
}

export const isMaintenanceFilterDisabled = (filter?: IMaintenanceFilter): boolean => {
    if (!filter) return true;
    return !Object.values(filter).some(value => value === true);
}

export const isRioPlanFilterDisabled = (hasRioPlanFilter?: boolean, planId?: string): boolean => {
    return hasRioPlanFilter === undefined && planId === undefined;
}

export const isVolksTotalPlanFilterDisabled = (hasVolksTotalPlanFilter?: boolean, volksTotalPlan?: string): boolean => {
    return hasVolksTotalPlanFilter === undefined && volksTotalPlan === undefined;
}