import StatsWidgets from "@rio-cloud/rio-uikit/StatsWidgets";
import { IAssetFilter } from "../../services/fleetManager/maintenanceTableService";

import MaintenanceBigNumbers from "./maintenanceBigNumbers";
import RioPlanBigNumbers from "./rioPlanBigNumbers";
import VolksTotalPlanBigNumbers from "./volksTotalPlanBigNumbers";

import "./styles.scss";
import { BigNumber, BigNumberMaintenance, BigNumberRioPlan, BigNumberVolksTotal } from "../../models/fleetManager/bigNumbers";

interface IBigNumbersProps {
  assetFilter: IAssetFilter;
  setAssetFilter: (e: IAssetFilter) => void;
  bigNumbers?: BigNumber;
}

export interface ICardBigNumberProps {
  assetFilter: IAssetFilter;
  setAssetFilter: (e: IAssetFilter) => void;
  getDisabledColor: (isDisabled: boolean, defaultColor: string) => void;
  bigNumberMaintenance?: BigNumberMaintenance;
  bigNumberRioPlans?: BigNumberRioPlan[];
  bigNumberVolksTotal?: BigNumberVolksTotal;
  withoutRioPlan?: number;
  withRioPlan?: number;
}

const BigNumbers = ({ assetFilter, setAssetFilter, bigNumbers }: IBigNumbersProps) => {
  const getDisabledColor = (isDisabled: boolean, defaultColor: string): string => {
    return isDisabled ? "text-color-light" : defaultColor;
  }

  return <>
    <StatsWidgets className="big-numbers">
      <MaintenanceBigNumbers
        assetFilter={assetFilter}
        setAssetFilter={setAssetFilter}
        getDisabledColor={getDisabledColor}
        bigNumberMaintenance={bigNumbers?.maintenance}
      />
      
      <RioPlanBigNumbers
        assetFilter={assetFilter}
        setAssetFilter={setAssetFilter}
        getDisabledColor={getDisabledColor}
        bigNumberRioPlans={bigNumbers?.rioPlans}
        withoutRioPlan={bigNumbers?.withoutRioPlan}
        withRioPlan={bigNumbers?.withRioPlan}
      />

      <VolksTotalPlanBigNumbers 
        assetFilter={assetFilter}
        setAssetFilter={setAssetFilter}
        getDisabledColor={getDisabledColor}
        bigNumberVolksTotal={bigNumbers?.volksTotal}
      />
    </StatsWidgets>
  </>
};

export default BigNumbers;
