import ApiService from "../../services/api";

export const fetchAlerts = async (body: any): Promise<any[]> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.post<any[]>(`/alerts`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
