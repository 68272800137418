import { CheckupDto } from "../../dtos/fleetManager/checkupDto";
import { Checkup } from "../../models/fleetManager/checkup";
import ApiService from "../../services/api";

export const fetchCheckup = async (checkupId: string): Promise<Checkup | undefined> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.get<any>(`/checkups/${checkupId}`);
        if (response.status === 200) {
            return CheckupDto(response.data);
        } else return undefined;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}