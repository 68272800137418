import { ReactNode, useRef, useState } from "react";
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import ApplicationLayoutBodyNavigation from "@rio-cloud/rio-uikit/ApplicationLayoutBodyNavigation";
import SubNavigation from "@rio-cloud/rio-uikit/SubNavigation";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../layout/AppContext";
import AppHeader from "../../features/header/AppHeader";
import LeftSidebar from "./LeftSidebar";

interface ISubnavitationHeaderProps {
  children: ReactNode;
}

const SubNavigationHeader = ({ children }: ISubnavitationHeaderProps) => {
  const navItems = [
    { key: "dealership/maintenance-control", value: "Controle de manutenções" },
    {
      key: "dealership/maintenance-monitoring",
      value: "Acompanhamento da manutenção",
    },
    { key: "dealership/my-appointments", value: "Meus agendamentos" },
  ];

  return (
    <ApplicationLayout.Body
      className="uikitDemo"
      navigation={
        <ApplicationLayoutBodyNavigation>
          <SubNavigation
            navItems={navItems.map((item) => ({
              key: item.key,
              route: <NavLink to={`/${item.key}`}>{item.value}</NavLink>,
            }))}
          />
        </ApplicationLayoutBodyNavigation>
      }
    >
      {children}
    </ApplicationLayout.Body>
  );
};

interface IHeaderProps {
  children: ReactNode;
}

const Page = ({ children }: IHeaderProps) => {
  const sidebarRef = useRef<HTMLDivElement>(null);

  return (
    <AppContext.Provider value={{ sidebarRef }}>
      <ApplicationLayout className="MyServiceLayout bg-lighter height-500">
        <ApplicationLayout.Header>
          <AppHeader />
        </ApplicationLayout.Header>
        <LeftSidebar />
        <ApplicationLayout.Sidebar className="right" ref={sidebarRef} />
        <SubNavigationHeader>{children}</SubNavigationHeader>
      </ApplicationLayout>
    </AppContext.Provider>
  );
};

export default Page;
