import { Checkbox, Dialog, Divider } from "@rio-cloud/rio-uikit";
import { Asset } from "../../../../models/dealership/asset";
import { useEffect, useState } from "react";
import { ICheckupChecklist } from "../../../../models/ticket";

interface IInspectionChecklistDialogProps {
    showDialog: boolean;
    onClose: () => void;
    services: ICheckupChecklist[];
    setServices: (services: ICheckupChecklist[]) => void;
    asset: Asset;
}
export const InspectionChecklistDialog = ({ showDialog, onClose, services, setServices, asset }: IInspectionChecklistDialogProps) => {
    const [checklist, setChecklist] = useState<ICheckupChecklist[]>(services);
    
    useEffect(() => {
        setChecklist(services);
    }, [showDialog, services]);

    const setAllChecklist = (serviceIndex: number, ok: boolean) => {
        setChecklist(
            checklist
                .map((s, i) => i === serviceIndex ? {
                    ...s,
                    items: s.items.map(c => ({
                        ...c,
                        ok
                    }))
                } : s)
        );
    }

    return (
        <Dialog
            show={showDialog}
            title='Checklist'
            footer={(
                <button 
                    type="button" 
                    disabled={checklist.some(service => service.items.some(check => !check.ok))} 
                    className="btn btn-primary" 
                    onClick={() => [setServices(checklist), onClose()]}
                >
                    <span className="text-capitalize">Confirmar</span>
                </button>
            )}
            body={(
                <div>
                    <h5 className="text-color-primary">Revisão de entrega</h5>
                    
                    <div className="flex items-start gap-4 border rounded padding-5 margin-y-15">
                        <img
                            src="/assets/images/vehicle-thumb.png"
                            alt="Vehicle"
                            className="w-15 h-15 rounded"
                        />

                        <div className="flex-1 padding-left-10">
                            <p className="text-color-black text-size-16 font-medium m-0">
                                {asset?.name || asset?.model}
                            </p>
                            <p className="text-color-status-resting text-size-14 m-0">
                                Chassi - {asset?.chassis}
                            </p>
                        </div>
                    </div>

                    {
                        checklist.map((service, serviceIndex) => (
                            <div key={serviceIndex}>
                                <div className="text-bold padding-bottom-10">{service.name}</div>
                                <Checkbox 
                                    checked={service.items.every(c => c.ok)}
                                    onChange={(e) => setAllChecklist(serviceIndex, e.target.checked)}
                                ><div className="text-bold padding-bottom-10">Selecionar todos</div></Checkbox>
                                {
                                    service.items.map((check, checklistIndex) => (
                                        <div key={checklistIndex} className="padding-y-5">
                                            <Checkbox 
                                                checked={check.ok}
                                                onChange={(e) => {
                                                    setChecklist(
                                                        checklist
                                                            .map((s, i) => i === serviceIndex ? {
                                                                ...s,
                                                                items: s.items.map((c, j) => j === checklistIndex ? {
                                                                    ...c,
                                                                    ok: e.target.checked
                                                                } : c)
                                                            } : s)
                                                    );
                                                }}
                                            >{check.name}</Checkbox>
                                        </div>
                                    ))
                                }
                                <Divider />
                            </div>
                        ))
                    }
                    <div className="text-color-primary">
                        Esta Concessionária garante que o veículo mencionado neste manual foi rigorosamente inspecionado para entrega ao cliente, de acordo com os procedimentos técnicos da MAN Latin America.
                    </div>
                </div>
            )}
            onClose={() => [setServices(checklist), onClose()]}
            showCloseButton
            bsSize='md'
        />

    )
}