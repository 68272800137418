import { IMonthCalendarProps } from '.';
import { getCurrentMonthDays, isDayInMonth } from '../../../../../utils/dateUtils';

const Calendar = ({ dayChoosed, chooseDay }: IMonthCalendarProps) => {
	const weekdays = ['s', 't', 'q', 'q', 's', 's', 'd'];
	const days = getCurrentMonthDays(dayChoosed);

	return (
		<div>
			<div className='display-grid gap-20 grid-cols-6 text-capitalize-first-word text-size-16 padding-10 text-medium'>
				<div 
					className='display-flex align-items-center cursor-pointer'
					onClick={() => chooseDay(new Date(dayChoosed.getFullYear(), dayChoosed.getMonth(), 0))}
				>
					<img src="/assets/images/arrow.svg" alt="Seta" style={{ transform: 'rotate(180deg)' }} />
				</div>
				<div className='grid-colspan-4 text-capitalize'>
					{dayChoosed.toLocaleString('pt-BR', { month: 'long' })} - {dayChoosed.getFullYear()}
				</div>
				<div 
					className='display-flex justify-content-end align-items-center cursor-pointer text-right'
					onClick={() => chooseDay(new Date(dayChoosed.getFullYear(), dayChoosed.getMonth() + 1, 1))}
				>
					<img src="/assets/images/arrow.svg" alt="Seta" />
				</div>
			</div>
			<div className="padding-10">
				<div className="display-grid gap-20 grid-cols-7 width-250">
					{[...weekdays, ...days].map((day, index) => (
						<div
							key={index}
							style={{ lineHeight: '25px' }}
							className={`
								height-25 width-25 text-center text-size-10 text-medium cursor-pointer
								${index < 7 ? 'text-color-dark cursor-text' : ''} 
								${index >= 7 && !isDayInMonth(day) ? 'text-color-gray cursor-text' : ''}
								${dayChoosed.getDate() === day ? 'bg-highlight-darker text-color-white rounded-circle' : ''}
							`}
							onClick={() => index >= 7 && isDayInMonth(day) && chooseDay(new Date(dayChoosed.getFullYear(), dayChoosed.getMonth(), day as number))}
						>
							{day.toString().replace(/[-+]/g, '')}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Calendar;