import { useEffect, useState } from "react";
import BigNumbers from "../../../components/bigNumbers";
import TableCommonDemo from "../../../components/maintenanceTable";
import { Asset } from "../../../models/fleetManager/asset";
import { fetchMaintenance } from "../../../api/fleetManager/fetchMaintenance";
import { IAssetFilter } from "../../../services/fleetManager/maintenanceTableService";
import { BigNumber } from "../../../models/fleetManager/bigNumbers";
import { Loading } from "../../../utils/loading";
import AppLayout from "../../../layout/AppLayout";
import { BigNumberDto } from "../../../dtos/fleetManager/bigNumberDto";
import { Client, API_REGIONS } from "@amityco/ts-sdk";

const PreventiveMaintenanceContent = (props: any) => {
  Client.createClient(import.meta.env.VITE_AMITY_API_KEY, API_REGIONS.US)
  
  const [loading, setLoading] = useState<boolean>(true);

  const [assets, setAssets] = useState<Asset[]>([]);
  const [filteredAssets, setFilteredAssets] = useState<Asset[]>([]);

  const [bigNumbers, setBigNumbers] = useState<BigNumber>();
  const [assetFilter, setAssetFilter] = useState<IAssetFilter>({
    maintenanceFilters: {
      toSchedule: undefined,
      overdue: undefined,
      scheduled: undefined,
      inProgress: undefined,
    },
    hasRioPlanFilter: undefined,
    planId: undefined,
    hasVolksTotalPlanFilter: undefined,
    volksTotalPlan: undefined,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const [assets, bigNumbers] = await fetchMaintenance(
        props.assetIds,
        props.groupIds
      );
      setAssets(assets);
      setFilteredAssets(assets);
      setBigNumbers(bigNumbers);
      setLoading(false);
    };

    const filterAssets = (defaultAssets?: Asset[]) => {
      if (defaultAssets) {
        setFilteredAssets(defaultAssets);
        setBigNumbers(BigNumberDto({}, defaultAssets));
      } else {
        const childFilteredAssets = assets.filter(
          (asset) =>
            props.assetIds.includes(asset.id) ||
            props.groupIds.some((group: string) =>
              (asset.groups ?? ["ungruped"]).includes(group)
            )
        );
        setFilteredAssets(childFilteredAssets);
        setBigNumbers(BigNumberDto({}, childFilteredAssets));
      }
    };
    if ((!props.assetIds && !props.groupIds) || assets.length === 0)
      fetchData();
    else if (props.assetIds.length === 0 && props.groupIds.length === 0)
      filterAssets(assets);
    else filterAssets();
  }, [props.assetIds, props.groupIds]);

  return loading ? (
    <Loading label="Carregando..." />
  ) : (
    <div>
      <BigNumbers
        setAssetFilter={setAssetFilter}
        assetFilter={assetFilter}
        bigNumbers={bigNumbers}
      />
      <div style={{ marginTop: 50 }}>
        <TableCommonDemo assets={filteredAssets} assetFilter={assetFilter} />
      </div>
    </div>
  );
};

const PreventiveMaintenance = () => {
  return (
    <AppLayout>
      <PreventiveMaintenanceContent />
    </AppLayout>
  );
};

export default PreventiveMaintenance;
