import { Scheduling } from "../../../../../models/dealership/schedule";

interface IDaySchedulingsProps {
	dayTitle: string;
	tasks: Scheduling[];
}

const DaySchedulings = ({ dayTitle, tasks }: IDaySchedulingsProps) => {
	return (
		<div className="padding-10 margin-top-10">
			<div className="flex text-size-16 text-medium padding-bottom-10" style={{ lineHeight: '34px' }}>
				<span className="rioglyph rioglyph-calendar" />
				{dayTitle}
			</div>
			<div>
				{tasks.map((task, index) => (
					<div key={index} className="padding-5 text-size-10 display-grid grid-cols-2-1fr-max-content">
						<div className="text-bold flex">
							<div className="bg-highlight-darker rounded-circle width-10 height-10 margin-3"></div>
							<div className="padding-left-5">
								{task.asset.name || task.asset.model}
							</div>
						</div>
						<div className="text-medium text-right">
							{new Date(task.scheduleTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default DaySchedulings;