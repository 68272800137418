import { Dealership } from "../../models/dealership/dealership";

export const dealershipDto = (data: any): Dealership => {
    return {
        id: data?.id ?? '',
        name: data?.fantasyName ?? '',
        address: data?.address ?? '',
        distance: data?.distance ?? 0,
        geoLocation: {
            lat: data?.latitude ?? 0,
            lng: data?.longitude ?? 0,
        },
        city: data?.city,
        state: data?.state,
        neighborhood: data?.neighborhood,
    };
}