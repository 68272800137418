import StatsWidget from "@rio-cloud/rio-uikit/StatsWidget";
import { ICardBigNumberProps } from ".";
import StatsWidgetNumber from "@rio-cloud/rio-uikit/StatsWidgetNumber";
import StatsWidgetSpacer from "@rio-cloud/rio-uikit/StatsWidgetSpacer";
import { isRioPlanFilterDisabled } from "../../services/fleetManager/maintenanceTableService";

const RioPlanBigNumbers = ({
    assetFilter,
    setAssetFilter,
    getDisabledColor,
    bigNumberRioPlans,
    withoutRioPlan,
    withRioPlan,
}: ICardBigNumberProps) => {

    return (
        <StatsWidget className="card">
            <StatsWidget.Header>RIO</StatsWidget.Header>
            <StatsWidget.Body>
                <StatsWidgetNumber
                    className={`
                        ${getDisabledColor(
                                (assetFilter.hasRioPlanFilter === undefined || assetFilter.hasRioPlanFilter === false) && !isRioPlanFilterDisabled(assetFilter.hasRioPlanFilter, assetFilter.planId),
                                'text-color-status-driving'
                            )} 
                        cursor-pointer
                        `}
                    value={withRioPlan}
                    label="Com RIO"
                    onClick={() =>
                        setAssetFilter({
                            ...assetFilter,
                            hasRioPlanFilter: assetFilter.hasRioPlanFilter === true ? undefined : true,
                        })
                    }
                />
                <StatsWidgetSpacer />
                <StatsWidgetNumber
                    className={`
                        ${getDisabledColor(
                                (assetFilter.hasRioPlanFilter === undefined || assetFilter.hasRioPlanFilter === true) && !isRioPlanFilterDisabled(assetFilter.hasRioPlanFilter, assetFilter.planId),
                                'text-color-danger'
                            )} 
                        cursor-pointer
                        `}
                    value={withoutRioPlan}
                    label="Sem RIO"
                    onClick={() =>
                        setAssetFilter({
                            ...assetFilter,
                            hasRioPlanFilter: assetFilter.hasRioPlanFilter === false ? undefined : false,
                            planId: undefined,
                        })
                    }
                />
            </StatsWidget.Body>
            <StatsWidget.Body>
                {
                    bigNumberRioPlans?.map((rioPlan, index) => (
                        <>
                            <StatsWidgetNumber
                                className={`
                                    ${getDisabledColor(
                                            (assetFilter.hasRioPlanFilter === false || assetFilter.planId !== rioPlan.productId) && !isRioPlanFilterDisabled(assetFilter.hasRioPlanFilter, assetFilter.planId),
                                            ''
                                        )} 
                                    cursor-pointer
                                    `}
                                value={rioPlan.amount}
                                label={rioPlan.productName}
                                onClick={() =>
                                    assetFilter.hasRioPlanFilter && setAssetFilter({
                                        ...assetFilter,
                                        planId: assetFilter.planId === rioPlan.productId ? undefined : rioPlan.productId,
                                    })
                                }
                            />
                            {index !== (bigNumberRioPlans.length - 1) && <StatsWidgetSpacer />}
                        </>
                    ))
                }
            </StatsWidget.Body>
        </StatsWidget>
    )
}

export default RioPlanBigNumbers;