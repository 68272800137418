import { dealershipConsultantDto } from "../../dtos/fleetManager/dealershipDto";
import { DealershipConsultant } from "../../models/fleetManager/dealership";
import ApiService from "../../services/api";

export const fetchConsultants = async (date: Date, dealershipId: string): Promise<DealershipConsultant[]> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.get<any>(`/users/consultants?date=${date.toISOString().split('T')[0]}&dn=${dealershipId}`);
        if (response.status === 200) {
            return response.data.map(dealershipConsultantDto);
        } else return [];
    } catch (error) {
        console.error(error);
        return [];
    }
}