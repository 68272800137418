import { SchedulingDto } from "../../dtos/dealership/schedulingDto";
import { Scheduling } from "../../models/dealership/schedule";
import ApiService from "../../services/api";

export const fetchSchedule = async (month: number, year: number): Promise<Scheduling[]> => {
    const apiService = new ApiService();
    const query = `month=${month}&year=${year}`
    try {
        const response = await apiService.get<any[]>(`/schedules/checkups?${query}`);
        if (response.status === 200) {
            return response.data.map(SchedulingDto);
        } else return [];
    } catch (error) {
        console.error(error);
        return [];
    }
}