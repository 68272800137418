import Page from "../Page";
import Appointmens from "./components/appointments";

import './styles.scss';

const MyAppointmens = () => {
	return (
		<div className="mySchedule">
			<Page>
				<div className="min-h-screen w-full flex flex-column">
					<Appointmens />
				</div>
			</Page>
		</div>
	)
}

export default MyAppointmens;