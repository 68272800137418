import { Dialog } from "@rio-cloud/rio-uikit";

interface IFinishStepDialogProps {
    open: boolean;
    loading: boolean;
    onClose: () => void;
    onFinish: () => void;
}

export const FinishStepDialog = (props: IFinishStepDialogProps) => {
    const { open, loading, onClose, onFinish } = props;

    return (
        <Dialog
            show={open}
            onClose={onClose}
            showCloseButton
            bsSize={Dialog.SIZE_SM}
        >
            <Dialog.Title title="Deseja finalizar essa etapa?" />
            <Dialog.Body>
                <div className="cancel-content flex flex-col">
                    <span className="text-color-darker text-size-16 text-normal">
                        Você está prestes a finalizar essa etapa! Ao clicar no botão Sim, a etapa seguinte iniciará automaticamente.<br /><br />
                        Deseja finalizar mesmo assim?
                    </span>
                </div>
            </Dialog.Body>
            <Dialog.Footer>
                <div className="flex items-center justify-end">
                    <div className="flex items-center gap-6">
                        <span
                            className="text-medium text-size-14 cursor-pointer text-color-primary"
                            onClick={onClose}
                        >
                            Voltar
                        </span>

                        <button
                            type="button"
                            className={`btn btn-primary ${loading ? 'btn-loading disabled' : ''}`}
                            disabled={loading}
                            onClick={onFinish}
                        >
                            Sim
                        </button>
                    </div>
                </div>
            </Dialog.Footer>
        </Dialog>
    );
};
