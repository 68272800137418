import { useEffect, useState } from "react";
import StatsWidgets from "@rio-cloud/rio-uikit/StatsWidgets";
import OccupancyRate from "./OccupancyRate";
import SchedulingCounts from "./SchedulingCounts";
import { getDailyOccupancyRate, getDailySchedulingCounts, getMaintenanceWeekSchedule, getMonthlyOccupancyRate, getMonthlySchedulingCounts, getWeeklyOccupancyRate, getWeeklySchedulingCounts } from "../../../../../services/dealership/consultantScheduleService";
import { Scheduling } from "../../../../../models/dealership/schedule";

interface IBigNumbersProps {
    consultantMonthSchedule: Scheduling[];
    firstWeekDay: Date;
    currentDate: Date;
    scheduleViewType: string;
}

const BigNumbers = ({ consultantMonthSchedule, firstWeekDay, currentDate, scheduleViewType }: IBigNumbersProps) => {
    const [occupancyRate, setOccupancyRate] = useState<number>(0);
    const [preventiveCount, setPreventiveCount] = useState<number>(0);
    const [correctiveCount, setCorrectiveCount] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            const weekSchedule = await getMaintenanceWeekSchedule(firstWeekDay, consultantMonthSchedule);

            if(scheduleViewType === 'month') {
                setOccupancyRate(getMonthlyOccupancyRate(consultantMonthSchedule, currentDate));
                const [preventive, corrective] = getMonthlySchedulingCounts(consultantMonthSchedule);
                setPreventiveCount(preventive);
                setCorrectiveCount(corrective);
            }

            if(scheduleViewType === 'week') {
                setOccupancyRate(getWeeklyOccupancyRate(weekSchedule));
                const [preventive, corrective] = getWeeklySchedulingCounts(weekSchedule);
                setPreventiveCount(preventive);
                setCorrectiveCount(corrective);
            }

            if(scheduleViewType === 'day') {
                const daySchedule = weekSchedule.days.find(day => day.date.getDate() === currentDate.getDate());
                if(daySchedule) {
                    setOccupancyRate(getDailyOccupancyRate(daySchedule));
                    const [preventive, corrective] = getDailySchedulingCounts(daySchedule);
                    setPreventiveCount(preventive);
                    setCorrectiveCount(corrective);
                }
            }
        }

        fetchData();
    }, [firstWeekDay, consultantMonthSchedule, currentDate, scheduleViewType ]);

    return (
        <div className="padding-bottom-50 display-flex justify-content-center">
            <StatsWidgets>
                <OccupancyRate occupancyRate={occupancyRate} />
                <SchedulingCounts preventiveCount={preventiveCount} correctiveCount={correctiveCount} />
            </StatsWidgets>
        </div>
    );
}

export default BigNumbers;
