import React, { useState } from "react";
import RadioButton from "@rio-cloud/rio-uikit/RadioButton";
import {
  MaintenanceMonitoringStepsEnum,
  MaintenanceMonitoringStatusEnum,
} from "../../../../enums/maintenanceMonitoring";
import { MaintenanceMonitoringTimelineStep } from "../../../../models/ticket";

interface ITimelineProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>; // Aceita números ou funções que retornam números
  doneTime: number;
  progressTime: number;
  totalTime: number;
  overTime: number;
  timeline: MaintenanceMonitoringTimelineStep[];
}

export const mockTimeline: MaintenanceMonitoringTimelineStep[] = [
  {
    step: MaintenanceMonitoringStepsEnum.TICKET,
    estimatedTime: 120, // Tempo estimado em minutos
    date: new Date(2025, 0, 25, 10, 30), // Data e hora da etapa
    time: 90, // Tempo decorrido em minutos
    status: MaintenanceMonitoringStatusEnum.DONE, // Status concluído
  },
  {
    step: MaintenanceMonitoringStepsEnum.SCREENING,
    estimatedTime: 60,
    date: new Date(2025, 0, 25, 12, 0),
    time: 30,
    status: MaintenanceMonitoringStatusEnum.IN_PROGRESS,
  },
  {
    step: MaintenanceMonitoringStepsEnum.REPAIR,
    estimatedTime: 180,
    date: new Date(), // Etapa ainda não iniciada
    time: 0,
    status: MaintenanceMonitoringStatusEnum.PENDING,
  },
  {
    step: MaintenanceMonitoringStepsEnum.INSPECTION,
    estimatedTime: 90,
    date: new Date(), // Etapa ainda não iniciada
    time: 0,
    status: MaintenanceMonitoringStatusEnum.PENDING,
  },
  {
    step: MaintenanceMonitoringStepsEnum.RELEASE,
    estimatedTime: 30,
    date: new Date(), // Etapa ainda não iniciada
    time: 0,
    status: MaintenanceMonitoringStatusEnum.PENDING,
  },
];

const Rate = ({
  step,
  setStep,
  doneTime,
  progressTime,
  totalTime,
  overTime,
  timeline: initialTimeline,
}: ITimelineProps) => {
  const [timeline, setTimeline] = useState<MaintenanceMonitoringTimelineStep[]>(
    () =>
      initialTimeline.map((step, index) => ({
        ...step,
        status:
          index === 0
            ? MaintenanceMonitoringStatusEnum.IN_PROGRESS
            : MaintenanceMonitoringStatusEnum.PENDING,
      }))
  );

  const handleNextStep = () => {
    setTimeline((prevTimeline) => {
      const currentStepIndex = prevTimeline.findIndex(
        (step) => step.status === MaintenanceMonitoringStatusEnum.IN_PROGRESS
      );

      if (
        currentStepIndex === -1 ||
        currentStepIndex >= prevTimeline.length - 1
      ) {
        return prevTimeline; // Se não há mais steps ou já estamos no último, não faz nada
      }

      return prevTimeline.map((step, index) => {
        if (index === currentStepIndex) {
          return { ...step, status: MaintenanceMonitoringStatusEnum.DONE };
        }
        if (index === currentStepIndex + 1) {
          return {
            ...step,
            status: MaintenanceMonitoringStatusEnum.IN_PROGRESS,
          };
        }
        return step;
      });
    });
    setStep((prevStep) => prevStep + 1);
  };

  const getStepIcon = (step: MaintenanceMonitoringStepsEnum) => {
    switch (step) {
      case MaintenanceMonitoringStepsEnum.TICKET:
        return "rioglyph-warning-sign";
      case MaintenanceMonitoringStepsEnum.SCREENING:
        return "rioglyph-transfer";
      case MaintenanceMonitoringStepsEnum.REPAIR:
        return "rioglyph-workshop";
      case MaintenanceMonitoringStepsEnum.INSPECTION:
        return "rioglyph-maintenance-components";
      case MaintenanceMonitoringStepsEnum.RELEASE:
        return "rioglyph-area-out";
      default:
        return "";
    }
  };

  const getFormattedTime = (time: number): string => {
    return `${
      time < 60
        ? `${time} min`
        : `${Math.floor(time / 60)
            .toString()
            .padStart(2, "0")} h ${(time % 60).toString().padStart(2, "0")} min`
    }`;
  };

  return (
    <div>
      <div className="display-grid grid-cols-5 margin-auto margin-top-20 width-100pct">
        {timeline.map((step, index) => (
          <div
            key={`step_${index}`}
            className={`
              padding-15 border-width-1 rounded text-color-gray margin-x-15
              ${
                step.status === MaintenanceMonitoringStatusEnum.IN_PROGRESS
                  ? "border-color-primary bg-white"
                  : step.status === MaintenanceMonitoringStatusEnum.DONE
                  ? "border-color-success bg-white"
                  : "border-color-gray bg-lightest"
              }
            `}
          >
            <div className="flex items-center">
              <div>
                <i
                  className={`
                    rioglyph 
                    ${
                      step.status === MaintenanceMonitoringStatusEnum.DONE
                        ? "rioglyph-ok"
                        : getStepIcon(step.step)
                    } 
                    ${
                      step.status ===
                      MaintenanceMonitoringStatusEnum.IN_PROGRESS
                        ? "text-color-primary"
                        : step.status === MaintenanceMonitoringStatusEnum.DONE
                        ? "text-color-success"
                        : "text-color-gray"
                    }
                  `}
                  style={{ fontSize: "25px" }}
                />
              </div>
              <div className="flex flex-col margin-left-20 width-100pct">
                <div className="border border-bottom-only flex justify-between padding-y-5">
                  <span
                    className={`
                      font-bold 
                      ${
                        step.status ===
                        MaintenanceMonitoringStatusEnum.IN_PROGRESS
                          ? "text-color-primary"
                          : step.status === MaintenanceMonitoringStatusEnum.DONE
                          ? "text-color-success"
                          : "text-color-gray"
                      }
                    `}
                  >
                    {step.step}
                  </span>
                  <span>
                    <i className="rioglyph rioglyph-time-alt"></i>{" "}
                    {Math.floor(step.estimatedTime / 60)
                      .toString()
                      .padStart(2, "0")}
                    :{(step.estimatedTime % 60).toString().padStart(2, "0")}h
                  </span>
                </div>
                <div
                  className={`
                    flex justify-between padding-y-5
                    ${
                      step.status === MaintenanceMonitoringStatusEnum.DONE
                        ? "text-color-success"
                        : ""
                    }
                  `}
                >
                  <span>
                    {step.date
                      ? `${step.date.getDate().toString().padStart(2, "0")}/${(
                          step.date.getMonth() + 1
                        )
                          .toString()
                          .padStart(2, "0")}/${step.date.getFullYear()}`
                      : "--/--/----"}
                  </span>
                  <span>
                    <i className="rioglyph rioglyph-time-alt"></i>{" "}
                    {step.time
                      ? `${Math.floor(step.time / 60)
                          .toString()
                          .padStart(2, "0")}:${(step.time % 60)
                          .toString()
                          .padStart(2, "0")}h`
                      : "--:--h"}
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`
                margin-top-10 width-100pct rounded-circle border-width-1 text-center padding-2
                ${
                  step.status === MaintenanceMonitoringStatusEnum.IN_PROGRESS
                    ? "bg-highlight-lightest border-color-highlight-light text-color-highlight-dark text-bold"
                    : step.status === MaintenanceMonitoringStatusEnum.DONE
                    ? "bg-emerald-50 border-emerald-300 text-color-success text-bold"
                    : "bg-decent border-color-gray"
                }
              `}
            >
              {step.status}
            </div>
          </div>
        ))}
      </div>

      <div className="margin-top-20 margin-bottom-10">
        <span style={{ color: "#707F8F" }} className="text-size-16 font-bold">
          Avalie como foi a etapa de Chamado do seu veículo
        </span>
        <div className="height-10 w-full margin-top-20 rate-bar"></div>
        <div className="margin-top-20 w-full flex space-between">
          <RadioButton name="radiosInline" id="1" inline>
            1
          </RadioButton>
          <RadioButton name="radiosInline" id="2" inline>
            2
          </RadioButton>
          <RadioButton name="radiosInline" id="3" inline>
            3
          </RadioButton>
          <RadioButton name="radiosInline" id="4" inline>
            4
          </RadioButton>
          <RadioButton name="radiosInline" id="5" inline>
            5
          </RadioButton>
          <RadioButton name="radiosInline" id="6" inline>
            6
          </RadioButton>
          <RadioButton name="radiosInline" id="7" inline>
            7
          </RadioButton>
          <RadioButton name="radiosInline" id="8" inline>
            8
          </RadioButton>
          <RadioButton name="radiosInline" id="9" inline>
            9
          </RadioButton>
          <RadioButton name="radiosInline" id="10" inline>
            10
          </RadioButton>
        </div>

        <div className="form-group margin-top-20">
          <label htmlFor="textArea">Comentários</label>
          <textarea
            className="form-control"
            id="textArea"
            placeholder="Descritivo"
            style={{
              height: 173,
            }}
          ></textarea>
        </div>

        <div className="margin-top-20 flex justify-center">
          <button
            type="button"
            className="btn btn-lg btn-primary"
            onClick={handleNextStep}
          >
            <span className="text-capitalize">Avançar</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Rate;
