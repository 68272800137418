import { SchedulingType } from "../../enums/dealership/taskType";
import { Scheduling } from "../../models/dealership/schedule";
import { assetDto } from "./assetDto";
import { dealershipDto } from "./dealershipDto";
import { driverDto } from "./driverDto";

export const SchedulingDto = (data: any): Scheduling => {
    return {
        id: data.id,
        type: SchedulingType.PREVENTIVA, //data.checkup.checkupType.name,
        description: `${data.checkup.desc ?? ''}  (${data.checkup?.range?.end} km)`,
        shortDescription: data.checkup.desc ?? '',
        asset: assetDto({
            ...data.vehicleSchedule.vehicle, 
            plans: data.vehicleSchedule.plans, 
            checkup: data.checkup,
            vehicleSchedule: data.vehicleSchedule,
            schedule: data.schedule,
        }),
        driver: driverDto(data.vehicleSchedule.driver),
        observation: data.vehicleSchedule.generalObservations,
        comments: data.vehicleSchedule.comments,
        consultant: `${data.consultant?.firstName ?? ''} ${data.consultant?.lastName ?? ''}`,
        dealership: dealershipDto(data.vehicleSchedule.dealership),
        scheduleTime: new Date(data.schedule.scheduledDate),
        services: data.checkup.checkupType.services
            .map((service:any) => ({title: service.name, items: service.items})),
        otherServices: data.vehicleSchedule.otherServices?.services?.map((service:any) => service.name),
        totalPrice: data.vehicleSchedule.otherServices.total,
    };
}