import { ticketDto } from "../dtos/ticketDto";
import { ICheckupChecklist, Ticket, TicketInspection, TicketRepair, TicketScreening } from "../models/ticket";
import ApiService from "../services/api";

export const fetchTickets = async ({ consultantUser }: { consultantUser: boolean }): Promise<Ticket[]> => {
  const apiService = new ApiService();
  try {
    const route = consultantUser ? 'consultant-user' : 'manager-account';
    const response = await apiService.get<any[]>(`/maintenance/ticket/${route}`);
    if (response.status < 400) {
      return response.data.map(ticketDto);
    } else {
      console.warn("Unexpected status code:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching tickets:", error);
    return [];
  }
}

export const fetchTicket = async (ticketId: string): Promise<Ticket | undefined> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.get<any>(`/maintenance/ticket/${ticketId}`);
    if (response.status < 400) {
      return ticketDto(response.data);
    } else {
      console.warn("Unexpected status code:", response.status);
      return undefined;
    }
  } catch (error) {
    console.error("Error fetching ticket:", error);
    return undefined;
  }
}

export const openTicket = async ({ schedulingId, date }: { schedulingId: string, date: Date }): Promise<string> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.post<void>(
      `/maintenance/ticket`,
      {
        maintenanceScheduleId: schedulingId,
        checkInDate: date.toISOString().split('T')[0],
        checkInHour: date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
      }
    );
    if (response.status < 400) {
      return (response.data as any).id;
    } else {
      console.warn("Unexpected status code:", response.status);
      return '';
    }
  } catch (error) {
    console.error("Error post ticket:", error);
    return '';
  }
}

export const postCheckoutTicket = async ({ ticketId, date }: { ticketId: string, date: Date }): Promise<boolean> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.post<void>(
      `/maintenance/ticket/${ticketId}/ticket/checkout`,
      {
        checkOutDate: date.toISOString().split('T')[0],
        checkOutHour: date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
      }
    );
    if (response.status < 400) {
      return true;
    } else {
      console.warn("Unexpected status code:", response.status);
      return false;
    }
  } catch (error) {
    console.error("Error post checkout ticket:", error);
    return false;
  }
}

export const putScreening = async (
  { ticketId, screening }:
    { ticketId: string, screening: TicketScreening }
) => {
  const apiService = new ApiService();
  try {
    const response = await apiService.post<void>(
      `/maintenance/ticket/${ticketId}/screening`,
      {
        ...screening,
        checkInDate: screening.checkIn ? screening.checkIn.toISOString().split('T')[0] : undefined,
        checkInHour: screening.checkIn ? screening.checkIn.getHours().toString().padStart(2, '0') + ':' + screening.checkIn.getMinutes().toString().padStart(2, '0') : undefined,
        checkOutDate: screening.checkOut ? screening.checkOut.toISOString().split('T')[0] : undefined,
        checkOutHour: screening.checkOut ? screening.checkOut.getHours().toString().padStart(2, '0') + ':' + screening.checkOut.getMinutes().toString().padStart(2, '0') : undefined,
      }
    );
    if (response.status < 400) {
      return true;
    } else {
      console.warn("Unexpected status code:", response.status);
      return false;
    }
  } catch (error) {
    console.error("Error put screening:", error);
    return false;
  }
}

export const putRepair = async (
  { ticketId, repair }:
    { ticketId: string, repair: TicketRepair }
) => {
  const apiService = new ApiService();
  try {
    const response = await apiService.post<void>(
      `/maintenance/ticket/${ticketId}/repair`,
      {
        ...repair,
        repairDate: repair.repairDate ? repair.repairDate.toISOString().split('T')[0] : undefined,
        checkInDate: repair.checkIn ? repair.checkIn.toISOString().split('T')[0] : undefined,
        checkInHour: repair.checkIn ? repair.checkIn.getHours().toString().padStart(2, '0') + ':' + repair.checkIn.getMinutes().toString().padStart(2, '0') : undefined,
        checkOutDate: repair.checkOut ? repair.checkOut.toISOString().split('T')[0] : undefined,
        checkOutHour: repair.checkOut ? repair.checkOut.getHours().toString().padStart(2, '0') + ':' + repair.checkOut.getMinutes().toString().padStart(2, '0') : undefined,
      }
    );
    if (response.status < 400) {
      return true;
    } else {
      console.warn("Unexpected status code:", response.status);
      return false;
    }
  } catch (error) {
    console.error("Error put repair:", error);
    return false;
  }
}

export const putInspection = async (
  { ticketId, inspection, checklist }:
    { ticketId: string, inspection: TicketInspection, checklist?: ICheckupChecklist[] | undefined }
) => {
  const apiService = new ApiService();
  try {
    const [response, ] = await Promise.all([
      apiService.post<void>(
        `/maintenance/ticket/${ticketId}/inspection`,
        {
          ...inspection,
          checkInDate: inspection.checkIn ? inspection.checkIn.toISOString().split('T')[0] : undefined,
          checkInHour: inspection.checkIn ? inspection.checkIn.getHours().toString().padStart(2, '0') + ':' + inspection.checkIn.getMinutes().toString().padStart(2, '0') : undefined,
        }
      ),
      apiService.post<void>(
        `/maintenance/ticket/${ticketId}/checklist/checkup`,
        (checklist ?? []).map(service => service.items.map(item => ({ id: item.id, ok: item.ok }))).flat(),
      ),
    ]);

    if (inspection.checkOut) {
      await apiService.post<void>(
        `/maintenance/ticket/${ticketId}/inspection/checkout`,
        {
          checkOutDate: inspection.checkOut ? inspection.checkOut.toISOString().split('T')[0] : undefined,
          checkOutHour: inspection.checkOut ? inspection.checkOut.getHours().toString().padStart(2, '0') + ':' + inspection.checkOut.getMinutes().toString().padStart(2, '0') : undefined,
        }
      );
    }
    if (response.status < 400) {
      return true;
    } else {
      console.warn("Unexpected status code:", response.status);
      return false;
    }
  } catch (error) {
    console.error("Error put inspection:", error);
    return false;
  }
}

export interface ITicketFile {
  name: string;
  updatedAt: string;
  contentType: string;
  contentSize: number;
}

export const uploadTicketImage = async ({ ticketId, imageFile, step }: { ticketId: string, imageFile: File, step: string }): Promise<boolean> => {
  const apiService = new ApiService();
  const formData = new FormData();
  formData.append('file', imageFile);

  try {
    const response = await apiService.post<void>(
      `/attachment/ticket/${ticketId}/${step}/upload`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    if (response.status < 400) {
      return true;
    } else {
      console.warn("Unexpected status code:", response.status);
      return false;
    }
  } catch (error) {
    console.error("Error uploading ticket image:", error);
    return false;
  }
}

export const fetchTicketImages = async ({ ticketId, step }: { ticketId: string, step: string }): Promise<ITicketFile[]> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.get<ITicketFile[]>(`/attachment/ticket/${ticketId}/${step}`);
    if (response.status < 400) {
      return response.data;
    } else {
      console.warn("Unexpected status code:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching ticket images:", error);
    return [];
  }
}