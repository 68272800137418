import { ReactNode, useRef, useState } from "react";
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import AppHeader from "../features/header/AppHeader";
import { AppContext } from "./AppContext";
import SubnavigationHeader from "../features/header/SubnavigationHeader";

import "./App.css";
import LeftSidebar from "../features/header/LeftSidebar";
import React from "react";

interface IHeaderProps {
  children: ReactNode;
}

const AppLayout = ({ children }: IHeaderProps) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [assetIds, setAssetIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);

  return (
    <AppContext.Provider value={{ sidebarRef }}>
      <ApplicationLayout data-testid="app-layout">
        <ApplicationLayout.Header>
          <AppHeader />
        </ApplicationLayout.Header>
        <LeftSidebar
          assetIds={assetIds}
          setAssetIds={setAssetIds}
          groupIds={groupIds}
          setGroupIds={setGroupIds}
        />
        <ApplicationLayout.Sidebar className="right" ref={sidebarRef} />
        <SubnavigationHeader>{React.cloneElement(
          children as React.ReactElement, 
          { assetIds, groupIds }
        )}</SubnavigationHeader>
      </ApplicationLayout>
    </AppContext.Provider>
  );
};

export default AppLayout;
