import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import { useState } from "react";
import ChatMessages from "../../components/chatMessages";
import Calendar from "../../components/calendar";

export type SidebarDemoKey = "showSidebarLeft";

const LeftSidebar = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSidebarLeft, setShowSidebarLeft] = useState(false);

  const handleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const toggleSidebarLeft = () => {
    setShowSidebarLeft(!showSidebarLeft);
  };

  return (
    <ApplicationLayout.Sidebar>
      <div className="icons-col">
        <span className="rioglyph rioglyph-truck"></span>

        <div className="display-flex flex-column align-items-center icons-col__bottom">
          <ChatMessages />
          <span
            onClick={handleCalendar}
            className="rioglyph rioglyph-calendar"
          ></span>
        </div>
      </div>

      {showCalendar && (
        <div className="calendar">
          <Calendar closeSidebar={() => handleCalendar()} />
        </div>
      )}
    </ApplicationLayout.Sidebar>
  );
};

export default LeftSidebar;
