import StatsWidget from "@rio-cloud/rio-uikit/StatsWidget";
import StatsWidgetNumber from "@rio-cloud/rio-uikit/StatsWidgetNumber";
import StatsWidgetSpacer from "@rio-cloud/rio-uikit/StatsWidgetSpacer";

interface ISchedulingCountsProps {
    preventiveCount: number;
    correctiveCount: number;
}

const SchedulingCounts = ({ preventiveCount, correctiveCount }: ISchedulingCountsProps) => {
    return (
        <StatsWidget>
            <StatsWidget.Header>Tipo de manutenção</StatsWidget.Header>
            <StatsWidget.Body>
                <StatsWidgetNumber
                    className="text-color-status-driving"
                    value={preventiveCount}
                    label="Preventiva"
                />
                <StatsWidgetSpacer />
                <StatsWidgetNumber
                    className="text-color-danger"
                    value={correctiveCount}
                    label="Corretiva"
                />
            </StatsWidget.Body>
        </StatsWidget>
    )
}

export default SchedulingCounts;