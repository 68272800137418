import { driverDto } from "../../dtos/fleetManager/driverDto";
import { Driver } from "../../models/fleetManager/driver";
import ApiService from "../../services/api";

export const fetchDrivers = async (): Promise<Driver[]> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.get<any>(`/drivers`);
        if (response.status === 200) {
            return response.data.map(driverDto);
        } else return [];
    } catch (error) {
        console.error(error);
        return [];
    }
}