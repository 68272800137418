import { ChannelRepository, MessageContentType, MessageRepository, UserRepository } from "@amityco/ts-sdk";

export interface IChannel {
    id: string;
    displayName: string;
    lastActivity: Date;
    hasUnreadMessage: boolean;
    members: IMember[];
    profileImage?: string;
}

export interface IMember {
    userId: string;
    displayName: string;
    profileImage?: string;
}

export const createChannel = async (members: IMember[], ownerId: string): Promise<IChannel> => {
    const createdChannel = await ChannelRepository.createChannel(
        {
            displayName: `Uptime: ${ownerId} Chat with ${members.map((member) => member.displayName.split('__')[0]).join(', ')}`,
            type: 'conversation',
            userIds: [...members.map((member) => member.userId), ownerId],
        }
    );

    return {
        id: createdChannel.data._id,
        displayName: members.map((member) => member.displayName).join(', '),
        lastActivity: new Date(createdChannel.data.lastActivity ?? ''),
        hasUnreadMessage: false,
        members,
    };
}

export const listUserChannels = (
    userId: string,
    setChannels: (channels: IChannel[]) => void,
    finishFetch: () => void,
): void => {
    let channels: IChannel[] = [];

    ChannelRepository.getChannels(
        { membership: 'member', limit: 20 },
        (channelRes) => {
            if (channelRes.error) return finishFetch();
            if (channelRes.data.length === 0) return finishFetch();
            for (const channel of channelRes.data) {
                ChannelRepository.Membership.getMembers(
                    { channelId: channel._id },
                    (memberRes) => {
                        if (memberRes.error) return finishFetch();
                        channels.push({
                            id: channel._id,
                            lastActivity: new Date(channel.lastActivity!),
                            displayName: memberRes.data
                                .filter((member: any) => member.userId !== userId)
                                .map((member: any) => member.user.metadata.givenName + ' ' + member.user.metadata.familyName)
                                .join(', '),
                            hasUnreadMessage: channel.subChannelsUnreadCount > 0,
                            members: memberRes.data.map((member: any) => ({
                                userId: member.userId,
                                displayName: member.user.metadata.givenName + ' ' + member.user.metadata.familyName,
                                profileImage: member.user.metadata.imageProfile?.fileUrl,
                            })),
                            profileImage: memberRes.data?.find((member: any) => member.userId !== userId)?.user?.metadata?.imageProfile?.fileUrl,
                        });
                        setChannels(channels);
                        finishFetch();
                    }
                );
            }
        }
    );
}

export const getMembers = (displayName: string, setMembers: (members: IMember[]) => void): void => {
    let members: IMember[] = [];

    UserRepository.searchUserByDisplayName(
        { displayName },
        (userRes) => {
            if (userRes.error) return;
            members = userRes.data.map((user: any) => ({
                userId: user.userId,
                displayName: user.metadata.givenName + ' ' + user.metadata.familyName,
                profileImage: user.metadata.imageProfile?.fileUrl,
            }));
            setMembers(members);
        }
    );
}

export const createTextMessage = async (newMessage: string, channelId: string) => {
    const textMessage = {
        subChannelId: channelId,
        dataType: MessageContentType.TEXT,
        data: {
            text: newMessage,
        },
    };

    const { data: message } = await MessageRepository.createMessage(textMessage);

    return message;
}

export const markAllMessageAsRead = async (channelId: string) => {
    await ChannelRepository.markAsRead(channelId);
}