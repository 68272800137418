"use client";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import AppLayout from "../../../layout/AppLayout";
import ClearableInput from "@rio-cloud/rio-uikit/ClearableInput";
import Select from "@rio-cloud/rio-uikit/Select";
import Switch from "@rio-cloud/rio-uikit/Switch";
import Popover from "@rio-cloud/rio-uikit/Popover";
import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger";
import {
  fetchScheduleList,
  fetchDeletedSchedule,
} from "../../../api/dealership/fetchScheduleList";
import { MainSchedule } from "../../../models/fleetManager/scheduleList";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../utils/loading";
import Dialog from "@rio-cloud/rio-uikit/Dialog";
import Appointment from "./appointment";
import { SchedulingDto } from "../../../dtos/dealership/schedulingDto";

const options = [
  {
    id: "1",
    label: <span>Decrescente</span>,
    selected: true,
  },
  {
    id: "2",
    label: <span>Crescente</span>,
    selected: false,
  },
];

const placements = ["left"] as const;

export const MyAppointments = () => {
  const navigate = useNavigate();
  const [scheduleList, setScheduleList] = useState<MainSchedule[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sortedList, setSortedList] = useState<MainSchedule[]>([]);
  const [selectedOption, setSelectedOption] = useState("1");
  const [selectedScheduleId, setSelectedScheduleId] = useState<string | null>(
    null
  );
  const [dialogContent, setDialogContent] = useState<
    "confirmCancel" | "reschedule" | "success"
  >("confirmCancel");
  const [appointment, setAppointment] = useState<MainSchedule | null>(null);

  const [switchStates, setSwitchStates] = useState<{
    [key: string]: boolean;
  }>({
    switch1: true,
    switch2: true,
    switch3: false,
    switch4: false,
    switch5: false,
    switch6: true,
  });

  const { switch4 } = switchStates;

  useEffect(() => {
    setLoading(true);
    const fetchAllSchedules = async () => {
      const schedules = await fetchScheduleList();
      setScheduleList(schedules);
      setLoading(false);
    };

    fetchAllSchedules();
  }, []);

  useEffect(() => {
    const sorted = [...scheduleList].sort((a, b) => {
      if (selectedOption === "1") {
        return (
          new Date(b.schedule.scheduledDate).getTime() -
          new Date(a.schedule.scheduledDate).getTime()
        );
      } else if (selectedOption === "2") {
        return (
          new Date(a.schedule.scheduledDate).getTime() -
          new Date(b.schedule.scheduledDate).getTime()
        );
      }
      return 0;
    });

    setSortedList(sorted);
  }, [scheduleList, selectedOption]);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    const months = [
      "janeiro",
      "fevereiro",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} de ${month} de ${year}`;
  };

  const extractTime = (dateTimeString: string): string => {
    const date = new Date(dateTimeString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  const handleReschedule = (id: string) => {
    navigate(`/fleet-manager/new-appointment?scheduling-id=${id}`);
  };

  const handleViewDetails = (schedule: MainSchedule) => {
    setAppointment(schedule);
  };

  const handleOpenCancelDialog = (id: string) => {
    setSelectedScheduleId(id);
    setDialogContent("confirmCancel");
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
    setSelectedScheduleId(null);
  };

  const handleConfirmCancel = async () => {
    if (!selectedScheduleId) {
      console.error("ID do agendamento não definido");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      await fetchDeletedSchedule(selectedScheduleId);
      setScheduleList((prev) =>
        prev.filter((schedule) => schedule.id !== selectedScheduleId)
      );
      setDialogContent("success");
    } catch (err) {
      console.error("Erro ao cancelar o agendamento:", err);
      setError("Falha ao cancelar o agendamento.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mySchedule">
      <AppLayout>
        {loading ? (
          <Loading label="Carregando..." />
        ) : 
          appointment ?
            <Appointment appointment={SchedulingDto(appointment)} goBack={() => setAppointment(null)}  /> :
            <div className="min-h-screen w-full flex flex-column">
              <h1 className="font-semibold">Meus agendamentos</h1>
              {sortedList.length > 0 && <div className="flex gap-6 margin-top-15 search-filter">
                <div className="form-group search-group">
                  <div className="input-group">
                    <span className="input-group-addon">
                      <span
                        className="rioglyph rioglyph-search"
                        aria-hidden="true"
                      />
                    </span>
                    <ClearableInput
                      id="5"
                      placeholder="Busque"
                      onChange={(value: string) => console.log(value)}
                    />
                  </div>
                </div>

                <div className="form-group select-group">
                  <Select
                    id="1"
                    options={options}
                    onChange={(selected) => {
                      const value =
                        selected?.id ||
                        options.find((opt) => opt.selected)?.id ||
                        "1";
                      setSelectedOption(value);
                    }}
                  />
                </div>
              </div>}

              {/* <div className="flex gap-6 margin-top-15 change-mode">
                <span>Lista</span>
                <Switch
                  onChange={() => handleChange("switch4")}
                  checked={switch4}
                  labelPosition="left"
                ></Switch>
                <span>Agenda</span>
              </div> */}

              {
                sortedList.length === 0 && (
                  <div className="flex justify-center padding-top-10">
                      <span className="text-color-gray">
                        Não existem agendamentos realizados.
                      </span>
                  </div>
                )
              }

              {sortedList.map(
                (schedule) =>
                  schedule?.schedule?.state !== "rejected" && (
                    <div
                      key={schedule?.id}
                      className="bg-white p-6 w-full margin-top-25 full-schedule"
                    >
                      <div className="flex padding-top-10 justify-between items-center mb-4">
                        <div className="flex items-center space-x-2 w-10/12">
                          <span className="text-size-18 font-semibold text-color-map-marker-asset flex items-center w-3/12">
                            <span className="rioglyph rioglyph-calendar text-color-map-marker-asset"></span>
                            {formatDate(schedule?.schedule?.scheduledDate)}
                          </span>
                          <div className="flex gap-6 schedule-tags w-9/12">
                            <div className="flex items-center justify-center gap-5 schedule-tags__card">
                              <span className="rioglyph rioglyph-time-alt"></span>
                              <span>
                                {extractTime(schedule?.schedule?.scheduledDate)}
                              </span>
                            </div>

                            <div className="flex items-center justify-center gap-5 schedule-tags__card">
                              <span className="rioglyph rioglyph-workshop"></span>
                              <span>
                                {
                                  schedule?.vehicleSchedule?.dealership
                                    ?.fantasyName
                                }
                              </span>
                            </div>

                            <div className="flex items-center justify-center gap-5 schedule-tags__card">
                              <span className="rioglyph rioglyph-pin"></span>
                              <span>
                                {schedule?.vehicleSchedule?.dealership?.city},{" "}
                                {schedule?.vehicleSchedule?.dealership?.state}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="w-2/12 flex items-center gap-6 justify-end">
                          <span className="flex items-center justify-center rounded-full payment-info">
                            Pagamento na concessionária
                          </span>
                          {placements.map((placement) => (
                            <OverlayTrigger
                              trigger="click"
                              key={placement}
                              placement={placement}
                              overlay={
                                <Popover id={`popover-positioned-${placement}`}>
                                  <div
                                    className="popover-item flex items-center cursor-pointer"
                                    onClick={() => handleReschedule(schedule?.id)}
                                  >
                                    <span className="text-color-darkest rioglyph rioglyph-calendar"></span>
                                    <span className="text-color-darkest text-size-14 font-semibold">
                                      Reagendar
                                    </span>
                                  </div>

                                  <div
                                    className="popover-item flex items-center cursor-pointer"
                                    onClick={() =>
                                      handleViewDetails(schedule)
                                    }
                                  >
                                    <span className="text-color-darkest text-size-h4 rioglyph rioglyph-eye-option"></span>
                                    <span className="text-color-darkest text-size-14 font-semibold">
                                      Ver detalhes
                                    </span>
                                  </div>

                                  <div
                                    className="popover-item flex items-center cursor-pointer"
                                    onClick={() =>
                                      handleOpenCancelDialog(schedule.id)
                                    }
                                  >
                                    <span className="text-color-darkest text-size-h4 rioglyph rioglyph-remove-circle"></span>
                                    <span className="text-color-darkest text-size-14 font-semibold">
                                      Cancelar
                                    </span>
                                  </div>
                                </Popover>
                              }
                            >
                              {/* Surrounding div is required for proper positioning of the Popover as the button uses a margin */}
                              <div>
                                <span className="rioglyph rioglyph-option-vertical cursor-pointer"></span>
                              </div>
                            </OverlayTrigger>
                          ))}{" "}
                        </div>
                      </div>

                      <hr className="border-gray-200 my-2" />

                      <div className="flex items-start justify-between mb-4 padding-top-25">
                        <div className="flex space-x-2 w-10/12">
                          <div className="w-3/12 flex flex-col">
                            <div className="flex items-center justify-center gap-6 payment-method">
                              <span className="text-size-16 font-bold text-color-map-marker-asset">
                                Valor a ser pago
                              </span>
                              <span className="text-size-14 font-bold flex items-center justify-around price-value">
                                {schedule?.vehicleSchedule?.otherServices?.total}
                              </span>
                            </div>

                            <div className="flex flex-col padding-top-25">
                              {schedule?.vehicleSchedule?.comments ? (
                                <>
                                  <span className="text-size-14 font-semibold text-color-highlight-darkest">
                                    Importante!
                                  </span>
                                  <span className="text-size-14 font-normal text-color-highlight-darkest">
                                    {schedule?.vehicleSchedule?.comments}
                                  </span>
                                </>
                              ) : null}
                            </div>
                          </div>
                          <div className="flex flex-col w-9/12">
                            <span className="text-size-14 font-semibold text-color-highlight-darkest">
                              Solicitante
                            </span>
                            <span className="text-size-14 font-normal text-color-highlight-darkest">
                              {schedule?.createdBy}
                            </span>
                          </div>
                        </div>
                        <div className="w-2/12 flex items-center justify-end">
                          <div className="flex gap-6 items-center">
                            <img
                              src="/assets/images/vehicle-thumb.png"
                              alt="Veículo"
                              className="w-16 h-16 rounded-md"
                            />
                            <div className="flex flex-col">
                              <span className="text-size-14 font-semibold text-color-highlight-darkest">
                                {schedule?.vehicleSchedule?.vehicle?.vehicleModel}
                              </span>
                              <span className="text-size-14 font-normal text-text-color-darker">
                                Chassi -{" "}
                                {schedule?.vehicleSchedule?.vehicle?.chassis}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
        }
      </AppLayout>

      <Dialog
        show={showDialog}
        onClose={handleClose}
        showCloseButton
        bsSize={Dialog.SIZE_SM}
      >
        {dialogContent === "confirmCancel" && (
          <>
            <Dialog.Title title={"Cancelar agendamento"} />
            <Dialog.Body>
              <div className="cancel-content flex flex-col">
                <span className="text-color-darker text-size-16 text-normal">
                  Você realmente gostaria de cancelar ou reagendar?
                </span>
                <span className="text-color-darker text-bold text-size-18 padding-top-10">
                  Atenção!
                </span>
                <span
                  style={{ color: "#E36E19" }}
                  className="text-size-16 padding-top-5"
                >
                  Após cancelar, essa ação não poderá ser desfeita.
                </span>
              </div>
            </Dialog.Body>
            <Dialog.Footer>
              <div className="flex items-center justify-between">
                <p
                  className="text-bold text-size-16 cursor-pointer text-color-primary"
                  onClick={handleClose}
                >
                  Voltar
                </p>
                <div className="flex gap-6">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={handleConfirmCancel}
                  >
                    Cancelar agendamento
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      handleReschedule;
                    }}
                  >
                    Reagendar
                  </button>
                </div>
              </div>
            </Dialog.Footer>
          </>
        )}

        {dialogContent === "success" && (
          <>
            <Dialog.Title title={"Agendamento Cancelado"} />
            <Dialog.Body>
              <div className="cancel-content flex flex-col">
                <span className="text-size-14 text-normal info-cancel">
                  Cancelamento realizado com sucesso
                </span>
                <span className="text-color-darker text-bold text-size-18 padding-top-10">
                  Atenção!
                </span>
                <span
                  style={{ color: "#B42318" }}
                  className="text-size-16 padding-top-5"
                >
                  Essa ação não pode ser desfeita.
                </span>
              </div>
            </Dialog.Body>
            <Dialog.Footer>
              <p
                onClick={handleClose}
                className="text-bold text-size-16 cursor-pointer text-color-primary"
              >
                Fechar
              </p>
            </Dialog.Footer>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default MyAppointments;
