import { Navigate, Route, Routes } from "react-router-dom";

import PreventiveMaintenance from "./preventiveMaintenance";
import MyAppointments from "./myAppointments";
import { NewAppointments } from "./newAppointment";
import FleetManagerMaintenanceMonitoringRoutes from "./maitenanceMonitoring/routes";

const FleetManagerRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/fleet-manager/preventive-maintenance" />} />
    <Route path="/preventive-maintenance" element={<PreventiveMaintenance />} />
    <Route path="/maintenance-monitoring/*" index element={<FleetManagerMaintenanceMonitoringRoutes />} />
    <Route path="/my-appointments" element={<MyAppointments />} />
    <Route path="/new-appointment" element={<NewAppointments />} />
  </Routes>
);

export default FleetManagerRoutes;
