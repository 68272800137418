export interface IInspection {
  number: string;
  name: string;
  validityFrom: string;
  validityUntil: string;
  classification: string;
  averagePrice: number;
  campaignYear: string;
  campaignStatus: string;
  dn: string;
  vehicle: string;
  repairDate: any;
  repairStatus: string;
}

export const mockInspections: IInspection[] = [];

const InspectionsService = {
  async getUserInspections(usernumber: string) {
    try {
      return mockInspections;
    } catch (error) {
      console.error("Error fetching user inspections:", error);
      throw error;
    }
  },
};

export default InspectionsService;
