import { useState } from "react";
import { DatePicker } from "@rio-cloud/rio-uikit";
import type { Moment } from "moment";

interface IAppointmentsProps {
    handleBackToList: () => void;
}

const Appointments = ({ handleBackToList }: IAppointmentsProps) => {

    const [newScheduling, setNewScheduling] = useState(false);

    return (
        <div className="chat-view scheduling-view flex flex-col h-full">
            <div className="chat-header flex justify-between items-center bg-black text-white px-4 py-2">
                <button
                    onClick={handleBackToList}
                    className="text-white focus:outline-none"
                >
                    <span className="rioglyph rioglyph-arrow-left text-color-primary"></span>
                </button>
                <h3 className="text-size-h4 font-bold insert-title">
                    Agendamentos
                </h3>
            </div>

            <div className="chat-body scheduling-content mt-10">
                <div className="chat-details flex items-center gap-6 px-4 py-3">
                    <img
                        src="assets/images/foto-1.png"
                        alt="Foto de Lays Silva"
                        className="w-20 h-20 rounded-full w-1/6"
                    />
                    <div className="flex flex-col w-5/6">
                        <span className="text-size-12 text-color-status-resting">
                            Enviada há 1 hora por
                        </span>
                        <span className="text-size-18 text-color-black font-medium">
                            Lays Silva
                        </span>
                        <span className="text-color-white text-size-10 bg-map-marker-asset consultor-info">
                            <span className="rioglyph rioglyph-businessman text-color-white text-size-12 padding-right-5"></span>
                            Consultora Maggi Jundiaí
                        </span>
                    </div>
                </div>

                <div className="scheduling-details">
                    <p className="text-color-secondary text-size-16 text-medium padding-0 m-0">
                        20/10/2024 às 09:00
                    </p>
                    <p className="text-color-black text-size-20 text-bold m-0">
                        Maggi Jundiaí, SP, Brasil
                    </p>
                    <p className="text-color-black text-size-16 m-0">
                        Meteor VW29.530 <span>.</span> 9536G8241LR021210
                    </p>
                </div>

                <div className="scheduling-actions flex justify-center gap-6 mt-10">
                    <button type="button" className="btn btn-default btn-lg">
                        <span className="text-capitalize">Recusar</span>
                    </button>
                    <button type="button" className="btn btn-primary btn-lg">
                        <span className="text-capitalize">Aceitar</span>
                    </button>
                </div>

                <div
                    className="new-date-suggestions flex justify-center items-center mt-10 pt-5 cursor-pointer"
                    onClick={() => setNewScheduling(true)}
                >
                    <span className="rioglyph rioglyph-calendar text-color-primary"></span>
                    <span className="text-color-primary text-size-20 text-medium">
                        Sugerir novo dia e horário
                    </span>
                </div>

                {newScheduling ? (
                    <div className="new-date-section mt-10 pt-5">
                        <p className="text-color-black text-medium text-size-14">
                            Nova sugestão de dia e horário
                        </p>
                        <div className="">
                            <div className="col-12">
                                <label
                                    htmlFor="2"
                                    className="display-flex flex-wrap justify-content-between"
                                >
                                    <span>Data</span>
                                </label>
                                <DatePicker
                                    id="2"
                                    inputProps={{ placeholder: "Selecione a data" }}
                                    dateFormat="DD-MM-YYYY"
                                    timeFormat={false}
                                    onChange={(result: Moment | string) =>
                                        console.log(result)
                                    }
                                />
                            </div>

                            <div className="col-12">
                                <label htmlFor="4">Horário</label>
                                <DatePicker
                                    inputProps={{ placeholder: "Escolha o horário", id: "4" }}
                                    dateFormat={false}
                                    alignRight
                                    onChange={(result: Moment | string) =>
                                        console.log(result)
                                    }
                                />
                            </div>
                        </div>

                        <div className="scheduling-actions flex justify-center gap-6 mt-10">
                            <button type="button" className="btn btn-default btn-lg">
                                <span className="text-capitalize">Cancelar</span>
                            </button>
                            <button type="button" className="btn btn-primary btn-lg">
                                <span className="text-capitalize">Enviar sugestão</span>
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Appointments;