import { Navigate, Route, Routes } from "react-router-dom";

import MaintenanceMonitoring from ".";
import Assessment from "./assessment";
import Report from "./report";

const DealershipMaintenanceMonitoringRoutes = () => (
	<Routes>
		<Route path="/" element={<Navigate to="/dealership/maintenance-monitoring/monitoring" />} />
		<Route path="/monitoring" element={<MaintenanceMonitoring />} />
		<Route path="/report" element={<Report />} />
		<Route path="/assessment" element={<Assessment />} />
	</Routes>
);

export default DealershipMaintenanceMonitoringRoutes;
