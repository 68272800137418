import SubNavigation from "@rio-cloud/rio-uikit/SubNavigation";
import { NavLink } from "react-router";

const Submenu = () => {
    return (
        <div className='bg-white border max-width-500'>
            <SubNavigation
                navItems={[
                    {
                        key: 'monitoring',
                        route: <NavLink to='/dealership/maintenance-monitoring/monitoring'>Acompanhamento</NavLink>,
                    },
                    {
                        key: 'report',
                        route: <NavLink to='/dealership/maintenance-monitoring/report'>Reportar</NavLink>,
                    },
                    {
                        key: 'assessment',
                        route: <NavLink to='/dealership/maintenance-monitoring/assessment'>Avaliação</NavLink>,
                    },
                ]}
            />
        </div>
    );
}

export default Submenu;