import Calendar from "./Calendar";

export interface IMonthCalendarProps {
	dayChoosed: Date;
	chooseDay: (day: Date) => void;
}

const MonthCalendar = (props: IMonthCalendarProps) => {
	return <Calendar {...props} />
};

export default MonthCalendar;