import "./styles.scss";
import { SetStateAction, useEffect, useState } from "react";

interface IIVehicleAlertsProps {
  vehicleData: any;
  closeSidebar: () => void;
}

const VehicleAlerts = ({ vehicleData, closeSidebar }: IIVehicleAlertsProps) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [expandedView, setExpandedView] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState<any>(null);

  const handleTabClick = (tab: SetStateAction<string>) => {
    setActiveTab(tab);
    setExpandedView(false);
  };

  const handleExpandClick = (alert: any) => {
    setSelectedAlert(alert);
    setExpandedView(true);
  };

  const handleBackClick = () => {
    setExpandedView(false);
    setSelectedAlert(null);
  };

  const getAlertsByCategory = (category: string) => {
    if (!vehicleData || !vehicleData.alerts) return [];
    return vehicleData.alerts.filter(
      (alert: any) => alert.category === category
    );
  };

  const getUncategorizedAlerts = () => {
    if (!vehicleData || !vehicleData.alerts) return [];
    return vehicleData.alerts.filter(
      (alert: any) =>
        !alert.category ||
        (alert.category !== "brake" &&
          alert.category !== "fluid" &&
          alert.category !== "battery" &&
          alert.category !== "components")
    );
  };

  const hasAlerts = (category: string) => {
    return getAlertsByCategory(category).length > 0;
  };

  const activeAlerts = (() => {
    switch (activeTab) {
      case "tab2":
        return getAlertsByCategory("brake");
      case "tab3":
        return getAlertsByCategory("fluid");
      case "tab4":
        return getAlertsByCategory("battery");
      case "tab1":
        return getAlertsByCategory("components");
      case "tab5":
        return getUncategorizedAlerts();
      default:
        return [];
    }
  })();

  useEffect(() => {
    if (vehicleData?.category === "brake") {
      setActiveTab("tab2");
    } else if (vehicleData?.category === "fluid") {
      setActiveTab("tab3");
    } else if (vehicleData?.category === "battery") {
      setActiveTab("tab4");
    } else if (vehicleData?.category === "components") {
      setActiveTab("tab1");
    } else {
      setActiveTab("tab5");
    }
  }, [vehicleData]);

  return (
    <div className="right-sidebar">
      <h3 className="flex gap-6 margin-bottom-20">
        <span className="rioglyph rioglyph-warning-sign"></span>
        Alertas do veículo
      </h3>
      <div
        className="close-sidebar cursor-pointer"
        onClick={() => closeSidebar()}
      >
        <span className="rioglyph rioglyph-remove"></span>
      </div>

      <div className="w-full">
        {!expandedView && (
          <div className="flex justify-center alert-tabs">
            <button
              className={`p-4 w-1/4 ${
                activeTab === "tab1" ? "active-tab" : "text-gray-500"
              }`}
              onClick={() => handleTabClick("tab1")}
            >
              <i className="rioglyph rioglyph-component-custom-recurrent"></i>
              {hasAlerts("components") && (
                <span className="tab-alert-warning"></span>
              )}
            </button>
            <button
              className={`p-4 w-1/4 ${
                activeTab === "tab2" ? "active-tab" : "text-gray-500"
              }`}
              onClick={() => handleTabClick("tab2")}
            >
              <i className="rioglyph rioglyph-brake"></i>
              {hasAlerts("brake") && (
                <span className="tab-alert-warning"></span>
              )}
            </button>
            <button
              className={`p-4 w-1/4 ${
                activeTab === "tab3" ? "active-tab" : "text-gray-500"
              }`}
              onClick={() => handleTabClick("tab3")}
            >
              <i className="rioglyph rioglyph-temperature"></i>
              {hasAlerts("fluid") && (
                <span className="tab-alert-warning"></span>
              )}
            </button>
            <button
              className={`p-4 w-1/4 ${
                activeTab === "tab4" ? "active-tab" : "text-gray-500"
              }`}
              onClick={() => handleTabClick("tab4")}
            >
              <i className="rioglyph rioglyph-battery-level-full"></i>
              {hasAlerts("battery") && (
                <span className="tab-alert-warning"></span>
              )}
            </button>
            <button
              className={`p-4 w-1/4 ${
                activeTab === "tab5" ? "active-tab" : "text-gray-500"
              }`}
              onClick={() => handleTabClick("tab5")}
            >
              <i className="rioglyph rioglyph-exclamation"></i>
              {getUncategorizedAlerts().length > 0 && (
                <span className="tab-alert-warning"></span>
              )}
            </button>
          </div>
        )}

        <div className="alertsTabs">
          {!expandedView ? (
            <div className="alert-tabs__content p-4">
              <h2 className="text-lg font-semibold mb-2">
                {activeTab === "tab1"
                  ? "Componentes"
                  : activeTab === "tab2"
                  ? "Freios"
                  : activeTab === "tab3"
                  ? "Fluído"
                  : activeTab === "tab4"
                  ? "Bateria"
                  : "Outros"}
              </h2>
              {activeAlerts.length > 0 ? (
                activeAlerts.map((alert: any, index: number) => (
                  <div
                    key={index}
                    className="callout callout-secondary flex items-center gap-6 padding-left-15 mb-4 cursor-pointer"
                    onClick={() => handleExpandClick(alert)}
                  >
                    <span
                      className="rioglyph rioglyph-warning-sign text-color-secondary text-size-20"
                      aria-hidden="true"
                    ></span>
                    <div>
                      <div className="text-color-black text-medium text-size-14 line-height-125rel margin-bottom-0">
                        {alert.sourceAddress || "Descrição não disponível"}
                      </div>
                      <p className="margin-bottom-0 margin-top-0">
                        {alert.fmiDescription || "Não disponível"}
                      </p>
                      <p className="margin-bottom-0 margin-top-0">
                        {alert.date || "Não disponível"}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p>Sem alertas no momento.</p>
              )}
            </div>
          ) : (
            <div className="expanded-view">
              <div className="flex items-center gap-4 expanded-view__header">
                <button onClick={handleBackClick} className="m-0 back-button">
                  <span className="rioglyph rioglyph-arrow-left"></span>
                </button>
                <p className="text-lg font-semibold m-0">Detalhes do Alerta</p>
              </div>
              {selectedAlert && (
                <div className="flex flex-col alert-detail">
                  <div className="flex alert-item">
                    <span>
                      {selectedAlert?.spn}/{selectedAlert?.fmi} -{" "}
                      {selectedAlert?.spnDescription}
                    </span>
                  </div>
                  <div className="flex justify-between alert-item">
                    <span>Data e hora:</span>
                    <span>{selectedAlert?.date}</span>
                  </div>
                  <div className="flex justify-between alert-item">
                    <span>Descrição:</span>
                    <span>{selectedAlert?.description}</span>
                  </div>
                  <div className="flex justify-between alert-item">
                    <span>SPN:</span>
                    <span>{selectedAlert?.spn}</span>
                  </div>
                  <div className="flex justify-between alert-item">
                    <span>FMI:</span>
                    <span>{selectedAlert?.fmi}</span>
                  </div>
                  <div className="flex justify-between alert-item">
                    <span>Status do alerta:</span>
                    <span>{selectedAlert?.lampStatus || "Não informado"}</span>
                  </div>
                  <div className="flex justify-between alert-item">
                    <span>Odômetro:</span>
                    <span>{selectedAlert?.km || "Não disponível"} km</span>
                  </div>
                  <div className="flex justify-between alert-item">
                    <span>SA:</span>
                    <span>
                      {selectedAlert?.sourceAddress || "Não informado"}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VehicleAlerts;
