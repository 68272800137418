import { Scheduling } from "../../models/dealership/schedule";

interface ICard {
    setShowDialog: (showDialog: boolean) => void;
    chooseScheduling: () => void;
    scheduling: Scheduling;
}

const Card = ({ setShowDialog, scheduling, chooseScheduling }: ICard) => {
    return (
        <div
            className="alert alert-dismissible alert-info cursor-pointer my-10"
            onClick={() => {
                chooseScheduling();
                setShowDialog(true);
            }}
        >
            <div className="display-flex gap-10">
                <div className="w-full">
                    <div className="alert-header flex justify-between">
                        <strong className="text-size-16">Revisão {scheduling.shortDescription}</strong>
                        <strong className="text-size-16">
                            {new Date(scheduling.scheduleTime).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
                        </strong>
                    </div>
                    <div className="margin-y-5 flex flex-column">
                        <span>{scheduling.asset.name}</span>
                        <span>Chassi: {scheduling.asset.chassis}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card;