import { Driver } from "../../models/dealership/driver";

export const driverDto = (data: any): Driver => {
    return {
        id: data.cnh,
        name: data.name,
        license: data.cnh,
        email: data.email,
        phone: data.phone
    };
}