import { useEffect, useState } from "react";
import classNames from "classnames";
import TableToolbar from "@rio-cloud/rio-uikit/TableToolbar";
import TableSearch from "@rio-cloud/rio-uikit/TableSearch";
import TableSettingsDialog from "@rio-cloud/rio-uikit/TableSettingsDialog";
import SortArrows from "@rio-cloud/rio-uikit/SortArrows";
import VehicleAlerts from "../vehicleAlerts";
import PeriodicReviews from "../periodicReviews";
import Inspections from "../inspections";
import { Asset } from "../../models/fleetManager/asset";
import {
  bigNumberAssetFilter,
  IAssetFilter,
} from "../../services/fleetManager/maintenanceTableService";

import CheckupInfo from "./components/checkupInfo";
import ToSchedule from "./components/toSchedule";
import "./styles.scss";
import ToScheduleDialog from "./dialogs/toScheduleDialog";
import { fetchAlerts } from "../../api/fleetManager/fetchAlerts";
import { fetchManuals } from "../../api/fleetManager/fetchManuals";
import Dialog from "@rio-cloud/rio-uikit/Dialog";
import ClearableInput from "@rio-cloud/rio-uikit/ClearableInput";
import { Loading } from "../../utils/loading";
import useEsc from "@rio-cloud/rio-uikit/useEsc";

const disabledColumns = ["vin"];

type ColumnLabel = {
  [key: string]: string;
};

type ColumnDetails = {
  [key: string]: number;
};

type ColumnDetailsMap = {
  [key: string]: ColumnDetails;
};

const defaultColumnOrder = [
  "agendamento",
  "veiculo",
  "chassi",
  "odometro",
  "horimetro",
];

const defaultColumnLabels: ColumnLabel = {
  agendamento: "Agendamento",
  veiculo: "Veículo",
  chassi: "Chassi",
  odometro: "Odômetro",
  horimetro: "Horímetro",
};

const defaultColumnsDetails: ColumnDetailsMap = {
  agendamento: {
    width: 60,
    defaultWidth: 60,
    maxWidth: 300,
  },
  veiculo: {
    width: 0,
    defaultWidth: 200,
    maxWidth: 350,
  },
  chassi: {
    width: 0,
    defaultWidth: 0,
    maxWidth: 350,
  },
  odometro: {
    width: 0,
    defaultWidth: 0,
    maxWidth: 350,
  },
  horimetro: {
    width: 0,
    defaultWidth: 0,
    maxWidth: 350,
  },
};

const secondTableColumnOrder = [
  "acoes",
  "revisoes",
  "score",
  "grupoManutencao",
  "statusGarantia",
  "rio",
  "status",
  "volksTotal",
  "ultimaRevisao",
  "proximaRevisao",
];

const secondTableColumnLabels: ColumnLabel = {
  acoes: "Campanhas de Campo",
  revisoes: "Revisões Periódicas",
  score: "Vehicle score",
  grupoManutencao: "Grupo Manutencao",
  statusGarantia: "Status Garantia",
  rio: "RIO",
  status: "Status",
  volksTotal: "Volks Total",
  ultimaRevisao: "Última Revisão",
  proximaRevisao: "Próxima Revisão",
};

const secondTableColumnsDetails: ColumnDetailsMap = {
  acoes: {
    width: 150,
    defaultWidth: 150,
    maxWidth: 150,
  },
  revisoes: {
    width: 120,
    defaultWidth: 120,
    maxWidth: 150,
  },
  score: {
    width: 100,
    defaultWidth: 100,
    maxWidth: 150,
  },
  grupoManutencao: {
    width: 130,
    defaultWidth: 130,
    maxWidth: 150,
  },
  statusGarantia: {
    width: 100,
    defaultWidth: 100,
    maxWidth: 100,
  },
  rio: {
    width: 200,
    defaultWidth: 200,
    maxWidth: 200,
  },
  status: {
    width: 80,
    defaultWidth: 80,
    maxWidth: 100,
  },
  volksTotal: {
    width: 100,
    defaultWidth: 100,
    maxWidth: 120,
  },
  ultimaRevisao: {
    width: 140,
    defaultWidth: 140,
    maxWidth: 160,
  },
  proximaRevisao: {
    width: 140,
    defaultWidth: 140,
    maxWidth: 160,
  },
};

interface ITableCommonDemoProps {
  assets: Asset[];
  assetFilter: IAssetFilter;
}

interface Alert {
  id: string;
  message: string;
}

const TableCommonDemo = ({ assets, assetFilter }: ITableCommonDemoProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [sortBy] = useState</*keyof Vehicle*/ string>("veiculo");
  const [showTableSettingsDialog, setShowTableSettingsDialog] = useState(false);
  const [columnOrder, setColumnOrder] = useState<string[]>(defaultColumnOrder);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [columnsDetails, setColumnsDetails] = useState(defaultColumnsDetails);
  const [secondTableColumns, setSecondTableColumns] = useState(
    secondTableColumnsDetails
  );
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [sidebarContent, setSidebarContent] = useState<string | null>(null);
  const [showManualModal, setShowManualModal] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState<string | null>(null);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [vehicleAlert, setVehicleAlert] = useState<any>(null);
  const [asset, setAsset] = useState<Asset | null>(null);
  const [selectedCampaigns, setSelectedCampaigns] = useState<any[]>([]);
  const [manuals, setManuals] = useState<{
    chassis?: string;
    year?: number;
    manuals?: { name: string; downloadLink: string }[];
  }>({ manuals: [] });
  const [chassisSearch, setChassisSearch] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toggled, setToggle] = useState(false);

  useEsc(() => setToggle(false));

  const preventBreakRow = {
    whiteSpace: "nowrap",
  };

  const chooseAsset = (assetId: string) => {
    setAsset(assets.find((asset) => asset.id === assetId) || null);
  };

  const showSidebar = (content: string, assetId?: string) => {
    if (assetId) chooseAsset(assetId);

    setSidebarContent(content);
    setSidebarVisible(true);
  };

  const hideSidebar = () => {
    setSidebarVisible(false);
    setSidebarContent(null);
  };

  const handleItemClick = (type: string, assetId: string) => {
    const chassis = getChassisById(assetId);

    if (!chassis) {
      console.error("Chassis not found for vehicle ID:", assetId);
      return;
    }

    if (type === "manual") {
      setLoading(true);

      fetchManuals(chassis)
        .then((manuals) => {
          setManuals(manuals);
          setShowManualModal(true);
          console.log("Manuals fetched for chassis:", chassis);
        })
        .catch((error) => {
          console.error("Error fetching manuals for chassis:", error);
          alert("Erro ao buscar manuais. Por favor, tente novamente.");
        })
        .finally(() => {
          setLoading(false);
        });
    }

    const selectedAsset = assets.find((asset) => asset.id === assetId);

    if (!selectedAsset) {
      console.error("Asset not found for ID:", assetId);
      return;
    }

    if (type === "warning-vehicle") {
      const vehicleAlerts = data.filter((alert) => alert.vehicleId === assetId);
      setVehicleAlert({ vehicleId: assetId, alerts: vehicleAlerts });
    }

    if (type === "acoes-oficina") {
      if (!selectedAsset.campaigns) {
        console.error("No campaigns found for asset ID:", assetId);
        return;
      }

      if (
        !selectedAsset.campaigns.list ||
        selectedAsset.campaigns.list.length === 0
      ) {
        console.warn(
          "Campaigns exist but the list is empty for asset ID:",
          assetId
        );
      } else {
        setSelectedCampaigns(selectedAsset.campaigns.list);
      }
    }

    const sidebarMapping: Record<string, string> = {
      "warning-vehicle": "warning-vehicle",
      "revisoes-periodicas": "revisoes-periodicas",
      "acoes-oficina": "acoes-oficina",
    };

    const newSidebarContent = sidebarMapping[type] || null;

    if (newSidebarContent) {
      if (isSidebarVisible && sidebarContent !== newSidebarContent) {
        showSidebar(newSidebarContent, assetId);
      } else if (!isSidebarVisible) {
        showSidebar(newSidebarContent, assetId);
      } else {
        hideSidebar();
      }
    } else {
      console.error("Invalid type for sidebar content:", type);
    }
  };

  const handleShowScheduleModal = (assetId: string) => {
    setSelectedAssetId(assetId);
    setShowScheduleModal(true);
  };

  const renderSidebarContent = (assetId: string) => {
    if (sidebarContent === "warning-vehicle") {
      return (
        <VehicleAlerts vehicleData={vehicleAlert} closeSidebar={hideSidebar} />
      );
    } else if (sidebarContent === "revisoes-periodicas") {
      return <PeriodicReviews asset={asset} closeSidebar={hideSidebar} />;
    } else if (sidebarContent === "acoes-oficina") {
      return (
        <Inspections
          inspectionsData={{ list: selectedCampaigns }}
          closeSidebar={hideSidebar}
        />
      );
    }
    return null;
  };

  const handleSearchValueChange = (newSearchValue: string) =>
    setSearchValue(newSearchValue);

  const handleColumnChange = (
    newColumnOrder: string[],
    newHiddenColumns: string[],
    newColumnsDetails = columnsDetails
  ) => {
    setColumnOrder(newColumnOrder);
    setHiddenColumns(newHiddenColumns);
    setColumnsDetails(newColumnsDetails);
    setSecondTableColumns(newColumnsDetails);
  };

  const handleColumnDetailsChange = (
    column: string,
    newColumnDetails: ColumnDetails
  ) => {
    const updatedColumnsDetails = { ...columnsDetails };
    updatedColumnsDetails[column] = newColumnDetails;
    setColumnsDetails(updatedColumnsDetails);
  };

  const handleSortChange = (event: React.MouseEvent<HTMLElement>) => {};

  const renderTableHead = (
    column: string,
    label: string,
    sortByColumn: string
  ) => {
    const tableHeadClassNames = classNames("user-select-none", "sort-column");

    return (
      <th
        key={column}
        className={tableHeadClassNames}
        onClick={handleSortChange}
        data-field={column}
        data-sortby={column}
        title={label}
        style={{ textAlign: column === "rio" ? "center" : undefined }}
      >
        <span>
          {sortByColumn === column ? <SortArrows /> : <SortArrows />}
          <span>{label}</span>
        </span>
      </th>
    );
  };

  const renderTableCaption = (column: string, columnDetails: ColumnDetails) => {
    const style = columnDetails?.width
      ? {
          minWidth: columnDetails.width,
          width: columnDetails.width,
        }
      : {};

    return <col key={column} style={style} />;
  };

  const columns = columnOrder.filter((name) => !hiddenColumns.includes(name));

  const tableClassNames = classNames(
    "table",
    "table-layout-fixed",
    "table-column-overflow-hidden",
    "table-bordered",
    "table-head-filled"
  );

  useEffect(() => {
    const scrollableTable = document.querySelector(".scrollabe-table");
    let isDragging = false;
    let startX = 0;
    let scrollLeft = 0;

    if (scrollableTable) {
      const onMouseDown = (e: any) => {
        isDragging = true;
        scrollableTable.classList.add("active");
        startX = e.pageX - (scrollableTable as HTMLElement).offsetLeft;
        scrollLeft = scrollableTable.scrollLeft;
      };

      const onMouseMove = (e: any) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - (scrollableTable as HTMLElement).offsetLeft;
        const walk = (x - startX) * 2;
        scrollableTable.scrollLeft = scrollLeft - walk;
      };

      const onMouseUpOrLeave = () => {
        isDragging = false;
        scrollableTable.classList.remove("active");
      };

      scrollableTable.addEventListener("mousedown", onMouseDown);
      scrollableTable.addEventListener("mousemove", onMouseMove);
      scrollableTable.addEventListener("mouseup", onMouseUpOrLeave);
      scrollableTable.addEventListener("mouseleave", onMouseUpOrLeave);

      return () => {
        scrollableTable.removeEventListener("mousedown", onMouseDown);
        scrollableTable.removeEventListener("mousemove", onMouseMove);
        scrollableTable.removeEventListener("mouseup", onMouseUpOrLeave);
        scrollableTable.removeEventListener("mouseleave", onMouseUpOrLeave);
      };
    }
  }, []);

  const isAlert = (assetId: string) => {
    if (!assetId && !data.length) return false;
    const filteredData = data?.filter((alert) => alert?.vehicleId === assetId);
    return filteredData?.length > 0 ? true : false;
  };

  useEffect(() => {
    const today = new Date();
    const startAt = new Date(today.setHours(0, 0, 0, 0)).toISOString();
    const endAt = new Date(today.setHours(23, 59, 59, 999)).toISOString();

    const bodyData = {
      pageSize: 50,
      page: 0,
      assetIds: assets?.map((asset) => asset.id),
      tagIds: [],
      lampFilter: [],
      startAt: startAt,
      endAt: endAt,
      search: "",
      zone: "UTC-3",
    };

    fetchAlerts(bodyData)
      .then((response: any) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [assets]);

  const getChassisById = (assetId: string): string | undefined => {
    const selectedAsset = assets.find((asset) => asset.id === assetId);
    return selectedAsset?.chassis;
  };

  const handleChassisSearch = () => {
    if (!chassisSearch.trim()) {
      alert("Por favor, insira um valor de chassi.");
      return;
    }

    console.log("Buscando manuais para o chassi:", chassisSearch);
    setIsLoading(true);

    fetchManuals(chassisSearch)
      .then((manuals) => {
        console.log("Manuais retornados pela API:", manuals);
        setManuals(manuals);
      })
      .catch((error) => {
        console.error("Erro ao buscar manuais:", error);
        alert("Erro ao buscar manuais. Por favor, tente novamente.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div id="TableCommonDemo">
      <TableToolbar>
        <div className="table-toolbar-container">
          <div className="table-toolbar-group-left">
            <TableSearch
              value={searchValue}
              onChange={handleSearchValueChange}
              placeholder="Busque"
            />
          </div>
          <div className="table-toolbar-group-right">
            <div className="table-toolbar-column table-toolbar-column-spacer"></div>
          </div>
          {showTableSettingsDialog && (
            <TableSettingsDialog
              show={showTableSettingsDialog}
              title="Configurações da tabela"
              onHide={() => setShowTableSettingsDialog(false)}
              onColumnChange={handleColumnChange}
              defaultColumnOrder={defaultColumnOrder}
              defaultHiddenColumns={[]}
              columnOrder={columnOrder}
              hiddenColumns={hiddenColumns}
              columnLabels={defaultColumnLabels}
              disabledColumns={disabledColumns}
              closeButtonText="Fechar"
              resetButtonText="Restaurar padrão"
              searchPlaceholder="Buscar coluna"
              notFoundMessage="Coluna não encontrada"
              columnsDetails={columnsDetails}
              autoLabel="Automático"
              onColumnDetailsChange={handleColumnDetailsChange}
              immediateChange
            />
          )}
        </div>
      </TableToolbar>
      <div className="tables-row">
        <div className="tables-row__fixed">
          <table
            className={classNames(
              "table",
              "table-layout-fixed",
              "table-bordered"
            )}
          >
            {" "}
            <colgroup>
              {columns.map((column) =>
                renderTableCaption(column, columnsDetails[column])
              )}
            </colgroup>
            <thead>
              <tr>
                {columns.map((column) =>
                  renderTableHead(
                    column,
                    defaultColumnLabels[column],
                    sortBy as string
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {assets
                .filter((item: Asset) =>
                  bigNumberAssetFilter(assetFilter, item)
                )
                .filter(
                  (item: Asset) =>
                    searchValue === "" ||
                    item.name.toLowerCase().includes(searchValue.toLowerCase())
                )
                .map((asset: Asset, assetIndex: number) => (
                  <tr key={assetIndex}>
                    {columns.map((col, colIndex) => (
                      <td
                        key={`${col}-${assetIndex}-${colIndex}`}
                        data-field={defaultColumnLabels[col]}
                        style={col !== "veiculo" ? preventBreakRow : {}}
                      >
                        {col === "agendamento" ? (
                          asset.nextCheckups[0]?.date ? (
                            <span className="rioglyph rioglyph-calendar" />
                          ) : (
                            <ToSchedule asset={asset} />
                          )
                        ) : col === "veiculo" ? (
                          asset.name || asset.model ? (
                            <div className="warning-vehicle">
                              <span className="vehicle-name" title={asset.name}>
                                {asset.name ?? asset.model}
                              </span>
                              {isAlert(asset.id) ? (
                                <button
                                  type="button"
                                  className="btn btn-secondary btn-action text-size-h6"
                                  onClick={() =>
                                    handleItemClick("warning-vehicle", asset.id)
                                  }
                                >
                                  <span
                                    className="rioglyph rioglyph-warning-sign"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              ) : null}
                            </div>
                          ) : null
                        ) : col === "chassi" ? (
                          <button
                            type="button"
                            onClick={() => handleItemClick("manual", asset.id)}
                          >
                            <span className="chassi-info">{asset.chassis}</span>
                          </button>
                        ) : col === "odometro" ? (
                          <div className="text-center">
                            {asset.odometer
                              ? asset.odometer.toLocaleString("pt-BR") + " km"
                              : "-"}
                          </div>
                        ) : col === "horimetro" ? (
                          <div className="text-center">
                            {asset.hourmeter
                              ? asset.hourmeter.toLocaleString("pt-BR")
                              : "-"}
                          </div>
                        ) : (
                          (asset[col as keyof Asset] as React.ReactNode)
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="scrollabe-table">
          <table className={tableClassNames}>
            <colgroup>
              {secondTableColumnOrder.map((column) =>
                renderTableCaption(column, secondTableColumns[column])
              )}
            </colgroup>
            <thead>
              <tr>
                {secondTableColumnOrder.map((column) =>
                  renderTableHead(
                    column,
                    secondTableColumnLabels[column],
                    sortBy
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {assets
                .filter((item: Asset) =>
                  bigNumberAssetFilter(assetFilter, item)
                )
                .filter(
                  (item: Asset) =>
                    searchValue === "" ||
                    item.name.toLowerCase().includes(searchValue.toLowerCase())
                )
                .map((asset: Asset, assetIndex: number) => (
                  <tr key={assetIndex}>
                    {secondTableColumnOrder.map((col) => (
                      <td key={col}>
                        {(() => {
                          if (col === "acoes") {
                            return asset?.campaigns &&
                              asset?.campaigns?.number > 0 ? (
                              <div
                                className="acoes-oficina cursor-pointer"
                                onClick={() =>
                                  handleItemClick("acoes-oficina", asset.id)
                                }
                              >
                                <span className="rioglyph rioglyph-calendar-today" />
                                {asset?.campaigns?.number > 0 && (
                                  <span className="alert-info">
                                    {asset?.campaigns?.number}
                                  </span>
                                )}
                              </div>
                            ) : (
                              <div className="acoes-oficina cursor-pointer">
                                <span className="rioglyph rioglyph-calendar-today" />
                              </div>
                            );
                          }
                          if (col === "revisoes") {
                            return (
                              <div className="revisoes-periodicas">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-action text-size-22"
                                  onClick={() =>
                                    handleItemClick(
                                      "revisoes-periodicas",
                                      asset.id
                                    )
                                  }
                                >
                                  <span
                                    className="rioglyph rioglyph-book"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              </div>
                            );
                          }

                          if (col === "score") {
                            return (
                              <div className="text-center">{asset.score}</div>
                            );
                          }

                          if (col === "grupoManutencao") {
                            return (
                              <div className="text-center">
                                {asset.groupName}
                              </div>
                            );
                          }

                          if (col === "status") {
                            return (
                              <div>
                                <span
                                  className={
                                    asset.status === "Online"
                                      ? "rioglyph rioglyph-wifi text-color-status-available padding-right-5"
                                      : asset.status === "Offline"
                                      ? "rioglyph rioglyph-wifi-off padding-right-5"
                                      : undefined
                                  }
                                ></span>
                                {asset.status}
                              </div>
                            );
                          }

                          if (col === "rio") {
                            return (
                              <div
                                className="rio text-center"
                                style={preventBreakRow}
                              >
                                {asset?.plans?.length > 0 ? (
                                  asset.plans
                                    ?.map((plan) => plan.name)
                                    .join(", ")
                                ) : (
                                  <a
                                    type="button"
                                    className="btn btn-primary"
                                    target="_blank"
                                    href="https://latam-marketplace.rio.cloud/customerCenter/services"
                                  >
                                    <span
                                      className="rioglyph rioglyph-shopping-cart"
                                      aria-hidden="true"
                                    ></span>
                                  </a>
                                )}
                              </div>
                            );
                          }

                          if (col === "statusGarantia") {
                            return (
                              <div className="status-garantia display-grid place-items-center">
                                <span
                                  className={
                                    asset.warrantyStatus === "Em Garantia"
                                      ? "text-color-garantia"
                                      : "text-color-expirada"
                                  }
                                >
                                  {asset.warrantyStatus}
                                </span>
                              </div>
                            );
                          }

                          if (col === "volksTotal") {
                            return (
                              <div className="volks-total text-center">
                                {asset.volksTotalPlan !== "" ? (
                                  asset.volksTotalPlan
                                ) : (
                                  <a
                                    type="button"
                                    className="btn btn-primary"
                                    target="_blank"
                                    href="https://latam-marketplace.rio.cloud/customerCenter/services"
                                  >
                                    <span
                                      className="rioglyph rioglyph-shopping-cart"
                                      aria-hidden="true"
                                    ></span>
                                  </a>
                                )}
                              </div>
                            );
                          }

                          if (col === "ultimaRevisao") {
                            return (
                              <div className="ultima-revisao">
                                {asset.lastCheckup && (
                                  <div
                                    className="display-flex flex-column"
                                    key={assetIndex}
                                  >
                                    <span className="flex items-center justify-between">
                                      {asset.lastCheckup.checkup.name}
                                      <span
                                        className={
                                          asset.lastCheckup.status ===
                                          "Realizada"
                                            ? "rioglyph-ok-circle text-color-status-available"
                                            : ""
                                        }
                                      ></span>
                                    </span>
                                    <span
                                      className={
                                        asset.lastCheckup.status === "Realizada"
                                          ? "text-color-info"
                                          : "text-color-secondary"
                                      }
                                    >
                                      {asset.lastCheckup.status}
                                    </span>
                                  </div>
                                )}
                              </div>
                            );
                          }
                          if (col === "proximaRevisao") {
                            return (
                              <div className="proxima-revisao">
                                {asset.nextCheckup && (
                                  <div
                                    className="display-flex flex-column"
                                    key={assetIndex}
                                  >
                                    <div className="flex items-center justify-between">
                                      <span>
                                        {asset.nextCheckup.checkup
                                          .description ||
                                          asset.nextCheckup.checkup.name}
                                      </span>
                                      <CheckupInfo asset={asset} />
                                    </div>
                                    <span
                                      className={
                                        asset.nextCheckup.status ===
                                        "Disponível para agendar"
                                          ? "text-color-success cursor-pointer"
                                          : "text-color-black"
                                      }
                                      onClick={
                                        asset.nextCheckup.status ===
                                        "Disponível para agendar"
                                          ? () =>
                                              handleShowScheduleModal(asset.id)
                                          : undefined
                                      }
                                    >
                                      {asset.nextCheckup.status ===
                                      "Disponível para agendar"
                                        ? "Disponível para agendar"
                                        : `Agendada para ${asset.nextCheckup.date?.toLocaleDateString(
                                            "pt-BR"
                                          )}`}
                                    </span>
                                  </div>
                                )}
                              </div>
                            );
                          }
                          return (asset as any)[col] as React.ReactNode;
                        })()}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {isSidebarVisible && (
        <div className="sidebar">{renderSidebarContent("")}</div>
      )}
      {loading ? (
        <div className="absolut-search">
          <Loading label="Carregando..." />
        </div>
      ) : (
        <Dialog
          show={showManualModal}
          title="Buscar Manuais por Chassi"
          onCloseValidation={() => {
            setShowManualModal(false);
            setManuals({});
            setChassisSearch("");
            return true;
          }}
          showCloseButton
          bsSize="md"
          body={
            <div className="w-full mx-auto bg-white rounded-lg manuals-content">
              <div className="flex items-end gap-6 border-b pb-5">
                <div className="form-group w-4/6">
                  <label htmlFor="chassi-search">Chassi do veículo*</label>
                  <div className="input-group">
                    <ClearableInput
                      id="chassi-search"
                      value={manuals.chassis || ""}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group w-2/6">
                  <label htmlFor="ano">Ano</label>
                  <div className="input-group">
                    <input
                      id="ano"
                      type="number"
                      value={manuals?.year}
                      className="form-control no-controls"
                      disabled
                    />
                  </div>
                </div>
              </div>

              {isLoading ? (
                <div className="text-center mt-4">
                  <Loading label="Carregando..." />
                </div>
              ) : manuals.manuals && manuals.manuals.length > 0 ? (
                <table className="table table-bordered mt-4">
                  <thead>
                    <tr>
                      <th>Nome do Manual</th>
                      <th className="text-center">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {manuals.manuals.map((manual, index) => (
                      <tr key={index}>
                        <td>{manual.name}</td>
                        <td className="text-center">
                          <a
                            href={manual.downloadLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-color-darker"
                          >
                            <span className="rioglyph rioglyph-cloud-download text-size-20 text-color-darker" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-center mt-4">Nenhum manual encontrado.</p>
              )}
            </div>
          }
        />
      )}

      {showScheduleModal && selectedAssetId && (
        <ToScheduleDialog
          asset={assets.find((asset) => asset.id === selectedAssetId) ?? null}
          isOpen={true}
          onClose={() => {
            setShowScheduleModal(false);
            setSelectedAssetId(null);
          }}
        />
      )}
    </div>
  );
};

export default TableCommonDemo;
