import { IInspection } from "../../services/fleetManager/inspectionsService";
import { useState } from "react";
import ScheduleModal from "./schedule-modal";

interface IInspectionProps {
  inspection: IInspection;
  setInspection: () => void;
  closeSidebar: () => void;
}

const Inspection = ({
  inspection,
  setInspection,
  closeSidebar,
}: IInspectionProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState<IInspection | null>(
    null
  );

  const handleScheduleImmediately = () => {
    setSelectedVehicle(inspection);
    console.log("selectedVehicle", selectedVehicle);
    setIsModalOpen(true);
  };

  return (
    <div className="border rounded">
      <div className="padding-left-15 padding-right-15 padding-top-15">
        <span
          className="rioglyph rioglyph-arrow-left margin-right-15 cursor-pointer"
          aria-hidden="true"
          onClick={setInspection}
        ></span>
        <span className="text-size-16">Campanhas de Campo</span>
      </div>

      <hr />

      <div className="padding-15 schedule-fixed-box">
        <div>
          <img
            src="https://assets.disaltecnologia.com.br/catalog/cover/e1c1013faa90d70ee3142d3fe94d9557.webp"
            alt="Inspection"
            className="rounded width-100pct height-150"
            style={{ objectFit: "cover" }}
          />

          <div className="padding-top-15 text-bold">Ação de Campo:</div>
          {inspection.name}

          <div className="padding-top-15 text-bold">Modelos:</div>
          {inspection.vehicle}

          <div className="padding-top-15 text-bold">Status:</div>
          {inspection.repairStatus}

          <div className="margin-top-50 width-100pct">
            <div className="text-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleScheduleImmediately}
              >
                <span className="text-capitalize">Agendar imediatamente</span>
              </button>
            </div>

            <div className="padding-top-15 text-center">
              <button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  closeSidebar();
                }}
              >
                Agendar juntamente com a revisão
              </button>
            </div>
          </div>
        </div>
      </div>

      <ScheduleModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        vehicle={selectedVehicle}
      />
    </div>
  );
};

export default Inspection;
