import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import { useState } from "react";
import ChatMessages from "../../components/chatMessages";
import Calendar from "../../components/calendar";
import Sidebar from "@rio-cloud/rio-uikit/Sidebar";
import AssetTreeSidebar from "../../components/assetsTree";

export type SidebarDemoKey = "showSidebarLeft";

export type SidebarDemosProps = Partial<Record<SidebarDemoKey, boolean>> & {
  toggleSidebar?: (id: SidebarDemoKey) => void;
  assetIds: string[];
  groupIds: string[];
  setAssetIds: (assetIds: string[]) => void;
  setGroupIds: (groupIds: string[]) => void;
};

const LeftSidebar = (props: SidebarDemosProps) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSidebarLeft, setShowSidebarLeft] = useState(false);

  const handleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const toggleSidebarLeft = () => {
    setShowSidebarLeft(!showSidebarLeft);
  };

  return (
    <ApplicationLayout.Sidebar>
      <div className="icons-col">
        <span className="rioglyph rioglyph-truck"></span>

        <div className="display-flex flex-column align-items-center icons-col__bottom">
          <ChatMessages />
          <span
            onClick={handleCalendar}
            className="rioglyph rioglyph-calendar"
          ></span>
          <span
            className={
              showSidebarLeft
                ? "rioglyph rioglyph-chevron-left"
                : "rioglyph rioglyph-chevron-right"
            }
            onClick={toggleSidebarLeft}
          ></span>
        </div>
      </div>

      {showCalendar && (
        <div className="calendar">
          <Calendar closeSidebar={() => handleCalendar()} />
        </div>
      )}

      <Sidebar
        closed={!showSidebarLeft}
        width={300}
        fly
        className="sidebar-left"
      >
        <div className="w-full">
          <AssetTreeSidebar
            assetIds={props.assetIds}
            setAssetIds={props.setAssetIds}
            groupIds={props.groupIds}
            setGroupIds={props.setGroupIds}
          />
        </div>
      </Sidebar>
    </ApplicationLayout.Sidebar>
  );
};

export default LeftSidebar;
