import { AssetGroupDto } from "../../dtos/fleetManager/assetGroupDto";
import { AssetGroup } from "../../models/fleetManager/asset";
import ApiService from "../../services/api";

export const fetchAssetGroups = async (): Promise<AssetGroup[]> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.get<any>(`/fleet/groups?account_id=`);
        if (response.status === 200) {
            return response.data.map(AssetGroupDto);
        } else return [];
    } catch (error) {
        console.error(error);
        return [];
    }
}