export const assetMockData = [
    {
        "vehicle": {
            "assetId": "08c7acfc-5b61-434e-87ce-709906aef00a",
            "chassis": "9535E6TB3SR032748",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 1896,
            "hourMeter": null,
            "vehicleGroup": "Rodoviário",
            "groups": [],
            "name": "11.180 REGULADOR SEG"
        },
        "autoWorkshopActivitiesAmount": 2,
        "vehicleScore": 87,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [
            {
                "productId": "LATAM-SKU00000078",
                "productName": "RIO Premium"
            }
        ],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Prev",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "941db21f-e4ec-45f4-b052-a8f7fd6759d5",
                "state": "Solicitado",
                "scheduledDate": "2025-01-27T14:30:00",
                "odometer": 1350,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "941db21f-e4ec-45f4-b052-a8f7fd6759d5",
                    "state": "Solicitado",
                    "scheduledDate": "2025-01-27T14:30:00",
                    "odometer": 1350,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -14.671910285949707,
            "lng": -40.48281478881836
        }
    },
    {
        "vehicle": {
            "assetId": "0ab02f41-737b-44bb-837a-3c0865a3c98e",
            "chassis": "9535E6TB1PR112587",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 228488,
            "hourMeter": 203,
            "vehicleGroup": "Misto",
            "groups": [
                "d423ac41-2223-4d5a-bbda-22ece19311d7",
                "217873f9-df96-493e-841b-d38b9a9a70c3"
            ],
            "name": "VW-544"
        },
        "autoWorkshopActivitiesAmount": 2,
        "vehicleScore": 98,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "Plus",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "81442ddc-d0f1-46b1-8d21-40c87055b4ef",
                "state": "Solicitado",
                "scheduledDate": "2025-01-08T18:30:00",
                "odometer": 227830,
                "hourmeter": 203
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "81442ddc-d0f1-46b1-8d21-40c87055b4ef",
                    "state": "Solicitado",
                    "scheduledDate": "2025-01-08T18:30:00",
                    "odometer": 227830,
                    "hourmeter": 203
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "8",
                    "desc": "Intermediária 4",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 175000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "9",
                    "desc": "L 3",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 200000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "10",
                    "desc": "Intermediária 5",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 225000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.455238342285156,
            "lng": -44.44383239746094
        }
    },
    {
        "vehicle": {
            "assetId": "1541f6e4-ebc8-4bd4-be58-de485a157ded",
            "chassis": "953658268NR010074",
            "plate": "",
            "vehicleModel": "26.280",
            "vehicleYear": null,
            "odometer": 104757,
            "hourMeter": 479,
            "vehicleGroup": "Severo",
            "groups": [
                "219acc30-74f0-4cba-bf20-0092bc214ad8"
            ],
            "name": "VW-573"
        },
        "autoWorkshopActivitiesAmount": 4,
        "vehicleScore": 97,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Max",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "e0857887-ebd5-4da6-99d3-c5313b2518de",
                "state": "Solicitado",
                "scheduledDate": "2025-01-27T17:30:00",
                "odometer": 104757,
                "hourmeter": 478
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "e0857887-ebd5-4da6-99d3-c5313b2518de",
                    "state": "Solicitado",
                    "scheduledDate": "2025-01-27T17:30:00",
                    "odometer": 104757,
                    "hourmeter": 478
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.429832458496094,
            "lng": -44.35655975341797
        }
    },
    {
        "vehicle": {
            "assetId": "179104b6-72f2-47e7-b7fe-0ca2b23f9cde",
            "chassis": "9539J8TH3RR200007",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 96384,
            "hourMeter": 1819,
            "vehicleGroup": "Especial",
            "groups": [
                "e9c11049-aa4e-49e3-b7a3-f770c103777d"
            ],
            "name": "EWF-1212"
        },
        "autoWorkshopActivitiesAmount": 5,
        "vehicleScore": 71,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "",
        "campaigns": {
            "number": 1,
            "list": [
                {
                    "number": "DVM202024",
                    "name": "Carta DVM AT 020-24 - Ação de Campo – Reposicionamento do chicote elétrico da ASM do motor D26 – Euro 6",
                    "validityFrom": "2024-08-29",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 541.53,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "28.480 6X2",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "f6cab299-8bb8-41d9-9221-160893bc0401",
                "state": "Solicitado",
                "scheduledDate": "2025-01-12T10:00:00",
                "odometer": 1213132132,
                "hourmeter": 1213132132
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "f6cab299-8bb8-41d9-9221-160893bc0401",
                    "state": "Solicitado",
                    "scheduledDate": "2025-01-12T10:00:00",
                    "odometer": 1213132132,
                    "hourmeter": 1213132132
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -23.893041610717773,
            "lng": -46.478031158447266
        }
    },
    {
        "vehicle": {
            "assetId": "17aa6c42-1e63-4341-810e-03282466b74e",
            "chassis": "9539B8TJ4PR200000",
            "plate": "",
            "vehicleModel": "29.520 6X4",
            "vehicleYear": null,
            "odometer": 170057,
            "hourMeter": 3510,
            "vehicleGroup": "Rodoviário",
            "groups": [
                "f10dd87f-157f-4bcb-a1be-e09eea247931"
            ],
            "name": "Wave 3 P25"
        },
        "autoWorkshopActivitiesAmount": 6,
        "vehicleScore": 80,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Prev",
        "campaigns": {
            "number": 1,
            "list": [
                {
                    "number": "DVM102023",
                    "name": "Carta DVM-AT-010-23 - Ação de Relacionamento - Roteirização das Mangueiras do Ar Condicionado",
                    "validityFrom": "2023-05-02",
                    "validityUntil": "2027-05-30",
                    "classification": "Ação de campo",
                    "averagePrice": 345.43,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": null,
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "361fb396-37f4-460e-be88-857dadda03ab",
                "state": "Solicitado",
                "scheduledDate": "2025-01-12T10:00:00",
                "odometer": 11212121,
                "hourmeter": 11212121
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "361fb396-37f4-460e-be88-857dadda03ab",
                    "state": "Solicitado",
                    "scheduledDate": "2025-01-12T10:00:00",
                    "odometer": 11212121,
                    "hourmeter": 11212121
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -23.510021209716797,
            "lng": -46.815399169921875
        }
    },
    {
        "vehicle": {
            "assetId": "1996719c-13b7-4ba7-9ca4-b6149d0add70",
            "chassis": "953698TKXMR120123",
            "plate": "",
            "vehicleModel": "25.460 6X2",
            "vehicleYear": null,
            "odometer": 182161,
            "hourMeter": 4910,
            "vehicleGroup": "Misto",
            "groups": [
                "0fb32f2c-3720-401e-879f-314d98e46f8f"
            ],
            "name": "VW-534"
        },
        "autoWorkshopActivitiesAmount": 1,
        "vehicleScore": 76,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "Plus",
        "campaigns": {
            "number": 7,
            "list": [
                {
                    "number": "DVM152024A",
                    "name": "Carta DVM AT 015-24 - Ação de Campo - Atual. de itens p/ operação fora de estrada - a) Coxim do radiador do líquido de arrefecimento",
                    "validityFrom": "2024-07-25",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM152024B",
                    "name": "Carta DVM AT 015-24 - Ação de Campo - Atual. de itens p/ operação fora de estrada - b) Isoladores acústico da longarina",
                    "validityFrom": "2024-07-25",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM152024C",
                    "name": "Carta DVM AT 015-24 - Ação de Campo - Atual. de itens p/ operação fora de estrada - c) Protetor de cárter e radiador",
                    "validityFrom": "2024-07-25",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM152024D",
                    "name": "Carta DVM AT 015-24 - Ação de Campo - Atual. de itens p/ operação fora de estrada - d) Tubo retorno do módulo óleo lubrificante e abraçadeira tubo primário de escape",
                    "validityFrom": "2024-07-25",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM152024E",
                    "name": "Carta DVM AT 015-24 - Ação de Campo - Atual. de itens p/ operação fora de estrada - e) Chicote elétrico do motor e chassis",
                    "validityFrom": "2024-07-25",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM182021",
                    "name": "Carta DVM-AT-018-21 - Ação de campo - Substituição dos roletes de freio - 28.460 29.520 e 33.460",
                    "validityFrom": "2021-06-15",
                    "validityUntil": "2027-06-30",
                    "classification": "Ação de campo",
                    "averagePrice": 822.08,
                    "campaignYear": "2021",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM472022",
                    "name": "Carta DVM-AT-047-22 - Ação de campo – Substituição do parafuso de fixação da coluna à caixa de direção",
                    "validityFrom": "2022-09-06",
                    "validityUntil": "2026-09-30",
                    "classification": "Ação de campo",
                    "averagePrice": 233.86,
                    "campaignYear": "2022",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "b1ddf73c-284d-41ce-b4ea-69ebfdb5e744",
                "state": "Solicitado",
                "scheduledDate": "2025-01-08T19:30:00",
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "b1ddf73c-284d-41ce-b4ea-69ebfdb5e744",
                    "state": "Solicitado",
                    "scheduledDate": "2025-01-08T19:30:00",
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "8",
                    "desc": "Intermediária 4",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 175000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.41605567932129,
            "lng": -44.306373596191406
        }
    },
    {
        "vehicle": {
            "assetId": "1dc55154-ba38-4fbd-a07b-5fa597cd8989",
            "chassis": "9535E6TB1PR029564",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 38982,
            "hourMeter": 3303,
            "vehicleGroup": "Severo",
            "groups": [
                "586b8bb2-3263-42a1-8943-29edd752f5fc"
            ],
            "name": "VW-613"
        },
        "autoWorkshopActivitiesAmount": 2,
        "vehicleScore": 91,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Max",
        "campaigns": {
            "number": 2,
            "list": [
                {
                    "number": "DVM142024",
                    "name": "Carta DVM AT 014-24 - Ação de Campo - Substituição da ECM do motor Cummins ISF 3.8L - Euro 6",
                    "validityFrom": "2024-07-15",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 830.96,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "11.180",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM342023",
                    "name": "Carta DVM-AT-034-23 - Ação de Campo – Instalação do chicote adaptador com proteção para BSG (falha ativação A/C)",
                    "validityFrom": "2023-11-21",
                    "validityUntil": "2027-11-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "11.180",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.42982292175293,
            "lng": -44.35641860961914
        }
    },
    {
        "vehicle": {
            "assetId": "25cbebae-5f44-427e-b92f-af82e13595e2",
            "chassis": "9532G82WXLR028369",
            "plate": "",
            "vehicleModel": "17.230 OD",
            "vehicleYear": null,
            "odometer": 285297,
            "hourMeter": 23043,
            "vehicleGroup": "Especial",
            "groups": [
                "9b349313-fec7-4a51-9e16-568f3cd7b750"
            ],
            "name": "17-230 OD – BEPOBUS "
        },
        "autoWorkshopActivitiesAmount": 3,
        "vehicleScore": 85,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "8",
                    "desc": "Intermediária 4",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 175000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "9",
                    "desc": "L 3",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 200000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "10",
                    "desc": "Intermediária 5",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 225000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "11",
                    "desc": "L 4",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "4",
                            "name": "Troca filtro secador",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 250000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "12",
                    "desc": "Intermediária 6",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 275000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -23.57003402709961,
            "lng": -46.42890548706055
        }
    },
    {
        "vehicle": {
            "assetId": "2f08a932-0a4c-4594-b154-4bd9b2725952",
            "chassis": "9536G8247NR004081",
            "plate": "",
            "vehicleModel": "17.230",
            "vehicleYear": null,
            "odometer": 11135,
            "hourMeter": 566,
            "vehicleGroup": "Rodoviário",
            "groups": [
                "eccb9104-b48f-4b2b-950a-74774a021c5a"
            ],
            "name": "VW-560"
        },
        "autoWorkshopActivitiesAmount": 5,
        "vehicleScore": 79,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Prev",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.42989730834961,
            "lng": -44.35657501220703
        }
    },
    {
        "vehicle": {
            "assetId": "354971bd-5e63-46e1-895d-24a9ff68e932",
            "chassis": "9539B8TJ0NR203084",
            "plate": "",
            "vehicleModel": "29.520 6X4",
            "vehicleYear": null,
            "odometer": 310020,
            "hourMeter": 6071,
            "vehicleGroup": "Misto",
            "groups": [],
            "name": "Perseu - 29.520 6X4 - NR203084"
        },
        "autoWorkshopActivitiesAmount": 6,
        "vehicleScore": 95,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "Plus",
        "campaigns": {
            "number": 1,
            "list": [
                {
                    "number": "DVM362022",
                    "name": "Carta DVM-AT-036-22 - Ação de Campo – Subst. do sensor de nível e reservatório de expansão do líquido de arrefecimento e atualiz. do software do motor",
                    "validityFrom": "2022-07-21",
                    "validityUntil": "2027-08-30",
                    "classification": "Ação de campo",
                    "averagePrice": 816.25,
                    "campaignYear": "2022",
                    "campaignStatus": "Ativo",
                    "dn": "4924",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": "2023-01-18",
                    "repairStatus": "Realizado garantia"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "8",
                    "desc": "Intermediária 4",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 175000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "9",
                    "desc": "L 3",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 200000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "10",
                    "desc": "Intermediária 5",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 225000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "11",
                    "desc": "L 4",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "4",
                            "name": "Troca filtro secador",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 250000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "12",
                    "desc": "Intermediária 6",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 275000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "13",
                    "desc": "MP1 2",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 300000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -23.475261688232422,
            "lng": -46.43319320678711
        }
    },
    {
        "vehicle": {
            "assetId": "43c1bc29-df46-4e17-bba4-5c088e2db1ed",
            "chassis": "9536C8TD9PR029366",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 57568,
            "hourMeter": 2081,
            "vehicleGroup": "Severo",
            "groups": [
                "219acc30-74f0-4cba-bf20-0092bc214ad8"
            ],
            "name": "PPF-25"
        },
        "autoWorkshopActivitiesAmount": 2,
        "vehicleScore": 89,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Max",
        "campaigns": {
            "number": 1,
            "list": [
                {
                    "number": "DVM342023",
                    "name": "Carta DVM-AT-034-23 - Ação de Campo – Instalação do chicote adaptador com proteção para BSG (falha ativação A/C)",
                    "validityFrom": "2023-11-21",
                    "validityUntil": "2027-11-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "18.320 CRM 4X2",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.45421028137207,
            "lng": -44.37663269042969
        }
    },
    {
        "vehicle": {
            "assetId": "46b6ab43-755e-4b18-829a-96064e7ff7ab",
            "chassis": "9535E6TB7SR011577",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 31948,
            "hourMeter": 502,
            "vehicleGroup": "Especial",
            "groups": [
                "f10dd87f-157f-4bcb-a1be-e09eea247931"
            ],
            "name": "11180 Bridge Plan"
        },
        "autoWorkshopActivitiesAmount": 6,
        "vehicleScore": 87,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.426549911499023,
            "lng": -44.35539627075195
        }
    },
    {
        "vehicle": {
            "assetId": "4a26aa1a-84c1-4d8e-95de-02fd755bc3c1",
            "chassis": "953563TC0NR049306",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 48848,
            "hourMeter": 1759,
            "vehicleGroup": "Rodoviário",
            "groups": [
                "f10dd87f-157f-4bcb-a1be-e09eea247931"
            ],
            "name": "NR049306 S03 - 6 ton"
        },
        "autoWorkshopActivitiesAmount": 1,
        "vehicleScore": 85,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Prev",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -21.132314682006836,
            "lng": -47.77885437011719
        }
    },
    {
        "vehicle": {
            "assetId": "4bb0b42f-f3ab-4336-a15e-c2110dfa370a",
            "chassis": "9532G82W4MR124032",
            "plate": "",
            "vehicleModel": "17.230 OD",
            "vehicleYear": null,
            "odometer": 69981,
            "hourMeter": 4551,
            "vehicleGroup": "Misto",
            "groups": [
                "e9c11049-aa4e-49e3-b7a3-f770c103777d"
            ],
            "name": "BETA-43"
        },
        "autoWorkshopActivitiesAmount": 1,
        "vehicleScore": 84,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "Plus",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -16.71521759033203,
            "lng": -49.23577880859375
        }
    },
    {
        "vehicle": {
            "assetId": "541f8d2e-a891-48d2-96fb-5e4796658ca9",
            "chassis": "9539B8TJ8MR200030",
            "plate": "",
            "vehicleModel": "29.520 6X4",
            "vehicleYear": null,
            "odometer": 259477,
            "hourMeter": 5940,
            "vehicleGroup": "Severo",
            "groups": [
                "0fb32f2c-3720-401e-879f-314d98e46f8f",
                "3d8ff90a-ba76-4709-901b-89eca29a7507"
            ],
            "name": "MN58 - P25"
        },
        "autoWorkshopActivitiesAmount": 5,
        "vehicleScore": 93,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Max",
        "campaigns": {
            "number": 4,
            "list": [
                {
                    "number": "CAT0522021",
                    "name": "Circular AT 052-21 - Ação Front Desk - Climatizador de Ar – Fornecedor Resfriar",
                    "validityFrom": "2021-08-10",
                    "validityUntil": "2026-08-30",
                    "classification": "Ação de campo",
                    "averagePrice": 43.0,
                    "campaignYear": "2021",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM182021",
                    "name": "Carta DVM-AT-018-21 - Ação de campo - Substituição dos roletes de freio - 28.460 29.520 e 33.460",
                    "validityFrom": "2021-06-15",
                    "validityUntil": "2027-06-30",
                    "classification": "Ação de campo",
                    "averagePrice": 822.08,
                    "campaignYear": "2021",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM1A2022",
                    "name": "Carta DVM-AT-001-22 - Ação de Campo – Melhoria do roteiro do chicote na região da pedaleira e atualização da etiqueta da central elétrica",
                    "validityFrom": "2022-01-12",
                    "validityUntil": "2027-01-30",
                    "classification": "Ação de campo",
                    "averagePrice": 324.46,
                    "campaignYear": "2022",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM362022",
                    "name": "Carta DVM-AT-036-22 - Ação de Campo – Subst. do sensor de nível e reservatório de expansão do líquido de arrefecimento e atualiz. do software do motor",
                    "validityFrom": "2022-07-21",
                    "validityUntil": "2027-08-30",
                    "classification": "Ação de campo",
                    "averagePrice": 816.25,
                    "campaignYear": "2022",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "8",
                    "desc": "Intermediária 4",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 175000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "9",
                    "desc": "L 3",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 200000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "10",
                    "desc": "Intermediária 5",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 225000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "11",
                    "desc": "L 4",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "4",
                            "name": "Troca filtro secador",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 250000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.429363250732422,
            "lng": -44.355899810791016
        }
    },
    {
        "vehicle": {
            "assetId": "57916136-3ad2-4409-ac16-599258b72770",
            "chassis": "9536C8TD3PR037561",
            "plate": "26.320 Q.A CTV",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 58658,
            "hourMeter": 1082,
            "vehicleGroup": "Especial",
            "groups": [
                "f10dd87f-157f-4bcb-a1be-e09eea247931"
            ],
            "name": "26.320 Q.A CTV"
        },
        "autoWorkshopActivitiesAmount": 2,
        "vehicleScore": 92,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "",
        "campaigns": {
            "number": 2,
            "list": [
                {
                    "number": "DVM242024",
                    "name": "Carta DVM AT 024-24 - Ação de Campo – Inspeção no sensor de pressão do DPF e instalação de defletores de calor",
                    "validityFrom": "2024-11-29",
                    "validityUntil": "2027-12-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "26.320 CRM 6X2",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM342023",
                    "name": "Carta DVM-AT-034-23 - Ação de Campo – Instalação do chicote adaptador com proteção para BSG (falha ativação A/C)",
                    "validityFrom": "2023-11-21",
                    "validityUntil": "2027-11-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "26.320 CRM 6X2",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.429048538208008,
            "lng": -44.358333587646484
        }
    },
    {
        "vehicle": {
            "assetId": "6197573d-459f-4ee8-bc63-29d28d91d6c4",
            "chassis": "953258S45LR018787",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 10146,
            "hourMeter": null,
            "vehicleGroup": "Rodoviário",
            "groups": [
                "7d67ce01-43ef-4d19-9da0-b413369e1c23"
            ],
            "name": "eBus-04"
        },
        "autoWorkshopActivitiesAmount": 2,
        "vehicleScore": 91,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Prev",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.4301700592041,
            "lng": -44.35601806640625
        }
    },
    {
        "vehicle": {
            "assetId": "6852f714-9335-41b5-b778-49d60ca14aba",
            "chassis": "953698TKXMR107291",
            "plate": "",
            "vehicleModel": "25.460 6X2",
            "vehicleYear": null,
            "odometer": 107995,
            "hourMeter": 2936,
            "vehicleGroup": "Misto",
            "groups": [
                "dde46c1a-353e-4216-ae03-42430ff4a860",
                "53aeea46-ede8-4d3c-b47e-1921863c8760"
            ],
            "name": "VW-533 - P23 PPF"
        },
        "autoWorkshopActivitiesAmount": 3,
        "vehicleScore": 100,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "Plus",
        "campaigns": {
            "number": 6,
            "list": [
                {
                    "number": "DVM152024A",
                    "name": "Carta DVM AT 015-24 - Ação de Campo - Atual. de itens p/ operação fora de estrada - a) Coxim do radiador do líquido de arrefecimento",
                    "validityFrom": "2024-07-25",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM152024B",
                    "name": "Carta DVM AT 015-24 - Ação de Campo - Atual. de itens p/ operação fora de estrada - b) Isoladores acústico da longarina",
                    "validityFrom": "2024-07-25",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM152024C",
                    "name": "Carta DVM AT 015-24 - Ação de Campo - Atual. de itens p/ operação fora de estrada - c) Protetor de cárter e radiador",
                    "validityFrom": "2024-07-25",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM152024D",
                    "name": "Carta DVM AT 015-24 - Ação de Campo - Atual. de itens p/ operação fora de estrada - d) Tubo retorno do módulo óleo lubrificante e abraçadeira tubo primário de escape",
                    "validityFrom": "2024-07-25",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM152024E",
                    "name": "Carta DVM AT 015-24 - Ação de Campo - Atual. de itens p/ operação fora de estrada - e) Chicote elétrico do motor e chassis",
                    "validityFrom": "2024-07-25",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM182021",
                    "name": "Carta DVM-AT-018-21 - Ação de campo - Substituição dos roletes de freio - 28.460 29.520 e 33.460",
                    "validityFrom": "2021-06-15",
                    "validityUntil": "2027-06-30",
                    "classification": "Ação de campo",
                    "averagePrice": 822.08,
                    "campaignYear": "2021",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.427749633789062,
            "lng": -44.35968017578125
        }
    },
    {
        "vehicle": {
            "assetId": "6e7e2dc3-355a-43a4-ae72-40f513f8e6c1",
            "chassis": "9535PFTEXMR100003",
            "plate": "",
            "vehicleModel": "EXPRESS",
            "vehicleYear": null,
            "odometer": 52087,
            "hourMeter": 1674,
            "vehicleGroup": "Severo",
            "groups": [
                "91250b3e-e51a-4b27-8d4a-1f8edfb13f0a"
            ],
            "name": "CTV-30"
        },
        "autoWorkshopActivitiesAmount": 5,
        "vehicleScore": 97,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Max",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.43011474609375,
            "lng": -44.35643005371094
        }
    },
    {
        "vehicle": {
            "assetId": "7a586b51-f6e3-4966-8c2a-e53126d4fedc",
            "chassis": "95355FTE5NR043796",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 93194,
            "hourMeter": 2393,
            "vehicleGroup": "Especial",
            "groups": [
                "e9c11049-aa4e-49e3-b7a3-f770c103777d"
            ],
            "name": "CTV-31"
        },
        "autoWorkshopActivitiesAmount": 3,
        "vehicleScore": 79,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -20.524803161621094,
            "lng": -47.35491180419922
        }
    },
    {
        "vehicle": {
            "assetId": "87152f48-2315-4314-a937-b38265db3f20",
            "chassis": "9536B8TD5SR011345",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 5365,
            "hourMeter": null,
            "vehicleGroup": "Rodoviário",
            "groups": [
                "91250b3e-e51a-4b27-8d4a-1f8edfb13f0a",
                "aa9b8014-0e2c-4070-b298-730884cf29fd"
            ],
            "name": "VW-651"
        },
        "autoWorkshopActivitiesAmount": 3,
        "vehicleScore": 84,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [
            {
                "productId": "LATAM-SKU00000078",
                "productName": "RIO Premium"
            }
        ],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Prev",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.48233985900879,
            "lng": -44.47319412231445
        }
    },
    {
        "vehicle": {
            "assetId": "8be134fa-7edb-433d-919e-7105ef1aebe7",
            "chassis": "9536C8TD4SR010085",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 88175,
            "hourMeter": 1599,
            "vehicleGroup": "Misto",
            "groups": [
                "d423ac41-2223-4d5a-bbda-22ece19311d7",
                "e9c11049-aa4e-49e3-b7a3-f770c103777d"
            ],
            "name": "VW-637"
        },
        "autoWorkshopActivitiesAmount": 3,
        "vehicleScore": 77,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "Plus",
        "campaigns": {
            "number": 1,
            "list": [
                {
                    "number": "DVM242024",
                    "name": "Carta DVM AT 024-24 - Ação de Campo – Inspeção no sensor de pressão do DPF e instalação de defletores de calor",
                    "validityFrom": "2024-11-29",
                    "validityUntil": "2027-12-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "26.320 CRM 6X2",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.54877281188965,
            "lng": -44.80741882324219
        }
    },
    {
        "vehicle": {
            "assetId": "91a3a581-88aa-4207-8e82-a1cd7d36ee7b",
            "chassis": "9539K8TJ7RR200026",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 62957,
            "hourMeter": 1560,
            "vehicleGroup": "Severo",
            "groups": [],
            "name": "P25 - MY24 - RR200026"
        },
        "autoWorkshopActivitiesAmount": 4,
        "vehicleScore": 75,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Max",
        "campaigns": {
            "number": 7,
            "list": [
                {
                    "number": "CAT02824IT2",
                    "name": "Circular AT 028-24 - Item 2 - Ar condicionado – Novas mangueiras e tubos de aço",
                    "validityFrom": "2024-06-28",
                    "validityUntil": "2026-08-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "4745",
                    "vehicle": "29.530 6X4",
                    "repairDate": null,
                    "repairStatus": "Realizado garantia"
                },
                {
                    "number": "CAT02824IT4",
                    "name": "Circular AT 028-24 - Item 4 - Suportes traseiros anteriores e intermediários dos para-barros",
                    "validityFrom": "2024-06-28",
                    "validityUntil": "2026-08-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "4745",
                    "vehicle": "29.530 6X4",
                    "repairDate": null,
                    "repairStatus": "Realizado garantia"
                },
                {
                    "number": "CAT05023IT10",
                    "name": "Circular AT 050-23 - Item 10 - Atualiz. suportes traseiros anteriores e intermediários dos parabarros - 6x2 e 6x4",
                    "validityFrom": "2023-10-09",
                    "validityUntil": "2026-10-31",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "4745",
                    "vehicle": "29.530 6X4",
                    "repairDate": null,
                    "repairStatus": "Realizado garantia"
                },
                {
                    "number": "DVM202024",
                    "name": "Carta DVM AT 020-24 - Ação de Campo – Reposicionamento do chicote elétrico da ASM do motor D26 – Euro 6",
                    "validityFrom": "2024-08-29",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 541.53,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "4990",
                    "vehicle": "29.530 6X4",
                    "repairDate": "2024-09-23",
                    "repairStatus": "Realizado DN"
                },
                {
                    "number": "DVM332023",
                    "name": "Carta DVM-AT-033-23 - Ação de campo – Atualização dos softwares dos módulos BSG, Painel de instrumentos, PTM e GSBC",
                    "validityFrom": "2023-11-21",
                    "validityUntil": "2027-07-31",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "4990",
                    "vehicle": "29.530 6X4",
                    "repairDate": "2024-04-05",
                    "repairStatus": "Realizado DN"
                },
                {
                    "number": "DVM342023",
                    "name": "Carta DVM-AT-034-23 - Ação de Campo – Instalação do chicote adaptador com proteção para BSG (falha ativação A/C)",
                    "validityFrom": "2023-11-21",
                    "validityUntil": "2027-11-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "4990",
                    "vehicle": "29.530 6X4",
                    "repairDate": "2024-04-05",
                    "repairStatus": "Realizado DN"
                },
                {
                    "number": "EXP0224SERV1",
                    "name": "Carta DVM AT 003-24 - Ação de Campo - Inspeção dos rolamentos interno e externo dos cubos de roda dos eixos trativos e substituição se necessário.",
                    "validityFrom": "2024-01-09",
                    "validityUntil": "2027-12-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "4990",
                    "vehicle": "29.530 6X4",
                    "repairDate": "2024-04-12",
                    "repairStatus": "Realizado DN"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -12.657032012939453,
            "lng": -45.597084045410156
        }
    },
    {
        "vehicle": {
            "assetId": "974a94e3-6aa0-4f4f-b0f3-4b93871abd46",
            "chassis": "9535E6TB2PR008769",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 65355,
            "hourMeter": 2210,
            "vehicleGroup": "Especial",
            "groups": [
                "217873f9-df96-493e-841b-d38b9a9a70c3"
            ],
            "name": "VW-605"
        },
        "autoWorkshopActivitiesAmount": 5,
        "vehicleScore": 76,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "",
        "campaigns": {
            "number": 2,
            "list": [
                {
                    "number": "DVM142024",
                    "name": "Carta DVM AT 014-24 - Ação de Campo - Substituição da ECM do motor Cummins ISF 3.8L - Euro 6",
                    "validityFrom": "2024-07-15",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 830.96,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "11.180",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM342023",
                    "name": "Carta DVM-AT-034-23 - Ação de Campo – Instalação do chicote adaptador com proteção para BSG (falha ativação A/C)",
                    "validityFrom": "2023-11-21",
                    "validityUntil": "2027-11-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "11.180",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.856840133666992,
            "lng": -47.13544845581055
        }
    },
    {
        "vehicle": {
            "assetId": "9a090b87-f50f-4d2e-9f33-f7f6fcabc071",
            "chassis": "9532G82W1MR124036",
            "plate": "",
            "vehicleModel": "17.230 OD",
            "vehicleYear": null,
            "odometer": 29095,
            "hourMeter": 1096,
            "vehicleGroup": "Rodoviário",
            "groups": [
                "e9c11049-aa4e-49e3-b7a3-f770c103777d"
            ],
            "name": "BETA-48"
        },
        "autoWorkshopActivitiesAmount": 3,
        "vehicleScore": 72,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Prev",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.425189971923828,
            "lng": -44.35928726196289
        }
    },
    {
        "vehicle": {
            "assetId": "a0d67e08-0d6c-411e-b52f-c837fd38ab45",
            "chassis": "9536B8TD4SR010784",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 36235,
            "hourMeter": 1565,
            "vehicleGroup": "Misto",
            "groups": [
                "d423ac41-2223-4d5a-bbda-22ece19311d7",
                "e9c11049-aa4e-49e3-b7a3-f770c103777d"
            ],
            "name": "VW-636"
        },
        "autoWorkshopActivitiesAmount": 6,
        "vehicleScore": 100,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "Plus",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.45754623413086,
            "lng": -44.42372131347656
        }
    },
    {
        "vehicle": {
            "assetId": "a50d4be8-d0b3-474d-abed-ad543ced542f",
            "chassis": "953557TPXNR009800",
            "plate": "",
            "vehicleModel": "e-Delivery 14",
            "vehicleYear": null,
            "odometer": 231220,
            "hourMeter": 2173,
            "vehicleGroup": "Severo",
            "groups": [
                "7d67ce01-43ef-4d19-9da0-b413369e1c23",
                "b832df8a-9275-489e-853a-873cdcd7f1dd"
            ],
            "name": "BEV-PVS1"
        },
        "autoWorkshopActivitiesAmount": 5,
        "vehicleScore": 81,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Max",
        "campaigns": {
            "number": 2,
            "list": [
                {
                    "number": "DVM00724",
                    "name": "Carta DVM AT 007-24 - Ação de Campo - Atualização do software do módulo S-Box",
                    "validityFrom": "2024-03-06",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "E_DELIVERY 14",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM562022",
                    "name": "AÇÃO ENCERRADA - Carta DVM-AT-056-22 - Ação Front Desk - e-Delivery Substituição da plaqueta de identificação",
                    "validityFrom": "2022-10-11",
                    "validityUntil": "2023-10-31",
                    "classification": "Ação de campo",
                    "averagePrice": 121.0,
                    "campaignYear": "2022",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "E_DELIVERY 14",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "8",
                    "desc": "Intermediária 4",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 175000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "9",
                    "desc": "L 3",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 200000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "10",
                    "desc": "Intermediária 5",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 225000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.429819107055664,
            "lng": -44.355873107910156
        }
    },
    {
        "vehicle": {
            "assetId": "a6d2a874-208a-46a0-b45c-ddf96ce9aac0",
            "chassis": "9539J8TH3SR203818",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 2794,
            "hourMeter": null,
            "vehicleGroup": "Especial",
            "groups": [
                "baf22bec-1195-4302-a548-5183a2f3642d"
            ],
            "name": "METEOR HD - INTERRUPTOR BASCULAMENTO"
        },
        "autoWorkshopActivitiesAmount": 3,
        "vehicleScore": 99,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.426694869995117,
            "lng": -44.35837936401367
        }
    },
    {
        "vehicle": {
            "assetId": "a8e49343-53d9-4567-b10d-b4e62342e125",
            "chassis": "9539K8TJ0PR204691",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 169917,
            "hourMeter": 3584,
            "vehicleGroup": "Rodoviário",
            "groups": [
                "2fd79f18-7bdd-41f0-8930-5efbc3264242"
            ],
            "name": "MN-74"
        },
        "autoWorkshopActivitiesAmount": 2,
        "vehicleScore": 99,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Prev",
        "campaigns": {
            "number": 4,
            "list": [
                {
                    "number": "DVM202024",
                    "name": "Carta DVM AT 020-24 - Ação de Campo – Reposicionamento do chicote elétrico da ASM do motor D26 – Euro 6",
                    "validityFrom": "2024-08-29",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 541.53,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "29.530 6X4",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM332023",
                    "name": "Carta DVM-AT-033-23 - Ação de campo – Atualização dos softwares dos módulos BSG, Painel de instrumentos, PTM e GSBC",
                    "validityFrom": "2023-11-21",
                    "validityUntil": "2027-07-31",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "29.530 6X4",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM342023",
                    "name": "Carta DVM-AT-034-23 - Ação de Campo – Instalação do chicote adaptador com proteção para BSG (falha ativação A/C)",
                    "validityFrom": "2023-11-21",
                    "validityUntil": "2027-11-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "29.530 6X4",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "EXP0224SERV1",
                    "name": "Carta DVM AT 003-24 - Ação de Campo - Inspeção dos rolamentos interno e externo dos cubos de roda dos eixos trativos e substituição se necessário.",
                    "validityFrom": "2024-01-09",
                    "validityUntil": "2027-12-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "4738",
                    "vehicle": "28.480 6X2",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.587356567382812,
            "lng": -44.883243560791016
        }
    },
    {
        "vehicle": {
            "assetId": "a9b7164b-d027-42fb-882d-a080e15eb989",
            "chassis": "9532G82W5MR120815",
            "plate": "",
            "vehicleModel": "17.230 OD",
            "vehicleYear": null,
            "odometer": 137740,
            "hourMeter": 6411,
            "vehicleGroup": "Misto",
            "groups": [
                "45ee1859-6abe-4b6b-938a-c57ef982a80a"
            ],
            "name": "BETA-47"
        },
        "autoWorkshopActivitiesAmount": 1,
        "vehicleScore": 76,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "Plus",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.430065155029297,
            "lng": -44.35602951049805
        }
    },
    {
        "vehicle": {
            "assetId": "ab5c17e5-447e-4be2-b667-7bbbd7f30fde",
            "chassis": "9539B8TJ1MR200113",
            "plate": "",
            "vehicleModel": "29.520 6X4",
            "vehicleYear": null,
            "odometer": 527028,
            "hourMeter": 8291,
            "vehicleGroup": "Severo",
            "groups": [
                "3d8ff90a-ba76-4709-901b-89eca29a7507",
                "0fb32f2c-3720-401e-879f-314d98e46f8f"
            ],
            "name": "MN57 - P25"
        },
        "autoWorkshopActivitiesAmount": 5,
        "vehicleScore": 82,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Max",
        "campaigns": {
            "number": 4,
            "list": [
                {
                    "number": "CAT0522021",
                    "name": "Circular AT 052-21 - Ação Front Desk - Climatizador de Ar – Fornecedor Resfriar",
                    "validityFrom": "2021-08-10",
                    "validityUntil": "2026-08-30",
                    "classification": "Ação de campo",
                    "averagePrice": 43.0,
                    "campaignYear": "2021",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM182021",
                    "name": "Carta DVM-AT-018-21 - Ação de campo - Substituição dos roletes de freio - 28.460 29.520 e 33.460",
                    "validityFrom": "2021-06-15",
                    "validityUntil": "2027-06-30",
                    "classification": "Ação de campo",
                    "averagePrice": 822.08,
                    "campaignYear": "2021",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM1A2022",
                    "name": "Carta DVM-AT-001-22 - Ação de Campo – Melhoria do roteiro do chicote na região da pedaleira e atualização da etiqueta da central elétrica",
                    "validityFrom": "2022-01-12",
                    "validityUntil": "2027-01-30",
                    "classification": "Ação de campo",
                    "averagePrice": 324.46,
                    "campaignYear": "2022",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM362022",
                    "name": "Carta DVM-AT-036-22 - Ação de Campo – Subst. do sensor de nível e reservatório de expansão do líquido de arrefecimento e atualiz. do software do motor",
                    "validityFrom": "2022-07-21",
                    "validityUntil": "2027-08-30",
                    "classification": "Ação de campo",
                    "averagePrice": 816.25,
                    "campaignYear": "2022",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "8",
                    "desc": "Intermediária 4",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 175000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "9",
                    "desc": "L 3",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 200000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "10",
                    "desc": "Intermediária 5",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 225000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "11",
                    "desc": "L 4",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "4",
                            "name": "Troca filtro secador",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 250000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "12",
                    "desc": "Intermediária 6",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 275000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "13",
                    "desc": "MP1 2",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 300000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "14",
                    "desc": "Intermediária 7",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 325000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "15",
                    "desc": "L 5",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "1",
                            "name": "Diferencial",
                            "oil": true
                        },
                        {
                            "id": "2",
                            "name": "Caixa ZF Traxon",
                            "oil": true
                        },
                        {
                            "id": "6",
                            "name": "Troca liquido arrefecimento",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 350000,
                        "end": null
                    },
                    "isOilCheckup": true,
                    "complsOil": [
                        {
                            "id": "1",
                            "name": "Diferencial",
                            "oil": true
                        },
                        {
                            "id": "2",
                            "name": "Caixa ZF Traxon",
                            "oil": true
                        }
                    ]
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "16",
                    "desc": "Intermediária 8",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 375000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "17",
                    "desc": "L 6",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 400000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "18",
                    "desc": "Intermediária 9",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 425000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "19",
                    "desc": "MP1 3",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 450000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "20",
                    "desc": "Intermediária 10",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 475000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "21",
                    "desc": "L 7",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "4",
                            "name": "Troca filtro secador",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 500000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "22",
                    "desc": "Intermediária 11",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 525000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [
            {
                "checkup": {
                    "id": "15",
                    "desc": "L 5",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "1",
                            "name": "Diferencial",
                            "oil": true
                        },
                        {
                            "id": "2",
                            "name": "Caixa ZF Traxon",
                            "oil": true
                        },
                        {
                            "id": "6",
                            "name": "Troca liquido arrefecimento",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 350000,
                        "end": null
                    },
                    "isOilCheckup": true,
                    "complsOil": [
                        {
                            "id": "1",
                            "name": "Diferencial",
                            "oil": true
                        },
                        {
                            "id": "2",
                            "name": "Caixa ZF Traxon",
                            "oil": true
                        }
                    ]
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.429718017578125,
            "lng": -44.356346130371094
        }
    },
    {
        "vehicle": {
            "assetId": "ac32f2a4-e924-47a2-ba9e-3ef336bc9507",
            "chassis": "953698TK7MR120757",
            "plate": "",
            "vehicleModel": "25.460 6X2",
            "vehicleYear": null,
            "odometer": 70852,
            "hourMeter": 2170,
            "vehicleGroup": "Especial",
            "groups": [
                "0fb32f2c-3720-401e-879f-314d98e46f8f"
            ],
            "name": "VW-535"
        },
        "autoWorkshopActivitiesAmount": 5,
        "vehicleScore": 85,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "",
        "campaigns": {
            "number": 7,
            "list": [
                {
                    "number": "DVM152024A",
                    "name": "Carta DVM AT 015-24 - Ação de Campo - Atual. de itens p/ operação fora de estrada - a) Coxim do radiador do líquido de arrefecimento",
                    "validityFrom": "2024-07-25",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM152024B",
                    "name": "Carta DVM AT 015-24 - Ação de Campo - Atual. de itens p/ operação fora de estrada - b) Isoladores acústico da longarina",
                    "validityFrom": "2024-07-25",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM152024C",
                    "name": "Carta DVM AT 015-24 - Ação de Campo - Atual. de itens p/ operação fora de estrada - c) Protetor de cárter e radiador",
                    "validityFrom": "2024-07-25",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM152024D",
                    "name": "Carta DVM AT 015-24 - Ação de Campo - Atual. de itens p/ operação fora de estrada - d) Tubo retorno do módulo óleo lubrificante e abraçadeira tubo primário de escape",
                    "validityFrom": "2024-07-25",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM152024E",
                    "name": "Carta DVM AT 015-24 - Ação de Campo - Atual. de itens p/ operação fora de estrada - e) Chicote elétrico do motor e chassis",
                    "validityFrom": "2024-07-25",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM182021",
                    "name": "Carta DVM-AT-018-21 - Ação de campo - Substituição dos roletes de freio - 28.460 29.520 e 33.460",
                    "validityFrom": "2021-06-15",
                    "validityUntil": "2027-06-30",
                    "classification": "Ação de campo",
                    "averagePrice": 822.08,
                    "campaignYear": "2021",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM472022",
                    "name": "Carta DVM-AT-047-22 - Ação de campo – Substituição do parafuso de fixação da coluna à caixa de direção",
                    "validityFrom": "2022-09-06",
                    "validityUntil": "2026-09-30",
                    "classification": "Ação de campo",
                    "averagePrice": 233.86,
                    "campaignYear": "2022",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 33.460 CONST",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "0c4678a2-5aaa-438a-b308-fd71ff118f30",
                "state": "Solicitado",
                "scheduledDate": "2025-01-10T08:00:00",
                "odometer": 70852,
                "hourmeter": 70852
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "0c4678a2-5aaa-438a-b308-fd71ff118f30",
                    "state": "Solicitado",
                    "scheduledDate": "2025-01-10T08:00:00",
                    "odometer": 70852,
                    "hourmeter": 70852
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.42969512939453,
            "lng": -44.35652160644531
        }
    },
    {
        "vehicle": {
            "assetId": "ad21121a-e521-4152-94e4-1807be1d55a3",
            "chassis": "9539K8TJ0PR204089",
            "plate": "RIS9F05",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 159712,
            "hourMeter": 2343,
            "vehicleGroup": "Rodoviário",
            "groups": [
                "e9c11049-aa4e-49e3-b7a3-f770c103777d"
            ],
            "name": "EWF-1213"
        },
        "autoWorkshopActivitiesAmount": 2,
        "vehicleScore": 87,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Prev",
        "campaigns": {
            "number": 3,
            "list": [
                {
                    "number": "DVM202024",
                    "name": "Carta DVM AT 020-24 - Ação de Campo – Reposicionamento do chicote elétrico da ASM do motor D26 – Euro 6",
                    "validityFrom": "2024-08-29",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 541.53,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "29.530 6X4",
                    "repairDate": "2024-09-25",
                    "repairStatus": "Realizado DN"
                },
                {
                    "number": "DVM332023",
                    "name": "Carta DVM-AT-033-23 - Ação de campo – Atualização dos softwares dos módulos BSG, Painel de instrumentos, PTM e GSBC",
                    "validityFrom": "2023-11-21",
                    "validityUntil": "2027-07-31",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "29.530 6X4",
                    "repairDate": "2024-05-29",
                    "repairStatus": "Realizado DN"
                },
                {
                    "number": "DVM342023",
                    "name": "Carta DVM-AT-034-23 - Ação de Campo – Instalação do chicote adaptador com proteção para BSG (falha ativação A/C)",
                    "validityFrom": "2023-11-21",
                    "validityUntil": "2027-11-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "29.530 6X4",
                    "repairDate": "2024-01-11",
                    "repairStatus": "Realizado DN"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.497859954833984,
            "lng": -44.04572296142578
        }
    },
    {
        "vehicle": {
            "assetId": "bf692123-bf55-4503-8164-e77f486a00e3",
            "chassis": "9532K82W9MR123683",
            "plate": "",
            "vehicleModel": "17.260 OD",
            "vehicleYear": null,
            "odometer": 52787,
            "hourMeter": 2219,
            "vehicleGroup": "Misto",
            "groups": [
                "758d8e62-737e-4686-bc0c-75cdff7cbacd"
            ],
            "name": "BETA-50"
        },
        "autoWorkshopActivitiesAmount": 6,
        "vehicleScore": 91,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [
            {
                "productId": "LATAM-SKU00000080",
                "productName": "RIO Basics"
            }
        ],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "Plus",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.429946899414062,
            "lng": -44.35627365112305
        }
    },
    {
        "vehicle": {
            "assetId": "c9f585e4-20cb-4a40-ab4d-342fb6b476a4",
            "chassis": "9535V6TB9PR112837",
            "plate": "",
            "vehicleModel": "11.180",
            "vehicleYear": null,
            "odometer": 62136,
            "hourMeter": 991,
            "vehicleGroup": "Severo",
            "groups": [
                "217873f9-df96-493e-841b-d38b9a9a70c3"
            ],
            "name": "VW-547"
        },
        "autoWorkshopActivitiesAmount": 4,
        "vehicleScore": 70,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Max",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.430091857910156,
            "lng": -44.355892181396484
        }
    },
    {
        "vehicle": {
            "assetId": "cae2bbac-466e-4c83-bdac-957217daf726",
            "chassis": "9536R8271NR014156",
            "plate": "",
            "vehicleModel": "19.360 Tractor",
            "vehicleYear": null,
            "odometer": 25995,
            "hourMeter": 166,
            "vehicleGroup": "Especial",
            "groups": [
                "287e93cb-2f82-4cff-9800-88e76500fb9c",
                "6a1002c6-581c-4351-8f78-a356c6a69f0b"
            ],
            "name": "VW-587"
        },
        "autoWorkshopActivitiesAmount": 4,
        "vehicleScore": 70,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.42989730834961,
            "lng": -44.3565788269043
        }
    },
    {
        "vehicle": {
            "assetId": "d3f958e9-e998-447b-9b13-2da3c281fa28",
            "chassis": "953658245MR100005",
            "plate": "",
            "vehicleModel": "17.280",
            "vehicleYear": null,
            "odometer": 337107,
            "hourMeter": 10209,
            "vehicleGroup": "Rodoviário",
            "groups": [],
            "name": "CUSTOMER TEST1"
        },
        "autoWorkshopActivitiesAmount": 1,
        "vehicleScore": 78,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Prev",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "8",
                    "desc": "Intermediária 4",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 175000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "9",
                    "desc": "L 3",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 200000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "10",
                    "desc": "Intermediária 5",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 225000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "11",
                    "desc": "L 4",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "4",
                            "name": "Troca filtro secador",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 250000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "12",
                    "desc": "Intermediária 6",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 275000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "13",
                    "desc": "MP1 2",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 300000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "14",
                    "desc": "Intermediária 7",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 325000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -27.225502014160156,
            "lng": -52.18874740600586
        }
    },
    {
        "vehicle": {
            "assetId": "d692aae2-fb4d-4919-a4da-09643a83a204",
            "chassis": "9539K8TJ9RR200027",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 55784,
            "hourMeter": 1486,
            "vehicleGroup": "Misto",
            "groups": [],
            "name": "P25 MY24 - RR200027"
        },
        "autoWorkshopActivitiesAmount": 6,
        "vehicleScore": 90,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "Plus",
        "campaigns": {
            "number": 5,
            "list": [
                {
                    "number": "CAT05023IT10",
                    "name": "Circular AT 050-23 - Item 10 - Atualiz. suportes traseiros anteriores e intermediários dos parabarros - 6x2 e 6x4",
                    "validityFrom": "2023-10-09",
                    "validityUntil": "2026-10-31",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "4745",
                    "vehicle": "29.530 6X4",
                    "repairDate": "2024-04-23",
                    "repairStatus": "Realizado DN"
                },
                {
                    "number": "DVM202024",
                    "name": "Carta DVM AT 020-24 - Ação de Campo – Reposicionamento do chicote elétrico da ASM do motor D26 – Euro 6",
                    "validityFrom": "2024-08-29",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 541.53,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "4990",
                    "vehicle": "29.530 6X4",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM332023",
                    "name": "Carta DVM-AT-033-23 - Ação de campo – Atualização dos softwares dos módulos BSG, Painel de instrumentos, PTM e GSBC",
                    "validityFrom": "2023-11-21",
                    "validityUntil": "2027-07-31",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "4990",
                    "vehicle": "29.530 6X4",
                    "repairDate": "2024-02-08",
                    "repairStatus": "Realizado DN"
                },
                {
                    "number": "DVM342023",
                    "name": "Carta DVM-AT-034-23 - Ação de Campo – Instalação do chicote adaptador com proteção para BSG (falha ativação A/C)",
                    "validityFrom": "2023-11-21",
                    "validityUntil": "2027-11-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "4990",
                    "vehicle": "29.530 6X4",
                    "repairDate": "2024-02-08",
                    "repairStatus": "Realizado DN"
                },
                {
                    "number": "EXP0224SERV1",
                    "name": "Carta DVM AT 003-24 - Ação de Campo - Inspeção dos rolamentos interno e externo dos cubos de roda dos eixos trativos e substituição se necessário.",
                    "validityFrom": "2024-01-09",
                    "validityUntil": "2027-12-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "4990",
                    "vehicle": "29.530 6X4",
                    "repairDate": "2024-04-19",
                    "repairStatus": "Realizado DN"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -12.655214309692383,
            "lng": -45.593177795410156
        }
    },
    {
        "vehicle": {
            "assetId": "d7c22ea7-ddd8-4fc4-86de-5e8d9c8bc97b",
            "chassis": "9536G8244NR003535",
            "plate": "",
            "vehicleModel": "17.230",
            "vehicleYear": null,
            "odometer": 196655,
            "hourMeter": 5640,
            "vehicleGroup": "Severo",
            "groups": [
                "eccb9104-b48f-4b2b-950a-74774a021c5a",
                "e9c11049-aa4e-49e3-b7a3-f770c103777d"
            ],
            "name": "VW-556"
        },
        "autoWorkshopActivitiesAmount": 5,
        "vehicleScore": 97,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Max",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "8",
                    "desc": "Intermediária 4",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 175000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.429170608520508,
            "lng": -44.35654830932617
        }
    },
    {
        "vehicle": {
            "assetId": "d7c8e277-b280-48c1-b54e-d3e4c4780127",
            "chassis": "953556TP2NR041280",
            "plate": "",
            "vehicleModel": "e-Delivery 11",
            "vehicleYear": null,
            "odometer": 119347,
            "hourMeter": 1149,
            "vehicleGroup": "Especial",
            "groups": [
                "7d67ce01-43ef-4d19-9da0-b413369e1c23"
            ],
            "name": "BEV-PVS2-ENG"
        },
        "autoWorkshopActivitiesAmount": 6,
        "vehicleScore": 91,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.42986297607422,
            "lng": -44.356483459472656
        }
    },
    {
        "vehicle": {
            "assetId": "e09f320c-f281-4727-a7bb-8ef2c0177160",
            "chassis": "9539B8TJ0NR201464",
            "plate": "",
            "vehicleModel": "29.520 6X4",
            "vehicleYear": null,
            "odometer": 53555,
            "hourMeter": 1176,
            "vehicleGroup": "Rodoviário",
            "groups": [
                "6ed35ce1-7888-4748-bfb8-5f9d173d9cbc",
                "b832df8a-9275-489e-853a-873cdcd7f1dd"
            ],
            "name": "9539B8TJ0NR201464"
        },
        "autoWorkshopActivitiesAmount": 5,
        "vehicleScore": 81,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Prev",
        "campaigns": {
            "number": 2,
            "list": [
                {
                    "number": "DVM1A2022",
                    "name": "Carta DVM-AT-001-22 - Ação de Campo – Melhoria do roteiro do chicote na região da pedaleira e atualização da etiqueta da central elétrica",
                    "validityFrom": "2022-01-12",
                    "validityUntil": "2027-01-30",
                    "classification": "Ação de campo",
                    "averagePrice": 324.46,
                    "campaignYear": "2022",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "29.520 6x4",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM362022",
                    "name": "Carta DVM-AT-036-22 - Ação de Campo – Subst. do sensor de nível e reservatório de expansão do líquido de arrefecimento e atualiz. do software do motor",
                    "validityFrom": "2022-07-21",
                    "validityUntil": "2027-08-30",
                    "classification": "Ação de campo",
                    "averagePrice": 816.25,
                    "campaignYear": "2022",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "29.520 6x4",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.427288055419922,
            "lng": -44.35839080810547
        }
    },
    {
        "vehicle": {
            "assetId": "e1a550b0-3bf8-42c3-8c1d-a421f227d057",
            "chassis": "9535PFTE4MR104595",
            "plate": "",
            "vehicleModel": "EXPRESS",
            "vehicleYear": null,
            "odometer": 107426,
            "hourMeter": 4653,
            "vehicleGroup": "Misto",
            "groups": [
                "2f86e940-b8be-435a-8530-9e9cd48fa9e2",
                "e9c11049-aa4e-49e3-b7a3-f770c103777d"
            ],
            "name": "VW-518"
        },
        "autoWorkshopActivitiesAmount": 1,
        "vehicleScore": 77,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "Plus",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -23.609556198120117,
            "lng": -46.68744659423828
        }
    },
    {
        "vehicle": {
            "assetId": "e6616edb-55f7-42a0-b6bc-f925ab4e2c9d",
            "chassis": "9536R8274NR014149",
            "plate": "",
            "vehicleModel": "19.360 Tractor",
            "vehicleYear": null,
            "odometer": 342232,
            "hourMeter": 1421,
            "vehicleGroup": "Severo",
            "groups": [
                "6a1002c6-581c-4351-8f78-a356c6a69f0b"
            ],
            "name": "VW-586"
        },
        "autoWorkshopActivitiesAmount": 5,
        "vehicleScore": 99,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Max",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "8",
                    "desc": "Intermediária 4",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 175000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "9",
                    "desc": "L 3",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 200000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "10",
                    "desc": "Intermediária 5",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 225000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "11",
                    "desc": "L 4",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "4",
                            "name": "Troca filtro secador",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 250000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "12",
                    "desc": "Intermediária 6",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 275000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "13",
                    "desc": "MP1 2",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 300000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "14",
                    "desc": "Intermediária 7",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 325000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.585439682006836,
            "lng": -44.881126403808594
        }
    },
    {
        "vehicle": {
            "assetId": "eb07be68-d031-4a7f-9e0c-903403158422",
            "chassis": "9539K8TJ0RR200028",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 94061,
            "hourMeter": 2077,
            "vehicleGroup": "Especial",
            "groups": [],
            "name": "P25 MY24 - RR200028"
        },
        "autoWorkshopActivitiesAmount": 6,
        "vehicleScore": 85,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "",
        "campaigns": {
            "number": 6,
            "list": [
                {
                    "number": "CAT02824IT1A",
                    "name": "Circular AT 028-24 - Item 1A - Captação de ar do motor",
                    "validityFrom": "2024-06-28",
                    "validityUntil": "2026-08-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "4705",
                    "vehicle": "29.530 6X4",
                    "repairDate": null,
                    "repairStatus": "Realizado garantia"
                },
                {
                    "number": "CAT02824IT2",
                    "name": "Circular AT 028-24 - Item 2 - Ar condicionado – Novas mangueiras e tubos de aço",
                    "validityFrom": "2024-06-28",
                    "validityUntil": "2026-08-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "4705",
                    "vehicle": "29.530 6X4",
                    "repairDate": null,
                    "repairStatus": "Realizado garantia"
                },
                {
                    "number": "DVM202024",
                    "name": "Carta DVM AT 020-24 - Ação de Campo – Reposicionamento do chicote elétrico da ASM do motor D26 – Euro 6",
                    "validityFrom": "2024-08-29",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 541.53,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "4705",
                    "vehicle": "29.530 6X4",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM332023",
                    "name": "Carta DVM-AT-033-23 - Ação de campo – Atualização dos softwares dos módulos BSG, Painel de instrumentos, PTM e GSBC",
                    "validityFrom": "2023-11-21",
                    "validityUntil": "2027-07-31",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "4705",
                    "vehicle": "29.530 6X4",
                    "repairDate": "2024-04-25",
                    "repairStatus": "Realizado DN"
                },
                {
                    "number": "DVM342023",
                    "name": "Carta DVM-AT-034-23 - Ação de Campo – Instalação do chicote adaptador com proteção para BSG (falha ativação A/C)",
                    "validityFrom": "2023-11-21",
                    "validityUntil": "2027-11-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "4705",
                    "vehicle": "29.530 6X4",
                    "repairDate": "2024-04-25",
                    "repairStatus": "Realizado DN"
                },
                {
                    "number": "EXP0224SERV1",
                    "name": "Carta DVM AT 003-24 - Ação de Campo - Inspeção dos rolamentos interno e externo dos cubos de roda dos eixos trativos e substituição se necessário.",
                    "validityFrom": "2024-01-09",
                    "validityUntil": "2027-12-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "4705",
                    "vehicle": "29.530 6X4",
                    "repairDate": "2024-04-25",
                    "repairStatus": "Realizado DN"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -3.244976282119751,
            "lng": -60.470619201660156
        }
    },
    {
        "vehicle": {
            "assetId": "ebb9b54a-dd25-4ec9-8d77-2b91b02fe5a6",
            "chassis": "9539B8TJ2MR200086",
            "plate": "",
            "vehicleModel": "29.520 6X4",
            "vehicleYear": null,
            "odometer": 191506,
            "hourMeter": 4452,
            "vehicleGroup": "Rodoviário",
            "groups": [
                "0fb32f2c-3720-401e-879f-314d98e46f8f"
            ],
            "name": "MN59 - P25"
        },
        "autoWorkshopActivitiesAmount": 6,
        "vehicleScore": 99,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Prev",
        "campaigns": {
            "number": 4,
            "list": [
                {
                    "number": "CAT0522021",
                    "name": "Circular AT 052-21 - Ação Front Desk - Climatizador de Ar – Fornecedor Resfriar",
                    "validityFrom": "2021-08-10",
                    "validityUntil": "2026-08-30",
                    "classification": "Ação de campo",
                    "averagePrice": 43.0,
                    "campaignYear": "2021",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM182021",
                    "name": "Carta DVM-AT-018-21 - Ação de campo - Substituição dos roletes de freio - 28.460 29.520 e 33.460",
                    "validityFrom": "2021-06-15",
                    "validityUntil": "2027-06-30",
                    "classification": "Ação de campo",
                    "averagePrice": 822.08,
                    "campaignYear": "2021",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM1A2022",
                    "name": "Carta DVM-AT-001-22 - Ação de Campo – Melhoria do roteiro do chicote na região da pedaleira e atualização da etiqueta da central elétrica",
                    "validityFrom": "2022-01-12",
                    "validityUntil": "2027-01-30",
                    "classification": "Ação de campo",
                    "averagePrice": 324.46,
                    "campaignYear": "2022",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM362022",
                    "name": "Carta DVM-AT-036-22 - Ação de Campo – Subst. do sensor de nível e reservatório de expansão do líquido de arrefecimento e atualiz. do software do motor",
                    "validityFrom": "2022-07-21",
                    "validityUntil": "2027-08-30",
                    "classification": "Ação de campo",
                    "averagePrice": 816.25,
                    "campaignYear": "2022",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "8",
                    "desc": "Intermediária 4",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 175000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.429767608642578,
            "lng": -44.35643768310547
        }
    },
    {
        "vehicle": {
            "assetId": "ef5ae1e3-28e9-4553-aaf8-8f218875c599",
            "chassis": "9532E82W9MR123979",
            "plate": "",
            "vehicleModel": "15.190 OD",
            "vehicleYear": null,
            "odometer": 8323,
            "hourMeter": 376,
            "vehicleGroup": "Misto",
            "groups": [
                "45ee1859-6abe-4b6b-938a-c57ef982a80a"
            ],
            "name": "BUS-01"
        },
        "autoWorkshopActivitiesAmount": 3,
        "vehicleScore": 79,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "Plus",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -23.517711639404297,
            "lng": -46.56252670288086
        }
    },
    {
        "vehicle": {
            "assetId": "efbc7999-e17a-4dd7-9410-56ae7bb30b64",
            "chassis": "9539B8TJ4MR100006",
            "plate": "",
            "vehicleModel": "29.520 6X4",
            "vehicleYear": null,
            "odometer": 226490,
            "hourMeter": 4800,
            "vehicleGroup": "Severo",
            "groups": [
                "b34e4136-6ff8-4aae-90e1-9860865bfe98"
            ],
            "name": "MN62 - P25"
        },
        "autoWorkshopActivitiesAmount": 5,
        "vehicleScore": 71,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Max",
        "campaigns": {
            "number": 3,
            "list": [
                {
                    "number": "CAT0522021",
                    "name": "Circular AT 052-21 - Ação Front Desk - Climatizador de Ar – Fornecedor Resfriar",
                    "validityFrom": "2021-08-10",
                    "validityUntil": "2026-08-30",
                    "classification": "Ação de campo",
                    "averagePrice": 43.0,
                    "campaignYear": "2021",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM182021",
                    "name": "Carta DVM-AT-018-21 - Ação de campo - Substituição dos roletes de freio - 28.460 29.520 e 33.460",
                    "validityFrom": "2021-06-15",
                    "validityUntil": "2027-06-30",
                    "classification": "Ação de campo",
                    "averagePrice": 822.08,
                    "campaignYear": "2021",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM362022",
                    "name": "Carta DVM-AT-036-22 - Ação de Campo – Subst. do sensor de nível e reservatório de expansão do líquido de arrefecimento e atualiz. do software do motor",
                    "validityFrom": "2022-07-21",
                    "validityUntil": "2027-08-30",
                    "classification": "Ação de campo",
                    "averagePrice": 816.25,
                    "campaignYear": "2022",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "VW 29.520 METEOR",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "8",
                    "desc": "Intermediária 4",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 175000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "9",
                    "desc": "L 3",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 200000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "10",
                    "desc": "Intermediária 5",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 225000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.430557250976562,
            "lng": -44.357120513916016
        }
    },
    {
        "vehicle": {
            "assetId": "f56420da-3494-4af9-850a-a4d9b07ed0f2",
            "chassis": "953556EL9LR999288",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 69002,
            "hourMeter": null,
            "vehicleGroup": "Especial",
            "groups": [
                "7d67ce01-43ef-4d19-9da0-b413369e1c23"
            ],
            "name": "BEV-13"
        },
        "autoWorkshopActivitiesAmount": 4,
        "vehicleScore": 87,
        "warrantyStatus": "Expirado",
        "vehiclePlan": [],
        "status": "Offline",
        "checkupStatus": "",
        "volksTotal": "",
        "campaigns": {
            "number": 0,
            "list": []
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -22.43027114868164,
            "lng": -44.3557243347168
        }
    },
    {
        "vehicle": {
            "assetId": "fe77501b-7c60-4ee6-86ec-4607a8b5985b",
            "chassis": "9539K8TJ3PR200795",
            "plate": "",
            "vehicleModel": "",
            "vehicleYear": null,
            "odometer": 249694,
            "hourMeter": 5199,
            "vehicleGroup": "Rodoviário",
            "groups": [
                "0fb32f2c-3720-401e-879f-314d98e46f8f",
                "e9c11049-aa4e-49e3-b7a3-f770c103777d"
            ],
            "name": "PVS-19 - P25"
        },
        "autoWorkshopActivitiesAmount": 2,
        "vehicleScore": 92,
        "warrantyStatus": "Em Garantia",
        "vehiclePlan": [],
        "status": "Online",
        "checkupStatus": "",
        "volksTotal": "Prev",
        "campaigns": {
            "number": 2,
            "list": [
                {
                    "number": "DVM202024",
                    "name": "Carta DVM AT 020-24 - Ação de Campo – Reposicionamento do chicote elétrico da ASM do motor D26 – Euro 6",
                    "validityFrom": "2024-08-29",
                    "validityUntil": "2027-07-30",
                    "classification": "Ação de campo",
                    "averagePrice": 541.53,
                    "campaignYear": "2024",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "29.530 6X4",
                    "repairDate": null,
                    "repairStatus": "Pendente"
                },
                {
                    "number": "DVM342023",
                    "name": "Carta DVM-AT-034-23 - Ação de Campo – Instalação do chicote adaptador com proteção para BSG (falha ativação A/C)",
                    "validityFrom": "2023-11-21",
                    "validityUntil": "2027-11-30",
                    "classification": "Ação de campo",
                    "averagePrice": 600.0,
                    "campaignYear": "2023",
                    "campaignStatus": "Ativo",
                    "dn": "9999",
                    "vehicle": "29.530 6X4",
                    "repairDate": "2024-02-27",
                    "repairStatus": "Realizado DN"
                }
            ]
        },
        "metricType": "1",
        "lastVehicleCheckup": null,
        "nextVehicleCheckup": {
            "checkup": {
                "id": "1",
                "desc": "Assentamento 1",
                "checkupType": {
                    "id": "1",
                    "name": "Assentamento",
                    "services": []
                },
                "compls": [],
                "range": {
                    "start": 1000,
                    "end": 5000
                },
                "isOilCheckup": false,
                "complsOil": []
            },
            "schedule": {
                "id": "null",
                "state": "Disponível para agendar",
                "scheduledDate": null,
                "odometer": 0,
                "hourmeter": 0
            }
        },
        "madeCheckups": [],
        "nextCheckups": [
            {
                "checkup": {
                    "id": "1",
                    "desc": "Assentamento 1",
                    "checkupType": {
                        "id": "1",
                        "name": "Assentamento",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 1000,
                        "end": 5000
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "2",
                    "desc": "Intermediária 1",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 25000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "3",
                    "desc": "L 1",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 50000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "4",
                    "desc": "Intermediária 2",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 75000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "5",
                    "desc": "L 2",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 100000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "6",
                    "desc": "Intermediária 3",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 125000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "7",
                    "desc": "MP1 1",
                    "checkupType": {
                        "id": "4",
                        "name": "MP1",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "11",
                            "name": "Cubos de rodas - 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 150000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "8",
                    "desc": "Intermediária 4",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 175000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "9",
                    "desc": "L 3",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "3",
                            "name": "Regulagem de válvulas",
                            "oil": false
                        },
                        {
                            "id": "5",
                            "name": "Substituição do filtro \"Blow By\" do motor",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        },
                        {
                            "id": "8",
                            "name": "Troca do filtro de Arla 32",
                            "oil": false
                        },
                        {
                            "id": "9",
                            "name": "Inspeção e limpeza dos respiros dos diferenciais",
                            "oil": false
                        },
                        {
                            "id": "10",
                            "name": "Revisão do 3° eixo (veículo 6x2)",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 200000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "10",
                    "desc": "Intermediária 5",
                    "checkupType": {
                        "id": "2",
                        "name": "Intermediária",
                        "services": []
                    },
                    "compls": [],
                    "range": {
                        "start": 225000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            },
            {
                "checkup": {
                    "id": "11",
                    "desc": "L 4",
                    "checkupType": {
                        "id": "3",
                        "name": "L",
                        "services": []
                    },
                    "compls": [
                        {
                            "id": "4",
                            "name": "Troca filtro secador",
                            "oil": false
                        },
                        {
                            "id": "7",
                            "name": "Troca do filtro de ar da cabine",
                            "oil": false
                        }
                    ],
                    "range": {
                        "start": 250000,
                        "end": null
                    },
                    "isOilCheckup": false,
                    "complsOil": []
                },
                "scheduleMaintenanceInfo": {
                    "id": "null",
                    "state": "Disponível para agendar",
                    "scheduledDate": null,
                    "odometer": 0,
                    "hourmeter": 0
                }
            }
        ],
        "nextOilCheckups": [],
        "madeOilCheckups": [],
        "geoLocation": {
            "lat": -20.495386123657227,
            "lng": -54.55659103393555
        }
    }
];

export const driverMockData = [
    {
        "id": "05674287-1f34-45a6-8c5a-b5a2811272fe",
        "displayName": "filipe oliveira",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "filipe",
        "lastName": "oliveira",
        "email": "teste@email.com",
        "phoneNumber": "+5518991373128",
        "driverLicence": ""
    },
    {
        "id": "08992d3d-9325-44fc-b2d5-c6e10984da06",
        "displayName": "Fernanda Hara",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Fernanda",
        "lastName": "Hara",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "08c5ab6d-b09e-4191-98f3-c81958266240",
        "displayName": "Fernando2 Soares",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Fernando2",
        "lastName": "Soares",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "09414bfa-0280-4cda-8c33-4186af517962",
        "displayName": "error archive",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "error",
        "lastName": "archive",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "0a8a331f-de53-4e66-bf4d-ff66aca6aaed",
        "displayName": "lucas  oliveira",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "lucas ",
        "lastName": "oliveira ",
        "email": "lucas.oliveira3@opah.com.br",
        "phoneNumber": "+5518991650990",
        "driverLicence": ""
    },
    {
        "id": "0cdc38c0-5815-4fc3-91d1-3ee099ffac5c",
        "displayName": "Guilherme Lopes",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Guilherme",
        "lastName": "Lopes",
        "email": "guilhermxlopes@gmail.com",
        "phoneNumber": "+5547996034004",
        "driverLicence": ""
    },
    {
        "id": "0d60281a-13be-4528-8819-1e1e9fb0fc8b",
        "displayName": "Fernanda Versão 3.6",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Fernanda",
        "lastName": "Versão 3.6",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "0e8f4b2f-7e39-4284-94f7-4783affb72ac",
        "displayName": "Carlao Novembro",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Carlao",
        "lastName": "Novembro",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "102d1977-9816-4355-b72a-cbbeeebb3b38",
        "displayName": "error archive",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "error",
        "lastName": "archive",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "1aa79b91-941c-4e1e-824b-e97c540a4225",
        "displayName": "Vinicius Okamoto",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Vinicius",
        "lastName": "Okamoto",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "2c5cd6c8-e070-45bc-bba7-30dff2f06e02",
        "displayName": "Fernando MFH",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Fernando",
        "lastName": "MFH",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "2d059d3a-d0bc-43f8-82ec-1f59f2be3602",
        "displayName": "206014617 Edson Antonio Simao",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "206014617",
        "lastName": "Edson Antonio Simao",
        "email": "gabsiqmshaosifwbzf@ytnhy.com",
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "2fbd2840-171d-4c72-8c23-cc70115e40bb",
        "displayName": "Henrique Souza",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Henrique",
        "lastName": "Souza",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "369b05ac-82d0-4f3a-a6ce-f75043f91daa",
        "displayName": "206014473 Anderson Luiz Custodio",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "206014473",
        "lastName": "Anderson Luiz Custodio",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "398f2cad-fd8c-4a76-a239-f2ce8c595b4c",
        "displayName": "206040013 Joel Goncalves da Rocha",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "206040013",
        "lastName": "Joel Goncalves da Rocha",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "3c96dd50-31ee-46e2-bf7e-88f725066992",
        "displayName": "Fernada  Pedrita",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Fernada ",
        "lastName": "Pedrita",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "3e305b5c-09cc-45ee-8113-c574f96740d9",
        "displayName": "Maycon Ramos",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Maycon",
        "lastName": "Ramos",
        "email": "maycon.ramos@atos.net",
        "phoneNumber": "+5515997328049",
        "driverLicence": ""
    },
    {
        "id": "46ae4eab-37a3-4050-843d-ee77c4fa9f26",
        "displayName": "Fernando Soares",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Fernando",
        "lastName": "Soares",
        "email": "fernando.soares1@volkswagen.com.br",
        "phoneNumber": "+5511996859728",
        "driverLicence": ""
    },
    {
        "id": "4a8a854b-bfeb-47d7-81c8-b1c68fa1e2c7",
        "displayName": "Victor Paschoalato",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Victor",
        "lastName": "Paschoalato",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "4c6d4698-9bf4-41ba-89e0-784c6c210056",
        "displayName": "Rodrigo Gregori",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Rodrigo",
        "lastName": "Gregori",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "509749a2-c12d-42a8-8797-e5b337a43899",
        "displayName": "Joelcio Silveira",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Joelcio",
        "lastName": "Silveira",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "53d2160c-694e-4220-803a-ac4792c8e7df",
        "displayName": "Renato Vertuam",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Renato",
        "lastName": "Vertuam",
        "email": "vertuam@gmail.com",
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "58b4b1a1-1b64-42e7-964f-1a1e8d659f48",
        "displayName": "Marlon Pedro Fauser",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Marlon Pedro",
        "lastName": "Fauser",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "629fa557-115e-4cb6-887e-19b38999c783",
        "displayName": "206035304 Eliseu Casagrande",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "206035304",
        "lastName": "Eliseu Casagrande",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "68b913a2-3bee-44e7-9010-c29cb6ed9102",
        "displayName": "João Santos",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "João",
        "lastName": "Santos",
        "email": "johnorgal@hotmail.com",
        "phoneNumber": "+5512982126036",
        "driverLicence": ""
    },
    {
        "id": "69bb19f2-75d8-4faf-b3df-a3596b90e6d2",
        "displayName": "Roberto  silva",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Roberto ",
        "lastName": "silva",
        "email": null,
        "phoneNumber": "+5519996584618",
        "driverLicence": ""
    },
    {
        "id": "71871ec1-d80d-409f-8870-aec449601858",
        "displayName": "Carlos Sardella",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Carlos",
        "lastName": "Sardella",
        "email": "extern.carlos.sardella1@volkswagen.com.br",
        "phoneNumber": "+24999065880",
        "driverLicence": ""
    },
    {
        "id": "7253b165-f8be-493e-b538-e7aece7f8da7",
        "displayName": "Fernanda Hara",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Fernanda",
        "lastName": "Hara",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "73ddb1c1-f102-4886-9839-f9e8021caf3a",
        "displayName": "error archive",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "error",
        "lastName": "archive",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "771e9555-f5a2-4417-8ece-2794868d9866",
        "displayName": "Andre Pedro bt0.3.0",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Andre",
        "lastName": "Pedro bt0.3.0",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "88d86ca8-5f40-46b2-b1bc-c1370830e108",
        "displayName": "Carlos Matheus",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Carlos",
        "lastName": "Matheus",
        "email": "autenticacao.mnbr.r.jab@volkswagen.com.br",
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "8b015949-7c8a-4bf6-b787-d8734244ea1d",
        "displayName": "Marlon teste1 Fauser",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Marlon teste1",
        "lastName": "Fauser",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "8cef67fe-9b05-4d8b-8a4a-c290f5ae9d32",
        "displayName": "Carlos Sardella",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Carlos",
        "lastName": "Sardella",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "8d13f399-13d8-42e7-af43-c75c19758233",
        "displayName": "206028575 Anisio Teixeira de Morais",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "206028575",
        "lastName": "Anisio Teixeira de Morais",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "8f230185-09ba-449c-a005-c4f90cf1eddc",
        "displayName": "Lucas Jordão",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Lucas",
        "lastName": "Jordão",
        "email": "lucas.jordao@opah.com.br",
        "phoneNumber": "+5583986679667",
        "driverLicence": ""
    },
    {
        "id": "912b902d-f3b0-46e6-887b-4415b15d6664",
        "displayName": "Renata ávila",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Renata",
        "lastName": "ávila",
        "email": "renata.avila@opah.com.br",
        "phoneNumber": "+5511991123521",
        "driverLicence": ""
    },
    {
        "id": "94566774-3f03-49c6-872f-69cf92cbb5d5",
        "displayName": "Joao Almeida",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Joao",
        "lastName": "Almeida",
        "email": "vitor@almeida",
        "phoneNumber": "+551199999999",
        "driverLicence": ""
    },
    {
        "id": "9b414002-8244-40bb-9059-7f80adc7097f",
        "displayName": "filipe alves",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "filipe",
        "lastName": "alves",
        "email": "filipe.fao94@gmail.com",
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "a0bc613b-e6e9-4fbd-91a9-70a5771c997f",
        "displayName": "Novo Marlon Marlon novin",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Novo Marlon",
        "lastName": "Marlon novin",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "a3f473b3-6c8d-4ecf-9b02-76ec3228c1e9",
        "displayName": "Antonio Da Silva",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Antonio",
        "lastName": "Da Silva",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "a41b25c2-3425-4f09-a25d-a34038b4cbe8",
        "displayName": "João  Pedro",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "João ",
        "lastName": "Pedro",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "a54d1a75-3114-4c53-9840-82e552cfe993",
        "displayName": "Everaldo Estevão Camilo Silva",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Everaldo Estevão",
        "lastName": "Camilo Silva",
        "email": "evesilva@zeentech.com.br",
        "phoneNumber": "+24999412023",
        "driverLicence": ""
    },
    {
        "id": "a6a1d2a9-f47b-4e8c-b2f9-56f05a652faa",
        "displayName": "BR 0001234567890000 BR 0001234567890000",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "BR 0001234567890000",
        "lastName": "BR 0001234567890000",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "d9eaaa34-2cfe-4a09-be86-91dd4f95474a",
        "displayName": "Driver 1 Soares",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Driver 1",
        "lastName": "Soares",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "df97f121-730f-4bdf-91c1-458e210f23a3",
        "displayName": "pedro oliveira",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "pedro",
        "lastName": "oliveira",
        "email": "Pedrobolfarini@gmail.com",
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "dfac08e2-215b-4c8b-b1f1-d24c0e56e3cb",
        "displayName": "Mither MFH",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Mither",
        "lastName": "MFH",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "e4001bb5-ce96-47fc-86e2-40cc7bfe86f0",
        "displayName": "Fernando3 Soares",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Fernando3",
        "lastName": "Soares",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "eea0206a-6382-45fb-8709-dad2af5f0862",
        "displayName": "Andre Pedro",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Andre",
        "lastName": "Pedro",
        "email": null,
        "phoneNumber": null,
        "driverLicence": ""
    },
    {
        "id": "f9cc35fe-6746-4cae-9b46-acbe2043bb05",
        "displayName": "Fernando1 Soares",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "archived",
        "firstName": "Fernando1",
        "lastName": "Soares",
        "email": "fernando.soares@rio.cloud",
        "phoneNumber": "+5511996859728",
        "driverLicence": ""
    },
    {
        "id": "fbaebad1-8eae-43c7-8533-9b6342e1dccc",
        "displayName": "Lucas Jordão",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Lucas",
        "lastName": "Jordão",
        "email": "lucas.jordao@opah.com.br",
        "phoneNumber": "+5583986679667",
        "driverLicence": ""
    },
    {
        "id": "fce10a08-b72c-40ed-8e0c-477a91c87d31",
        "displayName": "Lucas Jordão",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "Lucas",
        "lastName": "Jordão",
        "email": "lucas.jordao@opah.com.br",
        "phoneNumber": "+5583986679667",
        "driverLicence": ""
    },
    {
        "id": "ffc9e785-30ee-4a4b-9e2e-18061f1ffd3c",
        "displayName": "André Pelé",
        "accountId": "edca08ab-8979-441f-8b16-16682e188019",
        "status": "active",
        "firstName": "André",
        "lastName": "Pelé",
        "email": null,
        "phoneNumber": "+5511968429214",
        "driverLicence": ""
    }
];

export const dealershipMockData = [
    {
        id: '1930',
        name: 'Dibracam',
        address: 'Avenida Corifeu de Azevedo Marques, 345, Butanta, São Paulo - SP',
        consultants: [
            'b79d16f6-9213-43f2-971e-59de52b15956'// Josué
        ]
    },
    {
        id: '4873',
        name: 'Tiete',
        address: 'Avenida Marginal Direita do Rio Tiete, 700, Vila Jaguara, São Paulo - SP',
        consultants: [
            'ec6e5df5-b523-4428-bacd-48d04b90a234'// Alexis
        ]
    }
];