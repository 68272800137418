import ApiService from "../services/api";

export interface IUserProfile {
    name: string;
    email: string;
    group?: string;
}

export const fetchUser = async (userId: string): Promise<IUserProfile | undefined> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.get<any>(`/users/${userId}`);
        if (response.status === 200) {
            return {
                name: response.data.firstName + " " + response.data.lastName,
                email: response.data.email,
                group: response.data.group,
            };
        } else return undefined;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}