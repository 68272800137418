import "./styles.scss";

import { useState } from "react";
import { Asset } from "../../models/fleetManager/asset";
import Checkups from "./checkups";
import OilChange from "./oilChange";

interface IIPeriodicReviewsProps {
  closeSidebar: () => void;
  asset: Asset | null;
}

const PeriodicReviews = ({ closeSidebar, asset }: IIPeriodicReviewsProps) => {
  const [activeTab, setActiveTab] = useState<"revisoes" | "trocaDeOleo">("revisoes");

  return (
    <div className="right-sidebar">
      <h3 className="flex gap-6 margin-bottom-20">
        <span className="rioglyph rioglyph-book"></span>
        Revisões Periodicas
      </h3>
      <div
        className="close-sidebar cursor-pointer"
        onClick={() => closeSidebar()}
      >
        <span className="rioglyph rioglyph-remove"></span>
      </div>

      <div className="w-full">
        <div className="p-0">
          <div className="flex space-x-8 mb-6">
            <button
              onClick={() => setActiveTab("revisoes")}
              className={`text-size-16 font-semibold ${
                activeTab === "revisoes"
                  ? "border-b-2 border-black"
                  : "text-gray-400"
              }`}
            >
              Revisões
            </button>
            <button
              onClick={() => setActiveTab("trocaDeOleo")}
              className={`text-size-16 font-semibold ${
                activeTab === "trocaDeOleo"
                  ? "border-b-2 border-black"
                  : "text-gray-400"
              }`}
            >
              Troca de óleo
            </button>
          </div>

          {activeTab === "revisoes" && <Checkups asset={asset} />}

          {activeTab === "trocaDeOleo" && <OilChange asset={asset} />}
        </div>
      </div>
    </div>
  );
};

export default PeriodicReviews;
