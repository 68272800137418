import { AssetSummaryDto } from "../../dtos/fleetManager/assetDto";
import { AssetSummary } from "../../models/fleetManager/asset";
import ApiService from "../../services/api";

export const fetchAssets = async (): Promise<AssetSummary[]> => {
    const apiService = new ApiService();
    
    try {
        const response = await apiService.get<any>(`/assets?account_id=`);
        if (response.status === 200) {
            return response.data.map(AssetSummaryDto);
        } else return [];
    } catch (error) {
        console.error(error);
        return [];
    }
}