import { MainSchedule } from "../../models/fleetManager/scheduleList";
import ApiService from "../../services/api";

export const fetchScheduleList = async (): Promise<MainSchedule[]> => {
  const apiService = new ApiService();
  try {
    const response = await apiService.get<MainSchedule[]>(
      `/schedules/checkups/schedules-by-user`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      console.warn("Unexpected status code:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching schedule list:", error);
    return [];
  }
};

export const fetchDeletedSchedule = async (id: string): Promise<any[]> => {
  const apiService = new ApiService();

  try {
    const response = await apiService.post<any[]>(
      `/schedules/checkups/cancel/${id}`,
      {}
    );

    if (response.status === 200) {
      return response.data;
    } else {
      console.warn("Unexpected status code:", response.status);
      return [];
    }
  } catch (error: any) {
    console.error(
      "Error fetching deleted schedule list:",
      error.response?.data || error.message || error
    );
    return [];
  }
};
