import { useState } from "react";
import { makeAnAppointment } from "../../../../api/fleetManager/appointments";
import { IAppointmentForm } from "../../../fleetManager/newAppointment";
import Notification from '@rio-cloud/rio-uikit/Notification';
import Page from "../../Page";
import { useSelector } from "react-redux";
import { getUserProfile } from "../../../../configuration/login/loginSlice";
import SuccessDialog from "../../../fleetManager/newAppointment/dialogs/successDialog";

interface IAppointmentSummaryProps {
  goBackToForm: () => void;
  appointment: IAppointmentForm | undefined;
}

const AppointmentSummary = ({ goBackToForm, appointment }: IAppointmentSummaryProps) => {
  const userProfile = useSelector(getUserProfile);
  const [loading, setLoading] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  
  return (
    <Page>
      <div className="scheduling-page resume-section p-10">
        <h2 className="text-size-h2 text-center text-color-black font-semibold padding-top-20 padding-bottom-20">
          Resumo do agendamento
        </h2>
        <div className="resume-container">
          <h5 className="text-size-20 text-color-black font-bold margin-bottom-20">
            AGD 001574
          </h5>

          <div className="row schedule-data">
            <div className="col-4">
              <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
                Motorista
              </label>
              <div className="mt-1 p-2 border schedule-data__info">
                {appointment?.assetsAndDrivers?.driver?.name}
              </div>
            </div>
            <div className="col-2">
              <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
                CNH
              </label>
              <div className="mt-1 p-2 border schedule-data__info">
                {appointment?.assetsAndDrivers?.driver?.license}
              </div>
            </div>
            <div className="col-3">
              <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
                Telefone
              </label>
              <div className="mt-1 p-2 border schedule-data__info margin-bottom-10">
                {appointment?.assetsAndDrivers?.driver?.phone}
              </div>
            </div>
            <div className="col-3">
              <label className="block text-sm text-size-20 text-color-black font-bold margin-bottom-10">
                E-mail
              </label>
              <div className="mt-1 p-2 border schedule-data__info">
                {appointment?.assetsAndDrivers?.driver?.email}
              </div>
            </div>
          </div>

          <div className="p-4 bg-lighter margin-top-20 vehicle-detais">
            <div className="flex items-start gap-4">
              <img
                src="/assets/images/vehicle-thumb.png"
                alt="Vehicle"
                className="w-20 h-20 rounded"
              />

              <div className="flex-1 padding-left-10">
                <p className="text-color-black text-size-16 font-medium m-0">
                  {appointment?.assetsAndDrivers?.asset?.name || appointment?.assetsAndDrivers?.asset?.model} | Placa: {appointment?.assetsAndDrivers?.asset?.licensePlate}
                </p>
                <p className="text-color-status-resting text-size-14 m-0">
                  Chassi - {appointment?.assetsAndDrivers?.asset?.chassis}
                  <br />
                  Odômetro: {appointment?.assetsAndDrivers?.asset?.odometer} km | Horímetro: {appointment?.assetsAndDrivers?.asset?.hourmeter}
                </p>
              </div>

              {((appointment?.assetsAndDrivers?.asset?.plans?.length ?? 0) > 0) && <div className="flex gap-6 margin-bottom-20">
                <span className="px-2 py-1 green-tag">RIO</span>
                <span className="px-2 py-1 green-tag">
                  Em Garantia
                </span>
                {appointment?.assetsAndDrivers?.asset?.volksTotalPlan && <span className="px-2 py-1 bg-white white-tag">
                  Volkstotal {appointment?.assetsAndDrivers?.asset?.volksTotalPlan}
                </span>}
              </div>}
            </div>

            <div className="padding-top-10">
              <div className="car-workshop">
                <ul className="flex flex-column gap-5 m-0">
                  <li className="flex justify-between">
                    <div className="text-color-black">Ação de oficina</div>
                    <div className="text-color-black font-bold">Grátis</div>
                  </li>

                  <li className="flex justify-between">
                    <div className="text-color-black">
                      Revisão {appointment?.assetsAndDrivers?.checkup?.description} ({appointment?.assetsAndDrivers?.checkup?.odometer} km)
                    </div>
                    <div className="text-color-darkest font-bold">Grátis</div>
                  </li>
                  <li className="flex justify-between border border-bottom-none border-left-none border-right-none padding-top-10 margin-top-10">
                    <div className="text-color-black">Total</div>
                    <div className="text-color-black font-bold">Grátis</div>
                  </li>

                  <li className="flex justify-between">
                    <div className="text-color-black">
                      Total em serviços
                    </div>
                    <div className="text-color-black">
                      {(appointment?.assetsAndDrivers?.aditionalServices ?? [])
                        .reduce((acc, service) => acc + service.price, 0)
                        .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="revision-itens">
            <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
              Gestor
            </h5>
            <div className="p-4 bg-white border">
              <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
                <div className="padding-top-10 padding-bottom-10">
                  <span>-</span>
                </div>
              </ul>
            </div>
          </div>

          <div className="revision-itens">
            <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
              Itens cobertos na revisão
            </h5>
            <div className="p-4 bg-white border">
              <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
                {
                  appointment?.assetsAndDrivers?.checkup?.services.map((item, index) => (
                    <div key={index} className='padding-bottom-10'>
                      <p className='text-bold'>{item.name}</p>
                      {
                        item.items.map((subItem, subIndex) => (
                          <p key={subIndex} className='padding-left-20'>{subItem}</p>
                        ))
                      }
                    </div>
                  ))
                }
              </ul>
            </div>
          </div>

          <div className="revision-itens observation">
            <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
              Observações
            </h5>
            <div className="p-4 bg-white border">
              <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
                <li className="flex justify-between">
                  <span>{appointment?.assetsAndDrivers?.obs}</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="revision-itens flex justify-between gap-6">
            <div className="w-2/3">
              <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                Concessionária
              </h5>
              <div className="p-4 bg-white border card">
                <p className="m-0 text-size-h5">{appointment?.dealership?.name}</p>
                <p className="m-0">{appointment?.dealership?.address}</p>
              </div>
            </div>

            <div className="w-1/3">
              <h5 className="text-size-20 text-color-black font-bold margin-bottom-10">
                Data e horário:
              </h5>
              <div className="p-4 bg-white border card">
                <p className="m-0 text-size-h5">
                  {appointment?.dateAndConsultant?.scheduleDate?.toLocaleDateString('pt-BR', { year: 'numeric', month: 'long', day: 'numeric' })}
                </p>
                <p className="m-0">
                  {appointment?.dateAndConsultant?.scheduleDate?.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-between navigation-buttons step-4">
            <button
              className="back-btn"
              onClick={goBackToForm}
            >
              Voltar
            </button>
            <button
              className={`px-4 py-2 btn btn-primary ${loading ? 'btn-loading disabled' : ''}`}
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                const success = await makeAnAppointment(appointment!, userProfile);

                if (success) {
                    setSuccessDialogOpen(true);
                    setLoading(false);
                } else {
                    setLoading(false);
                    Notification.error('Erro ao agendar a revisão');
                }
            }}
            >Confirmar</button>
          </div>
        </div>

        <SuccessDialog  open={successDialogOpen} appointmentForm={appointment} routeWhenClose="/dealership" />
      </div>
    </Page>
  );
};

export default AppointmentSummary;