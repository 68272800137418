import StatsWidget from "@rio-cloud/rio-uikit/StatsWidget";
import { ICardBigNumberProps } from ".";
import StatsWidgetNumber from "@rio-cloud/rio-uikit/StatsWidgetNumber";
import { isRioPlanFilterDisabled } from "../../services/fleetManager/maintenanceTableService";
import StatsWidgetSpacer from "@rio-cloud/rio-uikit/StatsWidgetSpacer";

const VolksTotalPlanBigNumbers = ({
    assetFilter,
    setAssetFilter,
    getDisabledColor,
    bigNumberVolksTotal
}: ICardBigNumberProps) => {
    return (
        <StatsWidget className="card">
            <StatsWidget.Header>VolksTotal</StatsWidget.Header>
            <StatsWidget.Body>
                <StatsWidgetNumber
                    className={`
                        ${getDisabledColor(
                            (assetFilter.hasVolksTotalPlanFilter === undefined || assetFilter.hasVolksTotalPlanFilter === false) && !isRioPlanFilterDisabled(assetFilter.hasVolksTotalPlanFilter, assetFilter.volksTotalPlan),
                            'text-color-status-driving'
                        )} 
                        cursor-pointer
                        `}
                    value={bigNumberVolksTotal?.volksTotal}
                    label="Com VolksTotal"
                    onClick={() =>
                        setAssetFilter({
                            ...assetFilter,
                            hasVolksTotalPlanFilter: assetFilter.hasVolksTotalPlanFilter === true ? undefined : true,
                        })
                    }
                />
                <StatsWidgetSpacer />
                <StatsWidgetNumber
                    className={`
                        ${getDisabledColor(
                                (assetFilter.hasVolksTotalPlanFilter === undefined || assetFilter.hasVolksTotalPlanFilter === true) && !isRioPlanFilterDisabled(assetFilter.hasVolksTotalPlanFilter, assetFilter.volksTotalPlan),
                                'text-color-danger'
                            )} 
                        cursor-pointer
                        `}
                    value={bigNumberVolksTotal?.withoutVolksTotal}
                    label="Sem VolksTotal"
                    onClick={() =>
                        setAssetFilter({
                            ...assetFilter,
                            hasVolksTotalPlanFilter: assetFilter.hasVolksTotalPlanFilter === false ? undefined : false,
                            volksTotalPlan: undefined,
                        })
                    }
                />
            </StatsWidget.Body>
            <StatsWidget.Body>
                <StatsWidgetNumber
                    className={`
                        ${getDisabledColor(
                                (assetFilter.hasVolksTotalPlanFilter === false || assetFilter.volksTotalPlan !== 'Prev') && !isRioPlanFilterDisabled(assetFilter.hasVolksTotalPlanFilter, assetFilter.volksTotalPlan),
                                ''
                            )} 
                        cursor-pointer
                        `}
                    value={bigNumberVolksTotal?.prev}
                    label="Prev"
                    onClick={() =>
                        assetFilter.hasVolksTotalPlanFilter && setAssetFilter({
                            ...assetFilter,
                            volksTotalPlan: assetFilter.volksTotalPlan === 'Prev' ? undefined : "Prev",
                        })
                    }
                />
                <StatsWidgetSpacer />
                <StatsWidgetNumber
                    className={`
                        ${getDisabledColor(
                                (assetFilter.hasVolksTotalPlanFilter === false || assetFilter.volksTotalPlan !== 'Max') && !isRioPlanFilterDisabled(assetFilter.hasVolksTotalPlanFilter, assetFilter.volksTotalPlan),
                                ''
                            )} 
                        cursor-pointer
                        `}
                    value={bigNumberVolksTotal?.max}
                    label="Max"
                    onClick={() =>
                        assetFilter.hasVolksTotalPlanFilter && setAssetFilter({
                            ...assetFilter,
                            volksTotalPlan: assetFilter.volksTotalPlan === 'Max' ? undefined : "Max",
                        })
                    }
                />
                <StatsWidgetSpacer />
                <StatsWidgetNumber
                    className={`
                        ${getDisabledColor(
                                (assetFilter.hasVolksTotalPlanFilter === false || assetFilter.volksTotalPlan !== 'Plus') && !isRioPlanFilterDisabled(assetFilter.hasVolksTotalPlanFilter, assetFilter.volksTotalPlan),
                                ''
                            )} 
                        cursor-pointer
                        `}
                    value={bigNumberVolksTotal?.plus}
                    label="Plus"
                    onClick={() =>
                        assetFilter.hasVolksTotalPlanFilter && setAssetFilter({
                            ...assetFilter,
                            volksTotalPlan: assetFilter.volksTotalPlan === 'Plus' ? undefined : "Plus",
                        })
                    }
                />
            </StatsWidget.Body>
        </StatsWidget>
    )
}

export default VolksTotalPlanBigNumbers;