import { useEffect, useState } from "react";
import { RadioButton } from "@rio-cloud/rio-uikit";
import FilePicker from '@rio-cloud/rio-uikit/FilePicker';
import { Asset } from "../../../../models/dealership/asset";
import { InspectionChecklistDialog } from "../dialogs/inspectionChecklistDialog";
import { TicketInspection, TicketRequest } from "../../../../models/ticket";
import { fetchTicketImages, ITicketFile, uploadTicketImage } from "../../../../api/ticket";
import { Scheduling } from "../../../../models/dealership/schedule";

interface IInspectionProps {
    ticketId?: string | undefined;
    inspection?: TicketInspection | undefined;
    scheduling?: Scheduling | undefined;
    request?: TicketRequest | undefined;
}

const Inspection = ({ ticketId, inspection, scheduling, request }: IInspectionProps) => {

    const [file, setFile] = useState<ITicketFile | undefined>();
    useEffect(() => {
        const fetchData = async () => {
            if (inspection && ticketId) {
                const images = await fetchTicketImages({ ticketId: ticketId, step: 'INSPECTION' })
                setFile(images[0]);
            };
        }

        fetchData();
    }, [inspection]);

    const [checklistDialog, setChecklistDialog] = useState(false);

    return (
        <div>
            <div className="display-grid gap-20 grid-cols-4">
                <div className="form-group">
                    <label htmlFor="checkinDate">Check-in</label>
                    <input
                        id="checkin"
                        className="form-control"
                        type="text"
                        placeholder="--/--/----"
                        disabled
                        value={inspection?.checkIn?.toLocaleDateString('pt-BR')}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="checkinHour">Hora</label>
                    <input
                        id="hora-checkin"
                        className="form-control"
                        type="text"
                        placeholder="--:--"
                        disabled
                        value={inspection?.checkIn?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="checkoutDate">Check-out</label>
                    <input
                        id="checkout"
                        className="form-control"
                        type="text"
                        placeholder="--/--/----"
                        disabled
                        value={inspection?.checkOut?.toLocaleDateString('pt-BR')}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="checkoutHour">Hora</label>
                    <input
                        id="hora-checkout"
                        className="form-control"
                        type="text"
                        placeholder="--:--"
                        disabled
                        value={inspection?.checkOut?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    />
                </div>
            </div>

            <div className="display-grid gap-20 grid-cols-4">
                <div className="display-grid gap-20 grid-cols-2">
                    <div>
                        <label>Validação do Serviço está OK?</label>
                        <div>
                            <RadioButton
                                name='isServiceValid' inline
                                checked={inspection?.serviceValidation}
                                onChange={() => inspection!.serviceValidation = true}
                                disabled
                            >Sim</RadioButton>
                            <RadioButton
                                name='isServiceValid' inline
                                checked={!inspection?.serviceValidation}
                                onChange={() => inspection!.serviceValidation = false}
                                disabled
                            >Não</RadioButton>
                        </div>
                    </div>
                    <button type="button" className="btn btn-secondary margin-top-25" onClick={() => setChecklistDialog(true)}>
                        <span className="rioglyph rioglyph-time-alt" aria-hidden="true">
                        </span>
                        <span className="text-bold">Checklist</span>
                    </button>
                </div>

                <div className="form-group">
                    <label htmlFor="fanceInHour">Tempo Estimado</label>
                    <div className="input-group">
                        <span className="input-group-addon">
                            <span className="rioglyph rioglyph-time">
                            </span>
                        </span>
                        <input
                            id="fanceInHour"
                            className="form-control"
                            type="text"
                            value={inspection?.estimatedTime}
                            onChange={(e) => inspection!.estimatedTime = e.target.value}
                            disabled
                        />
                    </div>
                </div>
            </div>

            <div className="form-group ">
                <label htmlFor="textArea">Relato</label>
                <textarea
                    className="form-control"
                    rows={3} id="textArea" placeholder="Descritivo"
                    value={inspection?.report}
                    onChange={(e) => inspection!.report = e.target.value}
                    disabled
                />
            </div>

            <div>
                <label>Inspeção Visual</label>
                <div>
                    <FilePreview file={file} />
                </div>
            </div>

            <InspectionChecklistDialog
                showDialog={checklistDialog}
                onClose={() => setChecklistDialog(false)}
                services={request?.checkupChecklist ?? []}
                asset={scheduling?.asset ?? {} as Asset}
            />
        </div>
    )
}

const FilePreview = ({ file }: { file: ITicketFile | undefined }) => (
    <div className='panel panel-default margin-top-20'>
        <div className='panel-body'>
            {file && (
                <>
                    <div>
                        <b>{`${file?.name}`}</b>
                    </div>
                    <div>tipo: {`${file?.contentType}`}</div>
                    <div>tamanho: {`${file?.contentSize}`}</div>
                    <div>data da última modificação: {`${file?.updatedAt}`}</div>
                    <div>caminho: {`${file?.name}`}</div>
                </>
            )}
            {!file && <div className='text-color-dark text-italic'>Nenhum arquivo</div>}
        </div>
    </div>
);

export default Inspection;