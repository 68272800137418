import { Checkup } from "../../models/fleetManager/checkup";

export const CheckupDto = (data: any): Checkup => {
    return {
        id: data.id,
        name: data.checkupType.name,
        description: data.desc,
        odometer: data.range.start,
        hourmeter: null,
        services: data.checkupType.services,
        aditionalServices: data.compls.map((compl: any) => ({
            id: compl.id,
            name: compl.name,
            price: compl.price ?? 0,
        }))
    };
}