import { useEffect, useState } from "react";
import { Client } from '@amityco/ts-sdk';
import { useSelector } from "react-redux";
import { getUserProfile } from "../../configuration/login/loginSlice";

import Messages from "./components/Messages";
import Chat from "./components/Chat";
import NewChat from "./components/NewChat";
import Appointments from "./components/Appointments";

import "./styles.scss";
import { IChannel, listUserChannels } from "../../services/amityService";
// import { fetchUser } from "../../api/fetchUser";

const ChatMessages = () => {
  const [loadingChats, setLoadingChats] = useState<boolean>(false);
  const [amityChannels, setAmityChannels] = useState<IChannel[]>([]);
  const [channel, setChannel] = useState<IChannel | null>(null);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);

  const userProfile = useSelector(getUserProfile);
  
  const sessionHandler: Amity.SessionHandler = {
    sessionWillRenewAccessToken(renewal: Amity.AccessTokenRenewal) {
      renewal.renew();
    },
  };
  
  useEffect(() => {
    const init = async () => {
      setLoadingChats(true);
      const userId = userProfile?.sub?.split(':')[1]!;
      await Client.login(
        { userId, displayName: userId },
        sessionHandler,
      );
      // const userId = `uptime_${userProfile?.sub?.split(':')[1]}`;

      // const userData = 
      //   userId.split('_')[1] === 'ec6e5df5-b523-4428-bacd-48d04b90a234' ?
      //     { name: 'Alexis Barros', email: 'alexis.barros@opah.com.br' } : // Mock user
      //     await fetchUser(userId.split('_')[1]);
      
      // await Client.login(
      //   { userId, displayName: `${userData?.name}__${userData?.email}` },
      //   sessionHandler,
      // );

      listUserChannels(
        userId, 
        (channels) => setAmityChannels(
          channels
            .filter((channel) => channel.displayName)
            .filter((channel, index, self) => index === self.findIndex((c) => c.id === channel.id))
        ),
        () => setLoadingChats(false),
      );
    }
    
    init();
  }, []);

  const [currentView, setCurrentView] = useState<"list" | "chat" | "newChat" | "appointments">("list");

  const handleOpenMessages = (channel: IChannel) => [setChannel(channel), setCurrentView("chat")];

  const handleBackToList = () => [setChannel(null), setCurrentView("list")];

  const handleNewChat = () => setCurrentView("newChat");

  const handleOpenScheduling = () => setCurrentView("appointments");

  return (
    <div>
      <span className="indicated-icon" onClick={() => setShowSidebar(!showSidebar)}>
        <span className="rioglyph rioglyph-chat"></span>
        {
          amityChannels.some((channel) => channel.hasUnreadMessage) && 
            <span className="badge badge-indicator badge-danger height-20 width-20 text-size-11 padding-0 margin-top-10">
              {amityChannels.filter((channel) => channel.hasUnreadMessage).length}
            </span>
        }
      </span>

      {showSidebar && <div className="defaultProps">
        <div className="bg-chat-sidebar" onClick={() => setShowSidebar(!showSidebar)} />
        <div className="chat-sidebar">
          {currentView === "list" && 
            <Messages 
              loadingChats={loadingChats} 
              channels={amityChannels} 
              handleNewChat={handleNewChat} 
              handleOpenScheduling={handleOpenScheduling} 
              handleOpenMessages={handleOpenMessages} 
            />}

          {currentView === 'chat' && 
            <Chat 
              userId={userProfile?.sub?.split(':')[1]!} 
              channel={channel} 
              handleBackToList={handleBackToList} 
            />}

          {currentView === "newChat" && 
            <NewChat  
              handleBackToList={handleBackToList} 
              userId={userProfile?.sub?.split(':')[1]!} 
              handleOpenMessages={handleOpenMessages} 
              channels={amityChannels}
            />}

          {currentView === "appointments" && 
            <Appointments 
              handleBackToList={handleBackToList} 
            />}
        </div>
      </div>}
    </div>
  );
};

export default ChatMessages;
