import SimpleDialog from "@rio-cloud/rio-uikit/SimpleDialog";

import "../styles.css";
import Checkbox from "@rio-cloud/rio-uikit/Checkbox";
import { Scheduling } from "../../../../../../models/dealership/schedule";

interface ISchedulingDetailDialogProps {
  show: boolean;
  onClose: () => void;
  scheduling: Scheduling | undefined;
  newConsultantSchedule: Scheduling[];
}

const NewSchedulingDetailDialog = ({
  show,
  onClose,
  scheduling,
  newConsultantSchedule,
}: ISchedulingDetailDialogProps) => {
  const dialogContent = (
    <>
      {newConsultantSchedule.length > 0 &&
        newConsultantSchedule.map((item, index) => (
          <div className="padding-25">
            <div className="text-size-20 text-bold padding-bottom-25">
              Protocolo AGD 001574
            </div>

            <div className="row">
              <div className="col-4">
                <label className="block text-color-black text-size-20 text-bold margin-bottom-10">
                  Motorista
                </label>
                <div className="mt-1 p-2 border schedule-data__info">
                  {item?.driver.name}
                </div>
              </div>
              <div className="col-2">
                <label className="block text-color-black text-size-20 text-bold margin-bottom-10">
                  CNH
                </label>
                <div className="mt-1 p-2 border schedule-data__info">
                  {item?.driver.license}
                </div>
              </div>
              <div className="col-3">
                <label className="block text-color-black text-size-20 text-bold margin-bottom-10">
                  Telefone
                </label>
                <div className="mt-1 p-2 border schedule-data__info margin-bottom-10">
                  {item?.driver.phone}
                </div>
              </div>
              <div className="col-3">
                <label className="block text-color-black text-size-20 text-bold margin-bottom-10">
                  E-mail
                </label>
                <div className="mt-1 p-2 border schedule-data__info">
                  {item?.driver.email}
                </div>
              </div>
            </div>

            <div className="padding-20 bg-lighter margin-y-20 vehicle-detais">
              <div className="flex items-start gap-4">
                <img
                  src="/assets/images/vehicle-thumb.png"
                  alt="Vehicle"
                  className="w-20 h-20 rounded"
                />

                <div className="flex-1 padding-left-10">
                  <p className="text-color-black text-size-16 font-medium m-0">
                    {item?.asset.name} | Placa: {item?.asset.licensePlate}
                  </p>
                  <p className="text-color-status-resting text-size-14 m-0">
                    Chassi - {item?.asset.chassis}
                    <br />
                    Odômetro: {item?.asset.odometer} km | Horímetro:{" "}
                    {item?.asset.hourmeter} h
                  </p>
                </div>

                <div className="flex gap-6 margin-bottom-20">
                  {item?.asset.tags?.map((tag, index) => (
                    <span key={index} className="px-2 py-1 green-tag">
                      {tag}
                    </span>
                  ))}
                  {/* <span className="px-2 py-1 green-tag">RIO</span>
                        <span className="px-2 py-1 green-tag">Em Garantia</span>
                        <span className="px-2 py-1 bg-white white-tag">Volkstotal Prev</span> */}
                </div>
              </div>

              <div className="padding-10">
                <div className="car-workshop">
                  <ul className="flex flex-column gap-15 m-0">
                    <li className="flex justify-between">
                      <div className="text-color-black">Campanhas de campo</div>
                      <div className="text-bold">Grátis</div>
                    </li>

                    <li className="flex justify-between">
                      <div className="text-color-black">
                        Revisão {item?.description}
                      </div>
                      <div className="text-bold">Grátis</div>
                    </li>

                    <li className="flex justify-between">
                      <div className="text-color-black">Total em serviços</div>
                      <div className="text-color-black">{item?.totalPrice}</div>
                    </li>
                    <li className="flex justify-between border-top-only border-width-1">
                      <div className="text-color-black text-bold">Total</div>
                      <div className="text-color-black text-bold">
                        {item?.totalPrice}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div>
              <div className="text-size-14 text-medium">Importante!</div>
              <div className="text-size-14">{item?.observation}</div>
            </div>

            <div className="revision-itens margin-y-20">
              <h5 className="text-size-20 text-bold margin-bottom-10">
                Itens cobertos na revisão
              </h5>
              <div className="p-4 bg-white border">
                <div className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
                  {item?.services.map((item, index) => (
                    <div key={index} className="padding-bottom-10">
                      <p className="text-bold">{item.title}</p>
                      {item.items.map((subItem, subIndex) => (
                        <p key={subIndex} className="padding-left-20">
                          {subItem}
                        </p>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="revision-itens">
              <h5 className="text-size-20 text-bold margin-bottom-0">
                Descrição dos Serviços Contratados
              </h5>
              <div className="text-size-14 margin-bottom-10">
                Espaço para descrever informações importantes sobre “Serviços
                Contratados”, adicionados no agendamento.
              </div>
              <div className="p-4 bg-white border">
                {item?.otherServices?.map((item, index) => (
                  <div key={index}>
                    <p>{item}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="revision-itens observation">
              <h5 className="text-size-20 text-bold margin-bottom-0">
                Observações
              </h5>
              <div className="text-size-14 margin-bottom-10">
                Espaço para relatar problemas na manutenção do seu veículo.
              </div>
              <div className="p-4 bg-white border">
                <ul className="space-y-5 text-size-14 text-color-black m-0 padding-left-10">
                  <li className="flex justify-between">
                    <span>{item?.observation}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="revision-itens flex justify-between gap-6">
              <div className="w-1/3">
                <h5 className="text-size-20 text-bold margin-bottom-10">
                  Concessionária
                </h5>
                <div className="p-4 bg-white border card">
                  <p className="m-0 text-size-h5">{item?.dealership.name}</p>
                  <p className="m-0">{item?.dealership.address}</p>
                </div>
              </div>

              <div className="w-1/3">
                <h5 className="text-size-20 text-bold margin-bottom-10">
                  Data e horário:
                </h5>
                <div className="p-4 bg-white border card">
                  <p className="m-0 text-size-h5">
                    {item?.scheduleTime.toLocaleDateString("pt-BR", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                  <p className="m-0">
                    {item?.scheduleTime.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                </div>
              </div>

              <div className="w-1/3">
                <h5 className="text-size-20 text-bold margin-bottom-10">
                  Consultor:
                </h5>
                <div className="p-4 bg-white border card">
                  <p className="m-0 text-size-h5 text-capitalize">
                    {item?.consultant}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );

  const dialogFooter = (
    <div className="display-flex justify-content-center">
      <button
        type="button"
        className="btn btn-primary width-250"
        onClick={onClose}
      >
        Fechar
      </button>
    </div>
  );

  return (
    <SimpleDialog
      show={show}
      title="Detalhes do agendamento"
      subtitle="Confira abaixo as informações do agendamento"
      content={dialogContent}
      footer={dialogFooter}
      bsSize="lg"
      onClose={onClose}
      useOverflow
    />
  );
};

export default NewSchedulingDetailDialog;
