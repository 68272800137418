import { Asset } from "../../models/dealership/asset";

export const assetDto = (data: any): Asset => {
    return {
        id: data.assetId,
        name: data.name || data.vehicleModel,
        imageUrl: data.imageUrl,
        group: data.vehicleGroup,
        licensePlate: data.plate,
        chassis: data.chassis,
        odometer: data.odometer,
        hourmeter: data.hourMeter,
        tags: data.tags,
        model: data.vehicleModel,
        plans: data.plans,
        madeCheckups: [],
        nextCheckups: [data.checkup],
        nextCheckup: {
            id: data.checkup.checkupType.id,
            checkup: {
                id: data.checkup.checkupType.id,
                name: data.checkup.checkupType.name,
                description: '',
                odometer: data.checkup.range.start,
                hourmeter: 0,
                services: data.checkup.checkupType.services,
                aditionalServices: data.vehicleSchedule.otherServices.services
                    .map((service: any) => {
                        return {
                            ...service,
                            price: parseFloat(service.price.replace('R$', '').replace('.', '').replace(',', '.').trim())
                        }
                    }),
            },
            odometer: data.checkup.range.start,
            hourmeter: 0,
            os: '',
            date: new Date(data.schedule.scheduledDate),
            done: false,
            status: data.schedule.state,
            isSigned: false,
        },
        year: data.vehicleYear,
    };
}