import { getCurrentMonthDays } from "../../../../../utils/dateUtils";
import { Scheduling } from "../../../../../models/dealership/schedule";

interface IMonthProps {
    currentDate: Date;
    consultantMonthSchedule: Scheduling[];
    setCurrentDate: (date: Date) => void;
    setScheduleViewType: (scheduleViewType: string) => void;
}

const Month = ({ currentDate, consultantMonthSchedule, setCurrentDate, setScheduleViewType }: IMonthProps) => {
    const weekdays = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'];
    const days = getCurrentMonthDays(currentDate);

    console.log('days', days);

    return (
        <table className="width-100pct">
            <thead>
                <tr>
                    {
                        
                        weekdays.map((weekday, index) => (
                            <th className='height-20 text-size-12 text-medium' key={index}>{weekday}</th>
                        ))
                    }
                </tr>
            </thead>
            <tbody>
                {
                    Array.from({ length: Math.ceil(days.length / 7) }).map((_, weekIndex) => (
                        <tr key={weekIndex}>
                            {Array.from({ length: 7 }).map((_, dayIndex) => {
                                let hasScheduling = false;
                                const day = days[(weekIndex * 7) + dayIndex];

                                if(day > 0) {
                                    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
                                    hasScheduling = !!consultantMonthSchedule.find((scheduling: Scheduling) => 
                                        scheduling.scheduleTime.getDate() === date.getDate() &&
                                        scheduling.scheduleTime.getMonth() === date.getMonth()
                                    );
                                }
                                return (
                                    <td
                                        style={{ height: 121, width: 100 }}
                                        className={`
                                            text-center text-size-18 border 
                                            ${hasScheduling ? 'bg-highlight-decent' : ''}
                                            ${day < 0 ? 'text-color-gray cursor-text bg-lightest' : 'cursor-pointer'}
                                        `}
                                        onClick={() => day > 0 && [setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), day)), setScheduleViewType('day')]}
                                        key={`${weekIndex}_${dayIndex}`}
                                    >{day < 0 ? day * -1 : day}</td>
                                )})
                            }
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
}

export default Month;