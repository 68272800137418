import { Dealership, DealershipConsultant } from "../../models/fleetManager/dealership";

export const dealershipDto = (data: any): Dealership => {
    return {
        id: data?.id ?? '',
        name: data?.fantasyName ?? '',
        address: data?.address ?? '',
        distance: data?.distance ?? 0,
        geoLocation: {
            lat: data?.latitude ?? 0,
            lng: data?.longitude ?? 0,
        },
    };
}

export const dealershipConsultantDto = (data: any): DealershipConsultant => {
    return {
        id: data.consultant.id,
        name: `${data.consultant.firstName} ${data.consultant.lastName}`,
        scheduleDates: data.scheduleDates.map((el: any) => new Date(el)),
    }
}