import { useState } from "react";
import { Asset } from "../../../models/fleetManager/asset"
import ToScheduleDialog from "../dialogs/toScheduleDialog";
import { useNavigate } from "react-router-dom";

interface IToScheduleProps {
    asset: Asset;
}
const ToSchedule = ({asset}: IToScheduleProps) => {
    
    const navigate = useNavigate();
    const [showScheduleModal, setShowScheduleModal] = useState(false);

    return (
        <>
            <button 
                type="button" 
                onClick={() => {
                    if(asset?.volksTotalPlan) navigate(`/fleet-manager/new-appointment?asset=${asset.id}`)
                    else setShowScheduleModal(true)
                }}
            >
                <span className="rioglyph rioglyph-calendar active" />
            </button>

            <ToScheduleDialog
                asset={asset}
                isOpen={showScheduleModal}
                onClose={() => setShowScheduleModal(false)}
            />
        </>
    )
}

export default ToSchedule;