import { Asset, AssetPlan } from "../../models/fleetManager/asset";
import { BigNumber } from "../../models/fleetManager/bigNumbers";

export const rioPlans = [
    {id: 'LATAM-SKU00000078', name: 'Premium'},
    {id: 'LATAM-SKU00000080', name: 'Basics'},
    {id: 'LATAM-SKU00000079', name: 'E-Fleet'},
];

export const BigNumberDto = (data: any, assets: Asset[]): BigNumber => {
    // TODO: integrate with the backend
    
    const checkupScheduleInfo = (asset: Asset) => asset.nextCheckups[0];

    const rioPlansAmount = rioPlans
        .map((item: AssetPlan) => {
            return {
                amount: assets.reduce((acc, asset) => acc += asset.plans?.filter(plan => plan.id === item.id).length ? 1 : 0, 0),
                productId: item.id,
                productName: item.name,
            };
        })
        .filter((item: any) => item.amount > 0);

    return {
        maintenance: {
            toSchedule: assets.reduce((acc, asset) => acc += (checkupScheduleInfo(asset)?.date == null ? 1 : 0), 0),
            overdue: assets.reduce((acc, asset) => acc += ((checkupScheduleInfo(asset)?.date != null) && (checkupScheduleInfo(asset)?.date! < new Date()) && checkupScheduleInfo(asset)?.status !== "Em manutenção" ? 1 : 0), 0),
            scheduled: assets.reduce((acc, asset) => acc += ((checkupScheduleInfo(asset)?.date != null) && checkupScheduleInfo(asset)?.status !== "Em manutenção" ? 1 : 0), 0),
            underMaintenance: assets.reduce((acc, asset) => acc += (checkupScheduleInfo(asset)?.status === "Em manutenção" ? 1 : 0), 0),
        },
        rioPlans: rioPlansAmount,
        withRioPlan: assets
            .filter(asset => 
                (asset.plans ?? []).some(plan => rioPlansAmount.map(el => el.productId).includes(plan.id))
            ).length,
        withoutRioPlan: assets
            .filter(asset => 
                !(asset.plans ?? []).some(plan => rioPlansAmount.map(el => el.productId).includes(plan.id))
            ).length,
        volksTotal: {
            volksTotal: assets.filter(asset => asset.volksTotalPlan !== '').length,
            withoutVolksTotal: assets.filter(asset => asset.volksTotalPlan === '').length,
            prev: assets.reduce((acc, asset) => acc += asset.volksTotalPlan === 'Prev' ? 1 : 0, 0),
            max: assets.reduce((acc, asset) => acc += asset.volksTotalPlan === 'Max' ? 1 : 0, 0),
            plus: assets.reduce((acc, asset) => acc += asset.volksTotalPlan === 'Plus' ? 1 : 0, 0),
        },
    };
}