import { useState } from "react"
import Card from "./card"
import { ScheduleCheckup } from "../../models/fleetManager/checkup"
import { Asset } from "../../models/fleetManager/asset"

interface ICheckupsProps {
    asset: Asset | null,
}

const Checkups = ({ asset }: ICheckupsProps) => {
    const [order, setOrder] = useState<string>('down');
    const handleOrderChange = () => setOrder(order === 'down' ? 'up' : 'down');

    return (
        <div className="revision-content">
            <div className="flex justify-between items-center actions-row">
                <button type="button" className="btn btn-default btn-icon-only">
                    <span className="rioglyph rioglyph-tachograph-download"></span>
                </button>
                <div className="form-group">
                    <div className="display-flex gap-10 align-items-end">
                        <div className="form-group">
                            <button type="button" className="btn btn-default" onClick={handleOrderChange}>
                                Por ordem
                                <span className={`rioglyph rioglyph-chevron-${order} padding-left-10`} aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {(asset?.madeCheckups.length ?? 0) > 0 && <div className="flex justify-between items-center mb-4 margin-top-15">
                <h3 className="text-normal text-size-16">
                    Revisões realizadas
                </h3>
            </div>}
            <div className="grid grid-cols-2 gap-6">
                {
                    asset?.madeCheckups
                        .sort((a, b) => order === 'down' ? b.checkup.odometer - a.checkup.odometer : a.checkup.odometer - b.checkup.odometer)
                        .map((scheduleCheckup: ScheduleCheckup) => (
                            <Card scheduleCheckup={scheduleCheckup} isOilChange={false} />
                        ))
                }
            </div>

            {(asset?.nextCheckups.length ?? 0) > 0 && <div className="flex justify-between items-center mb-4 margin-top-20">
                <h3 className="text-normal text-size-16">Próximas revisões</h3>
            </div>}
            <div className="grid grid-cols-2 gap-6 scrollable">
                {
                    asset?.nextCheckups
                        .map((scheduleCheckup: ScheduleCheckup) => (
                            <Card scheduleCheckup={scheduleCheckup} isOilChange={false} />
                        ))
                }
            </div>
        </div>
    )
}

export default Checkups;