import { Dialog } from "@rio-cloud/rio-uikit";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchDeletedSchedule } from "../../../../api/dealership/fetchScheduleList";

interface IDeleteDialogProps {
    schedulingId: string | null;
    handleClose: () => void;
    fetchAppointmens: () => void;
}

const DeleteDialog = ({
    schedulingId,
    handleClose,
    fetchAppointmens,
}: IDeleteDialogProps) => {
    const [cancelLoading, setCancelLoading] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<"cancel" | "success">("cancel");
    const navigate = useNavigate();

    const cancelScheduling = async () => {
        if(!schedulingId) return;

        setCancelLoading(true);
        try {
            await fetchDeletedSchedule(schedulingId);
            setDialogContent('success');
            setCancelLoading(false);
        } finally {
            setCancelLoading(false);
        }
    }
    
    return (
        <Dialog
            show={!!schedulingId}
            onClose={handleClose}
            showCloseButton
            bsSize={Dialog.SIZE_SM}
        >
            {dialogContent === "cancel" && (
                <>
                    <Dialog.Title title={"Cancelar agendamento"} />
                    <Dialog.Body>
                        <div className="cancel-content flex flex-col">
                            <span className="text-color-darker text-size-16 text-normal">
                                Você realmente gostaria de cancelar ou reagendar?
                            </span>
                            <span className="text-color-darker text-bold text-size-18 padding-top-10">
                                Atenção!
                            </span>
                            <span
                                style={{ color: "#E36E19" }}
                                className="text-size-16 padding-top-5"
                            >
                                Após cancelar, essa ação não poderá ser desfeita.
                            </span>
                        </div>
                    </Dialog.Body>
                    <Dialog.Footer>
                        <div className="flex items-center justify-between">
                            <p
                                className="text-bold text-size-16 cursor-pointer text-color-primary"
                                onClick={handleClose}
                            >
                                Voltar
                            </p>
                            <div className="flex gap-6">
                                <button
                                    type="button"
                                    className={`btn btn-default ${cancelLoading ? "btn-loading disabled" : ""}`}
                                    onClick={cancelScheduling}
                                >
                                    Cancelar agendamento
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => navigate(`/dealership/new-appointment?scheduling-id=${schedulingId}`)}
                                >
                                    Reagendar
                                </button>
                            </div>
                        </div>
                    </Dialog.Footer>
                </>
            )}

            {dialogContent === "success" && (
                <>
                    <Dialog.Title title={"Agendamento Cancelado"} />
                    <Dialog.Body>
                        <div className="cancel-content flex flex-col">
                            <span className="text-size-14 text-normal info-cancel">
                                Cancelamento realizado com sucesso
                            </span>
                            <span className="text-color-darker text-bold text-size-18 padding-top-10">
                                Atenção!
                            </span>
                            <span
                                style={{ color: "#B42318" }}
                                className="text-size-16 padding-top-5"
                            >
                                Essa ação não pode ser desfeita.
                            </span>
                        </div>
                    </Dialog.Body>
                    <Dialog.Footer>
                        <p
                            onClick={() => [handleClose(), fetchAppointmens()]}
                            className="text-bold text-size-16 cursor-pointer text-color-primary"
                        >
                            Fechar
                        </p>
                    </Dialog.Footer>
                </>
            )}
        </Dialog>
    )
}

export default DeleteDialog;