import { useEffect, useState } from "react";
import { Multiselect, Select } from "@rio-cloud/rio-uikit";
import Page from "../../Page";
import Dialog from "@rio-cloud/rio-uikit/Dialog";
import {
    addDays,
    getFirstDayOfWeek,
    getLastDayOfWeek,
    getStringWeekDaysStartingFromDate,
} from "../../../../utils/dateUtils";
import { IAppointmentForm } from "../../../fleetManager/newAppointment";

import { Asset } from "../../../../models/fleetManager/asset";
import { Driver } from "../../../../models/fleetManager/driver";
import { fetchCheckup } from "../../../../api/fleetManager/fetchCheckup";

import "../styles.scss";

interface IAppointmentFormProps {
    goToSummary: () => void;
    form: IAppointmentForm | undefined;
    setForm: (form: IAppointmentForm) => void;
    assets: Asset[];
    drivers: Driver[];
}

const AppointmentForm = ({ goToSummary, form, setForm, assets, drivers }: IAppointmentFormProps) => {

    const [firstWeekDay, setFirstWeekDay] = useState<Date>(getFirstDayOfWeek(new Date()));
    const [weekDays, setWeekDays] = useState<any[]>([]);
    const [choosedDate, setChoosedDate] = useState<Date | null>(null);

    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        setWeekDays(
            getStringWeekDaysStartingFromDate(firstWeekDay).map((date, index) => ({
                date: addDays(firstWeekDay, index),
                weekDay: date.split(" ")[0],
                day: date.split(" ")[1],
            }))
        );
    }, [firstWeekDay]);

    const previousWeek = () => {
        const lastDayOfPreviousWeek = addDays(firstWeekDay, -1);
        const firstWeekDayOfPreviousWeek = getFirstDayOfWeek(lastDayOfPreviousWeek);
        setWeekDays(
            getStringWeekDaysStartingFromDate(firstWeekDayOfPreviousWeek).map(
                (date, index) => ({
                    date: addDays(firstWeekDayOfPreviousWeek, index),
                    weekDay: date.split(" ")[0],
                    day: date.split(" ")[1],
                })
            )
        );
        setFirstWeekDay(firstWeekDayOfPreviousWeek);
    };

    const nextWeek = () => {
        const lastWeekDay = getLastDayOfWeek(firstWeekDay);
        const firstDayOfNextWeek = addDays(lastWeekDay, +1);
        setWeekDays(
            getStringWeekDaysStartingFromDate(firstDayOfNextWeek).map(
                (date, index) => ({
                    date: addDays(firstDayOfNextWeek, index),
                    weekDay: date.split(" ")[0],
                    day: date.split(" ")[1],
                })
            )
        );
        setFirstWeekDay(firstDayOfNextWeek);
    };

    const selectDate = (selectedDate: Date | undefined) => {
        setForm({
            ...form,
            dateAndConsultant: {
                ...form?.dateAndConsultant,
                scheduleDate: selectedDate,
            }
        });
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    const chooseAsset = (assetId: string, assets: Asset[]) => {
        const asset = assets.find((asset) => asset.id === assetId);
        setForm({
            ...form,
            assetsAndDrivers: {
                ...form?.assetsAndDrivers,
                asset,
            }
        })
        chooseCheckup(asset);
    };

    const chooseCheckup = async (asset: Asset | undefined) => {
        if (!asset?.nextCheckup?.checkup) return;
        const checkup = await fetchCheckup(asset?.nextCheckup?.checkup.id ?? "");
        setForm({
            ...form,
            assetsAndDrivers: {
                ...form?.assetsAndDrivers,
                checkup,
                asset,
            }
        });
    };

    const chooseDriver = (driverId: string, drivers: Driver[]) => {
        const driver = drivers.find((driver) => driver.id === driverId);
        setForm({
            ...form,
            assetsAndDrivers: {
                ...form?.assetsAndDrivers,
                driver,
            }
        });
    };

    const formIsValid = (): boolean => {
        return !!(
            form?.assetsAndDrivers?.asset &&
            form?.assetsAndDrivers?.driver &&
            form?.assetsAndDrivers?.driver.email &&
            form?.assetsAndDrivers?.driver.license &&
            form?.assetsAndDrivers?.driver.name &&
            form?.assetsAndDrivers?.driver.phone &&
            form?.assetsAndDrivers?.checkup &&
            form?.dateAndConsultant?.scheduleDate
        );
    };

    return (
        <>
            <Page>
                <div className="schedule-consultant">
                    <div className="schedule-consultant overflow-auto bg-white flex">
                        <div className="w-2/3">
                            <div className="schedule-consultant__header">
                                <h2 className="text-color-black font-semibold">
                                    Agendamento de revisão preventiva
                                </h2>
                                <p className="text-color-status-resting text-size-16">
                                    Preencha abaixo os dados para criar o agendamento
                                </p>
                            </div>

                            <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
                                <h6 className="text-bold text-size-16">
                                    Informações do veículo
                                </h6>

                                <div className="flex justify-between w-full gap-6 mt-5">
                                    <div className="form-group form-group-lg w-full">
                                        <label htmlFor="placa">
                                            Buscar veículo<span className="asterisk-color">*</span>
                                        </label>
                                        <Select
                                            placeholder="Buscar veículo pelo chassi..."
                                            options={assets.map((asset: Asset) => ({
                                                id: asset.id,
                                                label: `${asset.name} (chassi: ${asset.chassis})`,
                                            }))}
                                            value={form?.assetsAndDrivers?.asset ? [form?.assetsAndDrivers?.asset.id] : []}
                                            useFilter
                                            noItemMessage="Nenhum veículo encontrado"
                                            onChange={(e) => chooseAsset(e?.id ?? "", assets)}
                                            inputAddon="rioglyph rioglyph-search"
                                        />
                                    </div>
                                </div>

                                <div className="flex justify-between w-full gap-6">
                                    <div className="form-group form-group-lg w-1/3">
                                        <label htmlFor="placa">Placa do veículo</label>
                                        <input
                                            id="placa"
                                            className="form-control"
                                            type="text"
                                            placeholder="Placa"
                                            value={form?.assetsAndDrivers?.asset?.licensePlate}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group form-group-lg w-1/3">
                                        <label htmlFor="placa">Modelo do veículo</label>
                                        <div className="input-group">
                                            <input
                                                id="placa"
                                                className="form-control"
                                                type="text"
                                                placeholder="Modelo"
                                                value={form?.assetsAndDrivers?.asset?.model}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group form-group-lg w-1/3">
                                        <label htmlFor="placa">Ano do veículo</label>
                                        <div className="input-group">
                                            <input
                                                id="placa"
                                                className="form-control"
                                                type="text"
                                                placeholder="Ano do veículo"
                                                value={form?.assetsAndDrivers?.asset?.year}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between w-full gap-6">
                                    <div className="form-group form-group-lg w-1/2">
                                        <label htmlFor="placa">Horímetro</label>
                                        <input
                                            id="placa"
                                            className="form-control"
                                            type="text"
                                            placeholder="Horímetro"
                                            value={form?.assetsAndDrivers?.asset?.hourmeter}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group form-group-lg w-1/2">
                                        <label htmlFor="placa">Odômetro</label>
                                        <div className="input-group">
                                            <input
                                                id="placa"
                                                className="form-control"
                                                type="text"
                                                placeholder="Odômetro"
                                                value={form?.assetsAndDrivers?.asset?.odometer}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between w-full gap-6 mt-5">
                                    <div className="form-group form-group-lg w-1/2">
                                        <label htmlFor="motorista">
                                            Nome do motorista
                                            <span className="asterisk-color">*</span>
                                        </label>
                                        <Select
                                            placeholder="Buscar ou inserir nome"
                                            options={drivers.map((driver: Driver) => ({
                                                id: driver.id,
                                                label: driver.name,
                                            }))}
                                            value={form?.assetsAndDrivers?.driver ? [form?.assetsAndDrivers?.driver.id] : []}
                                            useFilter
                                            noItemMessage="Nenhum motorista encontrado"
                                            onChange={(e) => chooseDriver(e?.id ?? "", drivers)}
                                            inputAddon="rioglyph rioglyph-search"
                                        />
                                    </div>

                                    <div className="form-group form-group-lg w-1/2">
                                        <label htmlFor="cnh">
                                            CNH do motorista
                                            <span className="asterisk-color">*</span>
                                        </label>
                                        <div className="input-group">
                                            <input
                                                id="cnh"
                                                className="form-control"
                                                type="text"
                                                placeholder="00000000000"
                                                maxLength={9}
                                                value={form?.assetsAndDrivers?.driver?.license}
                                                onChange={(e) => setForm({
                                                    ...form,
                                                    assetsAndDrivers: {
                                                        ...form?.assetsAndDrivers,
                                                        driver: form?.assetsAndDrivers?.driver ? {
                                                            ...form?.assetsAndDrivers?.driver,
                                                            license: e.target.value,
                                                        } : undefined,
                                                    }
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between w-full gap-6">
                                    <div className="form-group form-group-lg w-1/2">
                                        <label htmlFor="emailMotorista">
                                            E-mail do motorista
                                            <span className="asterisk-color">*</span>
                                        </label>
                                        <div className="input-group">
                                            <span className="input-group-addon">
                                                <span className="rioglyph rioglyph-envelope text-color-black"></span>
                                            </span>
                                            <input
                                                id="emailMotorista"
                                                className="form-control"
                                                type="text"
                                                placeholder="Inserir email"
                                                value={form?.assetsAndDrivers?.driver?.email}
                                                onChange={(e) => setForm({
                                                    ...form,
                                                    assetsAndDrivers: {
                                                        ...form?.assetsAndDrivers,
                                                        driver: form?.assetsAndDrivers?.driver ? {
                                                            ...form?.assetsAndDrivers?.driver,
                                                            email: e.target.value,
                                                        } : undefined,
                                                    }
                                                })}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group form-group-lg w-1/2">
                                        <label htmlFor="telefoneMotorista">
                                            Telefone do motorista
                                            <span className="asterisk-color">*</span>
                                        </label>
                                        <div className="input-group">
                                            <span className="input-group-addon">
                                                <span className="rioglyph rioglyph-earphone text-color-black"></span>
                                            </span>
                                            <input
                                                id="telefoneMotorista"
                                                className="form-control"
                                                type="text"
                                                placeholder="Inserir telefone"
                                                value={form?.assetsAndDrivers?.driver?.phone}
                                                onChange={(e) => setForm({
                                                    ...form,
                                                    assetsAndDrivers: {
                                                        ...form?.assetsAndDrivers,
                                                        driver: form?.assetsAndDrivers?.driver ? {
                                                            ...form?.assetsAndDrivers?.driver,
                                                            phone: e.target.value,
                                                        } : undefined,
                                                    }
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
                                <h6 className="text-bold text-size-16">
                                    Revisões com base no intervalo de km e/ou tempo do veículo e
                                    ações de oficina (se houver)
                                </h6>
                                <div className="info-group">
                                    <p className="text-color-status-resting text-size-14 padding-top-10 padding-bottom-5">
                                        Revisões disponíveis
                                        <span className="asterisk-color">*</span>
                                        <span className="rioglyph rioglyph-question question-icon"></span>
                                    </p>
                                    <Select
                                        placeholder="Escolha a revisão"
                                        options={
                                            form?.assetsAndDrivers?.checkup ?
                                                [
                                                    {
                                                        id: form?.assetsAndDrivers?.checkup?.id,
                                                        label: form?.assetsAndDrivers?.checkup?.description,
                                                    }
                                                ] : []}
                                        value={form?.assetsAndDrivers?.checkup ? [form?.assetsAndDrivers?.checkup?.id] : []}
                                    />
                                </div>
                            </div>

                            <div className="border rounded padding-15 margin-top-20 scheduling-page__fieldset">
                                <div className="flex justify-between">
                                    <h6 className="text-bold text-size-16">Observações gerais</h6>
                                </div>

                                <div className="info-group">
                                    <div className="add-services">
                                        <div className="text-comments margin-top-10">
                                            <textarea
                                                style={{ height: "100px" }}
                                                className="form-control"
                                                placeholder="Detalhe o que está acontecendo com o veículo..."
                                                value={form?.assetsAndDrivers?.obs}
                                                onChange={(e) =>
                                                    setForm({
                                                        ...form,
                                                        assetsAndDrivers: {
                                                            ...form?.assetsAndDrivers,
                                                            obs: e.target.value,
                                                        }
                                                    })
                                                }
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/3 calendar-col">
                            <h3>Calendário</h3>

                            <div className="mb-4">
                                <div className="calendar-row margin-top-10">
                                    <div className="flex items-center justify-between mb-2 calendar-row__month">
                                        <button onClick={previousWeek}>
                                            <span className="rioglyph rioglyph-chevron-left text-color-status-available"></span>
                                        </button>
                                        <span className="text-size-16 text-color-status-resting font-semibold capitalize">
                                            {firstWeekDay
                                                .toLocaleDateString("pt-BR", {
                                                    month: "long",
                                                    year: "numeric",
                                                })
                                                .replace(" de ", "/")}
                                        </span>
                                        <button onClick={nextWeek}>
                                            <span className="rioglyph rioglyph-chevron-right text-color-status-available"></span>
                                        </button>
                                    </div>
                                    <div className="flex justify-between text-center p-2 calendar-row__days">
                                        {(weekDays ?? []).map((weekDay: any) => (
                                            <button
                                                key={`${weekDay.weekDay}_${weekDay.day}`}
                                                className={`
                                                    flex flex-col items-center gap-5 p-2 w-12 h-16 rounded-lg text-sm transition-all 
                                                    ${choosedDate?.getTime() === weekDay.date.getTime()
                                                        ? "active-date text-white"
                                                        : "hover:bg-gray-200"
                                                    }
                                                `}
                                                onClick={() => [setChoosedDate(weekDay.date)]}
                                                disabled={weekDay.date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)}
                                            >
                                                <span className="text-size-12 text-gray-600 text-lowercase">
                                                    {weekDay.weekDay}
                                                </span>
                                                <span
                                                    className={`
                                                        font-medium flex items-center justify-center text-size-14 day-card
                                                        ${weekDay.date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) ? "bg-lighter" : ""}
                                                    `}
                                                >
                                                    {weekDay.day}
                                                </span>
                                            </button>
                                        ))}
                                    </div>
                                </div>

                                {choosedDate && <div className="select-date-row">
                                    <h5>Selecione uma hora</h5>
                                    <div className="hour-slots flex flex-col">
                                        {Array.from({ length: 12 })
                                            .map((_, hour) => hour + 7)
                                            .filter((hour) => {
                                                const currentDate = new Date(choosedDate);
                                                currentDate.setHours(hour, 0, 0, 0);
                                                return currentDate.getTime() >= new Date().getTime();
                                            })
                                            .map((hour) => (
                                                <div
                                                    key={hour}
                                                    className={`hour-row flex gap-6 ${form?.dateAndConsultant?.scheduleDate?.getTime() === new Date(choosedDate).setHours(hour, 0, 0, 0) ? "active" : ""}`}
                                                    onClick={() => selectDate(new Date(choosedDate.setHours(hour, 0, 0, 0)))}
                                                >
                                                    <span className="hour-slot">{hour}:00</span>
                                                    <span className="hour-select">
                                                        {form?.dateAndConsultant?.scheduleDate?.getTime() === new Date(choosedDate).setHours(hour, 0, 0, 0)
                                                            ? "Selecionado"
                                                            : "Selecionar"}
                                                    </span>
                                                </div>
                                            ))}
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="fixed-footer">
                            <div className="page-info flex justify-end">
                                <span className="text-color-status-resting text-size-16 text-right">
                                    Informações do veículo e serviços
                                </span>
                            </div>
                            <div className="flex justify-between navigation-buttons step-1">
                                <button
                                    className="px-4 py-2 rounded-lg font-semibold button-color"
                                    onClick={() => setShowDialog(true)}
                                >
                                    <span className="rioglyph rioglyph-arrow-left text-size-16"></span>{" "}
                                    Voltar
                                </button>
                                <button
                                    className="px-4 py-2 btn btn-primary"
                                    disabled={!formIsValid()}
                                    onClick={goToSummary}
                                >
                                    Finalizar agendamento
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
            <Dialog
                show={showDialog}
                onClose={handleClose}
                showCloseButton
                bsSize={Dialog.SIZE_SM}
            >
                <Dialog.Title title={"Sair do agendamento"} />
                <Dialog.Body>
                    <div className="cancel-content flex flex-col">
                        <span className="text-color-darker text-size-16 text-normal">
                            Você está prestes a sair do agendamento, se continuar, todos os
                            dados serão perdidos.
                            <br />
                            <br />
                            Deseja continuar mesmo assim?
                        </span>
                    </div>
                </Dialog.Body>
                <Dialog.Footer>
                    <div className="flex items-center justify-end">
                        <div className="flex items-center gap-6">
                            <span
                                className="text-medium text-size-14 cursor-pointer text-color-primary"
                                onClick={handleClose}
                            >
                                Voltar
                            </span>

                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    setShowDialog(false);
                                    window.history.back();
                                }}
                            >
                                Continuar
                            </button>
                        </div>
                    </div>
                </Dialog.Footer>
            </Dialog>
        </>
    );
}

export default AppointmentForm;