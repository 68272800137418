import { AssetDto } from "../../dtos/fleetManager/assetDto";
import { BigNumberDto } from "../../dtos/fleetManager/bigNumberDto";
import { Asset } from "../../models/fleetManager/asset";
import { BigNumber } from "../../models/fleetManager/bigNumbers";
import ApiService from "../../services/api";

export const fetchMaintenance = async (
    ids?: string[], 
    groupIds?: string[]
): Promise<[Asset[], BigNumber?]> => {
    const apiService = new ApiService();
    
    try {
        const query: string[] = [
            ids?.length ? `assetIds=${ids.join(',')}` : '',
            groupIds?.length ? `groupIds=${groupIds.join(',')}` : ''
        ].filter(Boolean);
        
        const response = await apiService.get<any>(`/maintenance?${query.join('&')}`);
        if (response.status === 200) {
            const assets = response.data.list.map(AssetDto);
            return [
                assets,
                BigNumberDto(response.data.bigNumbers, assets)
            ];
        } else return [[], ];
    } catch (error) {
        console.error(error);
        return [[], ];
    }
}