import FilePicker from "@rio-cloud/rio-uikit/FilePicker";
import { useEffect, useState } from "react";
import { TicketScreening } from "../../../../models/ticket";
import { fetchTicketImages, ITicketFile, uploadTicketImage } from "../../../../api/ticket";
import { Scheduling } from "../../../../models/dealership/schedule";

const accept = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/svg": [],
};

interface IScreeningDiagnosisProps {
  ticketId: string | undefined;
  screening: TicketScreening | undefined;
  setCheckOut: () => void;
  scheduling: Scheduling | undefined;
}

const ScreeningDiagnosis = ({ ticketId, screening, setCheckOut, scheduling }: IScreeningDiagnosisProps) => {
  const [file, setFile] = useState<ITicketFile | undefined>();

  useEffect(() => {
    const fetchData = async () => {
      if (screening && ticketId) {
        const images = await fetchTicketImages({ ticketId: ticketId, step: 'SCREENING' })
        setFile(images[0]);
      };
    }

    fetchData();
  }, [screening]);

  return (
    <div>
      <div className="resume-section">
        <div className="resume-container padding-0">
          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="concessionaria">Nome da Concessionária</label>
              <input
                id="concessionaria"
                className="form-control"
                type="text"
                onChange={(e) => screening!.dealership = e.target.value}
                value={scheduling?.dealership.name}
                disabled
                // value={screening?.dealership}
                // disabled={!screening?.checkIn || !!screening?.checkOut}
              />
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="cnpj">CNPJ</label>
              <div className="input-group">
                <input
                  id="cnpj"
                  className="form-control"
                  type="text"
                  placeholder="CNPJ"
                  onChange={(e) => screening!.corporateTaxId = e.target.value}
                  value={screening?.corporateTaxId}
                  disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="local">Local</label>
              <input
                id="local"
                className="form-control"
                type="text"
                onChange={(e) => screening!.location = e.target.value}
                value={`${scheduling?.dealership.address} ${scheduling?.dealership.city} - ${scheduling?.dealership.state}`}
                disabled
                // value={screening?.location}
                // disabled={!screening?.checkIn || !!screening?.checkOut}
              />
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="representante">Representante</label>
              <div className="input-group">
                <input
                  id="representante"
                  className="form-control"
                  type="text"
                  onChange={(e) => screening!.agentName = e.target.value}
                  value={scheduling?.consultant}
                  disabled
                  // value={screening?.agentName}
                  // disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="checkin">Check-in</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-calendar"></span>
                </span>
                <input
                  id="checkin"
                  className="form-control"
                  type="text"
                  placeholder="--/--/----"
                  disabled
                  value={screening?.checkIn?.toLocaleDateString('pt-BR')}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="hora-checkin" className="opacity-0">Hora</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="hora-checkin"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  disabled
                  value={screening?.checkIn?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="checkout">Check-out</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-calendar"></span>
                </span>
                <input
                  id="checkout"
                  className="form-control"
                  type="text"
                  placeholder="--/--/----"
                  disabled
                  value={screening?.checkOut?.toLocaleDateString('pt-BR')}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/4">
              <label htmlFor="hora-checkout" className="opacity-0">Hora</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="hora-checkout"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  disabled
                  value={screening?.checkOut?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/3">
              <label htmlFor="checkinMotorista">Check-in do Motorista</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-pin"></span>
                </span>
                <input
                  id="hora-checkout"
                  className="form-control"
                  type="text"
                  placeholder="Localização"
                  value={screening?.checkInDriverLocation}
                  onChange={(e) => screening!.checkInDriverLocation = e.target.value}
                  disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/3">
              <label htmlFor="aberturaOs">Abertura da OS</label>
              <input
                id="aberturaOs"
                className="form-control"
                type="text"
                placeholder="Número"
                value={screening?.serviceOrder}
                onChange={(e) => screening!.serviceOrder = e.target.value}
                disabled={!screening?.checkIn || !!screening?.checkOut}
              />
            </div>

            <div className="form-group form-group-lg w-1/3">
              <label htmlFor="dataHoraOs">Data de abertura</label>
              <input
                id="dataHoraOs"
                className="form-control"
                type="text"
                placeholder="10/10/2024 às 10:00"
                value={screening?.serviceOrderDate?.toDateString()}
                onChange={(e) => screening!.serviceOrderDate = new Date(e.target.value)}
                disabled={!screening?.checkIn || !!screening?.checkOut}
              />
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="indicaFalha">Indicação de Falha</label>
              <input
                id="indicaFalha"
                className="form-control"
                type="text"
                value={screening?.failIndication}
                onChange={(e) => screening!.failIndication = e.target.value}
                disabled={!screening?.checkIn || !!screening?.checkOut}
              />
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="codeFalha">Código de Falha</label>
              <div className="input-group">
                <input
                  id="codeFalha"
                  className="form-control"
                  type="text"
                  value={screening?.failCode}
                  onChange={(e) => screening!.failCode = e.target.value}
                  disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="ferramentaDiagnose">Ferramenta de Diagnose</label>
              <input
                id="ferramentaDiagnose"
                className="form-control"
                type="text"
                value={screening?.diagnosisTool}
                onChange={(e) => screening!.diagnosisTool = e.target.value}
                disabled={!screening?.checkIn || !!screening?.checkOut}
              />
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="lerDiagnose">Leitura de Diagnose</label>
              <div className="input-group">
                <input
                  id="lerDiagnose"
                  className="form-control"
                  type="text"
                  value={screening?.diagnosisReport}
                  onChange={(e) => screening!.diagnosisReport = e.target.value}
                  disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="troubleshooting">
                Literatura para Troubleshooting
              </label>
              <input
                id="troubleshooting"
                className="form-control"
                type="text"
                value={screening?.troubleshootingDoc}
                onChange={(e) => screening!.troubleshootingDoc = e.target.value}
                disabled={!screening?.checkIn || !!screening?.checkOut}
              />
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="tempoEstimado">Tempo Estimado</label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="tempoEstimado"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  value={screening?.estimatedTimeTroubleshooting}
                  onChange={(e) => screening!.estimatedTimeTroubleshooting = e.target.value}
                  disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="tempoEstimadoBox">
                Tempo Estimado para Entrada no Box
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="tempoEstimadoBox"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  value={screening?.estimatedTimeBoxEntry}
                  onChange={(e) => screening!.estimatedTimeBoxEntry = e.target.value}
                  disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>

            <div className="form-group form-group-lg w-1/2">
              <label htmlFor="tempoEstimadoReparo">
                Tempo Estimado de Reparo
              </label>
              <div className="input-group">
                <span className="input-group-addon">
                  <span className="rioglyph rioglyph-time"></span>
                </span>
                <input
                  id="tempoEstimadoReparo"
                  className="form-control"
                  type="text"
                  placeholder="--:--"
                  value={screening?.estimatedTimeRepair}
                  onChange={(e) => screening!.estimatedTimeRepair = e.target.value}
                  disabled={!screening?.checkIn || !!screening?.checkOut}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center w-full">
            <button
              type="button"
              className="btn btn-primary btn-lg padding-left-25 padding-right-25 margin-top-10"
            >
              <span
                className="rioglyph rioglyph-ok-circle"
                aria-hidden="true"
              ></span>
              <span className="text-capitalize">Checklist</span>
            </button>
          </div>

          <div className="flex justify-between w-full gap-6 margin-top-25">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="relato">Relato</label>
              <textarea
                className="form-control height-100"
                id="relato"
                placeholder="Descritivo"
                value={screening?.report}
                onChange={(e) => screening!.report = e.target.value}
                disabled={!screening?.checkIn || !!screening?.checkOut}
              ></textarea>
            </div>
          </div>

          <div className="flex justify-between w-full gap-6">
            <div className="form-group form-group-lg w-full">
              <label htmlFor="relato">Inspeção visual</label>
              <div>
                {
                  !!!screening?.checkOut && 
                  !file &&
                  !(!screening?.checkIn || !!screening?.checkOut) &&
                  <FilePicker
                    displayMode='dropzone'
                    className='margin-bottom-20'
                    onPick={(acceptedFiles: FileList | null) => {
                      const filePicked = acceptedFiles?.[0];
                      setFile(
                        filePicked ?
                        { 
                          name: filePicked.name, 
                          updatedAt: new Date(filePicked.lastModified).toLocaleDateString('pt-BR'), 
                          contentType: filePicked.type, 
                          contentSize: filePicked.size 
                        }
                        : undefined
                      );
                      if(acceptedFiles?.[0] && ticketId) uploadTicketImage({ ticketId: ticketId, imageFile: acceptedFiles[0], step: 'SCREENING' });
                    }}
                    multiple={false}
                    accept={accept}
                  >
                    {dropArea}
                  </FilePicker>
                }
                <FilePreview file={file} />
              </div>
            </div>
          </div>

          <div className="flex justify-end w-full padding-bottom-25">
            <button
              disabled={!screening?.checkIn || !!screening?.checkOut}
              type="button"
              className="btn btn-primary btn-lg padding-left-25 padding-right-25 margin-top-10"
              onClick={setCheckOut}
            >
              <span className="text-capitalize">Concluir Triagem</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const dropArea = ({ isDragActive }: { isDragActive: boolean }) => (
  <div
    className={`
          rounded border border-style-dashed border-color-gray bg-lightest text-size-14
          text-center text-color-darker opacity-50 padding-25 cursor-pointer
      `}
  >
    <span className='rioglyph rioglyph-files text-size-200pct opacity-50 margin-top-20' />
    <div className='margin-top-10'>
      {isDragActive ? 'Jogue os arquivos aqui ...' : 'Arraste arquivos de imagem para cá ou clique para selecionar no computador'}
    </div>
  </div>
);

const FilePreview = ({ file }: { file: ITicketFile | undefined }) => (
  <div className='panel panel-default margin-top-20'>
    <div className='panel-body'>
      {file && (
        <>
          <div>
            <b>{`${file?.name}`}</b>
          </div>
          <div>tipo: {`${file?.contentType}`}</div>
          <div>tamanho: {`${file?.contentSize}`}</div>
          <div>data da última modificação: {`${file?.updatedAt}`}</div>
          <div>caminho: {`${file?.name}`}</div>
        </>
      )}
      {!file && <div className='text-color-dark text-italic'>Nenhum arquivo</div>}
    </div>
  </div>
);

export default ScreeningDiagnosis;
