import StatsWidget from "@rio-cloud/rio-uikit/StatsWidget";
import StatsWidgetNumber from "@rio-cloud/rio-uikit/StatsWidgetNumber";
import { ICardBigNumberProps } from ".";
import StatsWidgetSpacer from "@rio-cloud/rio-uikit/StatsWidgetSpacer";
import { IMaintenanceFilter, isMaintenanceFilterDisabled } from "../../services/fleetManager/maintenanceTableService";

const MaintenanceBigNumbers = ({
    assetFilter,
    setAssetFilter,
    getDisabledColor, 
    bigNumberMaintenance,
}: ICardBigNumberProps) => {
    
    const setMaintenanceFilter = (prop: string, value?: boolean): IMaintenanceFilter => ({
        toSchedule: prop === 'toSchedule' ? value : undefined,
        overdue: prop === 'overdue' ? value : undefined,
        scheduled: prop === 'scheduled' ? value : undefined,
        inProgress: prop === 'inProgress' ? value : undefined,
    });

    return (
        <StatsWidget className="card">
            <StatsWidget.Header>Manutenções</StatsWidget.Header>
            <StatsWidget.Body>
                <StatsWidgetNumber
                    className={`
                        ${getDisabledColor(
                                assetFilter.maintenanceFilters?.toSchedule === undefined && !isMaintenanceFilterDisabled(assetFilter.maintenanceFilters),
                                'text-color-status-driving'
                            )} 
                        cursor-pointer
                        `}
                    value={bigNumberMaintenance?.toSchedule}
                    label="Agendar"
                    onClick={() =>
                        setAssetFilter({
                            ...assetFilter,
                            maintenanceFilters: setMaintenanceFilter('toSchedule', assetFilter.maintenanceFilters?.toSchedule === true ? undefined : true),
                        })
                    }
                />
                <StatsWidgetSpacer />
                <StatsWidgetNumber
                    className={`
                        ${getDisabledColor(
                                assetFilter.maintenanceFilters?.overdue === undefined && !isMaintenanceFilterDisabled(assetFilter.maintenanceFilters),
                                'text-color-danger'
                            )} 
                        cursor-pointer
                        `}
                    value={bigNumberMaintenance?.overdue}
                    label="Vencidas"
                    onClick={() =>
                        setAssetFilter({
                            ...assetFilter,
                            maintenanceFilters: setMaintenanceFilter('overdue', assetFilter.maintenanceFilters?.overdue === true ? undefined : true)
                        })
                    }
                />
            </StatsWidget.Body>
            <StatsWidget.Body>
                <StatsWidgetNumber
                    className={`
                        ${getDisabledColor(
                                assetFilter.maintenanceFilters?.scheduled === undefined && !isMaintenanceFilterDisabled(assetFilter.maintenanceFilters),
                                ''
                            )} 
                        cursor-pointer
                        `}
                    value={bigNumberMaintenance?.scheduled}
                    label="Agendadas"
                    onClick={() =>
                        setAssetFilter({
                            ...assetFilter,
                            maintenanceFilters: setMaintenanceFilter('scheduled', assetFilter.maintenanceFilters?.scheduled === true ? undefined : true)
                        })
                    }
                />
                <StatsWidgetSpacer />
                <StatsWidgetNumber
                    className={`
                        ${getDisabledColor(
                                assetFilter.maintenanceFilters?.inProgress === undefined && !isMaintenanceFilterDisabled(assetFilter.maintenanceFilters),
                                ''
                            )} 
                        cursor-pointer
                        `}
                    value={bigNumberMaintenance?.underMaintenance}
                    label="Em Manutenção"
                    onClick={() =>
                        setAssetFilter({
                            ...assetFilter,
                            maintenanceFilters: setMaintenanceFilter('inProgress', assetFilter.maintenanceFilters?.inProgress === true ? undefined : true)
                        })
                    }
                />
            </StatsWidget.Body>
        </StatsWidget>
    )
}

export default MaintenanceBigNumbers;