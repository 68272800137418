import { useEffect, useState } from "react";
import AppLayout from "../../../layout/AppLayout";
import AssetsAndDriversForm, { IAssetAndDriverForm } from "./components/assetsAndDriversForm";
import DealershipForm from "./components/dealershipForm";
import { Asset } from "../../../models/fleetManager/asset";
import { Driver } from "../../../models/fleetManager/driver";
import { fetchMaintenance } from "../../../api/fleetManager/fetchMaintenance";
import { fetchDrivers } from "../../../api/fleetManager/fetchDrivers";
import { Loading } from "../../../utils/loading";
import { Dealership } from "../../../models/fleetManager/dealership";
import DateAndConsultantForm, { IDateAndConsultantForm } from "./components/dateAndConsultantForm";
import AppointmentSummary from "./components/appointmentSummary";

import { useSearchParams } from "react-router-dom";
import { fetchScheduling } from "../../../api/fleetManager/fetchScheduling";
import { createAppointmentFormFromScheduling } from "../../../services/fleetManager/schedulingService";

import "./styles.scss";

export interface IAppointmentForm {
  assetsAndDrivers?: IAssetAndDriverForm;
  dealership?: Dealership;
  dateAndConsultant?: IDateAndConsultantForm;
}

export const NewAppointments = () => {
  const [loading, setLoading] = useState(true);

  const [step, setStep] = useState(1);
  const [appointmentForm, setAppointmentForm] = useState<IAppointmentForm>();
  const [appointmentId, setAppointmentId] = useState<string | null>(null);

  const [assets, setAssets] = useState<Asset[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const [
        [assets,],
        drivers
      ] = await Promise.all([fetchMaintenance(), fetchDrivers()]);
      
      setAssets(assets);
      setDrivers(drivers);
      
      const schedulingId = searchParams.get("scheduling-id");
      if (schedulingId) {
        const appointment = await fetchScheduling(schedulingId);
        if(appointment) {
          setAppointmentForm(createAppointmentFormFromScheduling(appointment, drivers));
          setStep(3);
          setAppointmentId(schedulingId);
        }
      }

      setLoading(false);
    }

    fetchData()
  }, []);

  return (
    <div className="mySchedule">
      <AppLayout>
        <div className="min-h-screen w-full flex justify-center">
          {
            loading ?
              <Loading label="Carregando dados..." /> :
              <div className="w-full h-full bg-white">
                <div className="stepsSchedule">

                  {step === 1 &&
                    <AssetsAndDriversForm
                      setStep={setStep}
                      assets={assets}
                      drivers={drivers}
                      form={appointmentForm?.assetsAndDrivers}
                      setAppointmentForm={
                        (assetsAndDriversForm: IAssetAndDriverForm) =>
                          setAppointmentForm({ ...appointmentForm, assetsAndDrivers: assetsAndDriversForm })
                      }
                    />
                  }

                  {step === 2 &&
                    <DealershipForm
                      asset={appointmentForm?.assetsAndDrivers?.asset}
                      setAsset={(asset: Asset) => {
                          setAppointmentForm({ 
                            ...appointmentForm, 
                            assetsAndDrivers: { ...appointmentForm?.assetsAndDrivers, asset }
                          })
                      }}
                      dealership={appointmentForm?.dealership}
                      setDealership={(dealership: Dealership) =>  setAppointmentForm({ ...appointmentForm, dealership })}
                      setStep={setStep}
                    />
                  }

                  {step === 3 && (
                    <DateAndConsultantForm 
                      dealership={appointmentForm?.dealership}
                      setStep={setStep}
                      form={appointmentForm?.dateAndConsultant}
                      isReschedule={!!appointmentId}
                      setAppointmentForm={
                        (dateAndConsultantForm: IDateAndConsultantForm) =>
                          setAppointmentForm({ ...appointmentForm, dateAndConsultant: dateAndConsultantForm })
                      }
                    />
                  )}

                  {step === 4 && (
                    <AppointmentSummary
                      appointmentForm={appointmentForm}
                      setStep={setStep}
                      schedulingId={appointmentId}
                    />
                  )}
                </div>
              </div>
          }
        </div>
      </AppLayout>
    </div>
  );
};
